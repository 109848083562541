/* ------------------------------------ *\
   #ce09-product-teaser
\* ------------------------------------ */
.c-product-teaser {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    color: $c-text;

    &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 1px;
        background-color: $c-grey-10;
        transition: $global-transition-config;
        transition-property: opacity;
        content: '';

        @include mq(md) {
            left: calc(((100% + #{$grid-gutter-width}) / 12));
            width: calc(((100% + #{$grid-gutter-width}) / 12 * 10) - #{$grid-gutter-width});
        }
        @include mq(lg) {
            left: 0;
            width: 100%;
        }
        @include mq(xl) {
            left: 20px;
            width: calc(100% - 40px);
        }
    }

    &:hover,
    &:focus {
        text-decoration: none;

        &::after {
            opacity: 0;
        }

        .c-product-teaser__image-box {
            border-bottom: 1px solid $c-brand;

            @include mq(md) {
                border-bottom: 1px solid $c-grey-20;
            }
            @include mq(lg) {
                border-bottom: 1px solid $c-brand;
            }
            @include mq(xl) {
                border-bottom: 1px solid $c-grey-20;
            }
        }

        .c-product-teaser__text-box-inner {
            background-color: $c-brand;
            box-shadow: 0 5px 15px 0 rgba($c-grey-90, .2);
        }

        .c-product-teaser__topline,
        .c-product-teaser__headline,
        .c-product-teaser__text {
            color: $c-white;
            text-decoration: none;
        }

        .c-product-teaser__icon {
            color: $c-white;

            &::before {
                background-color: $c-white;
            }

            &::after {
                border-color: $c-white;
            }
        }

        ~ .c-toggle--bookmark {
            opacity: 1;
        }
    }
}

.c-product-teaser__image-box {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border: 1px solid $c-grey-20;
    transition: $global-transition-config;
    transition-property: border-color;

    @include mq(md) {
        padding: 15px;
    }
    @include mq(lg) {
        padding: 0;
    }
    @include mq(xl) {
        padding: 20px;
    }

    &.is-upright {
        .c-product-teaser__img-wrap {
            align-items: center;
            width: 200px;
        }

        img {
            width: auto;
            height: 199px;
            margin: auto;
        }
    }
}

.c-product-teaser__img-wrap {
    display: flex;
    align-items: center;
    height: 200px;
    margin: auto;

    img {
        width: 200px;
        height: auto;
        max-height: 199px;
    }
}

.c-product-teaser__text-box-wrap {
    @include make-row();

    height: auto;

    @include mq(md) {
        height: 100%;
    }
}

.c-product-teaser__text-box {
    @include make-col-ready();
    @include make-col(12);

    @include mq(md) {
        flex: 0 0 calc(100% - 40px);
        margin-top: -34px;
        margin-left: 20px;
    }

    @include mq(lg) {
        @include make-col-offset(0);
        @include make-col(12);

        width: 100%;
        margin-top: 0;
        margin-right: 0;
        margin-left: 0;
        padding-right: $grid-gutter-width / 2;
        padding-left: $grid-gutter-width / 2;
    }
    @include mq(xl) {
        margin-top: -34px;
        padding-right: $grid-gutter-width / 2 + 20px;
        padding-left: $grid-gutter-width / 2 + 20px;
    }
}

.c-product-teaser__text-box-inner {
    padding: 20px;
    background-color: $c-white;
    transition: $global-transition-config;
    transition-property: background-color;

    @include mq(md) {
        height: 100%;
    }

    @include mq(lg) {
        padding: 19px $grid-gutter-width / 2;
    }

    @include mq(xl) {
        height: 100%;
        padding: 20px;
    }
}

.c-product-teaser__topline {
    @include textEmphasizedSmall();

    display: block;
    margin-bottom: 5px;
    color: $c-grey-40;
    transition: $global-transition-config;
    transition-property: color;
}

.c-product-teaser__headline {
    margin-bottom: 10px;
    transition: $global-transition-config;
    transition-property: color;
}

.c-product-teaser__text {
    @include normal-font();

    margin-bottom: 0;
    padding-bottom: 30px;
    transition: $global-transition-config;
    transition-property: color;

    @include mq($until: md) {
        @include small-font();
    }
}

.c-product-teaser__icon {
    @include textEmphasizedSmall();

    position: absolute;
    bottom: 0;
    left: 20px;
    display: flex;
    width: calc(100% - 40px);
    min-height: 40px;
    margin: 0;
    padding-top: 2px;
    padding-bottom: 0;
    padding-left: 34px;
    font-weight: bold;
    transition: $global-transition-config;
    transition-property: color;

    @include mq(md) {
        left: calc(((100% + #{$grid-gutter-width}) / 12) + 8px);
        width: 70%;
    }
    @include mq(lg) {
        left: $grid-gutter-width / 2;
        width: calc(100% - #{$grid-gutter-width});
    }
    @include mq(xl) {
        left: calc(20px + #{$grid-gutter-width});
        width: calc(100% - ((20px + #{$grid-gutter-width}) * 2));
    }

    &::before,
    &::after {
        position: absolute;
        display: block;
        content: '';
    }

    &::before {
        top: 10px;
        bottom: 0;
        left: 0;
        width: 21px;
        height: 2px;
        background-color: $c-brand;
        transition: $global-transition-config;
        transition-property: background;
    }

    &::after {
        top: 5px;
        left: 9px;
        width: 12px;
        height: 12px;
        border-color: $c-brand;
        border-top: 2px solid $c-brand;
        border-right: 2px solid $c-brand;
        transform: rotate(45deg);
        transition: $global-transition-config;
        transition-property: border, left;
    }
}

// collection
.c-product-teaser--collection {
    background-color: $c-transparent;

    &:hover,
    &:focus {
        &::after {
            opacity: 0;
        }

        .c-product-teaser__image-box {
            @include mq(md) {
                border-bottom: 0;
            }
            @include mq(lg) {
                border-bottom: 0;
            }
            @include mq(xl) {
                border-bottom: 0;
            }
        }

        ~ .c-toggle--bookmark {
            &::after {
                border: 1px solid $c-white;
            }
        }
    }

    @include mq(md) {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        height: auto;
    }

    &::after {
        opacity: 0;
        transition: none;
    }

    .c-product-teaser__image-box {
        @include mq(md) {
            position: relative;
            width: calc(((100% + #{$grid-gutter-width}) / 8 * 2 ) - #{$grid-gutter-width});
            max-height: unset;
            padding: 0;
            overflow-y: hidden;
            border: none;
        }

        .c-product-teaser__img-wrap {
            @include mq(md) {
                position: relative;
                width: calc(100% - 1px);
                height: 0;
                margin: 0;
                padding-bottom: 100%;
                border: 1px solid $c-grey-20;
            }

            img {
                max-width: calc(100% - 2px);
                margin-left: 1px;

                @include mq(md) {
                    position: absolute;
                    top: 0;
                    left: 0;
                    align-items: start;
                    width: 100%;
                    max-width: 100%;
                    height: auto;
                    margin-left: 0;
                    vertical-align: top;
                    transform: translate(0, 0);
                }
            }
        }
    }

    .c-product-teaser__text-box-wrap {
        @include mq(md) {
            width: calc(((100% + #{$grid-gutter-width}) / 8 * 6 ));
            height: auto;
            margin: 0;
            padding: 0;
        }
    }

    .c-product-teaser__text-box {
        flex: 0 0 100%;
        margin: 0;
        padding: 0;
    }

    .c-product-teaser__text-box-inner {
        margin-right: $grid-gutter-width / 2;
        margin-left: $grid-gutter-width / 2;

        @include mq(md) {
            margin-right: 0;
            margin-left: 0;
            padding-right: $grid-gutter-width;
            padding-left: $grid-gutter-width;
        }
    }

    .c-product-teaser__icon {
        @include mq(md) {
            left: calc((100% + #{$grid-gutter-width}) / 8 * 2);
            width: 30%;
        }
        @include mq(lg) {
            left: calc((100% + #{$grid-gutter-width}) / 8 * 2);
            width: 25%;
        }
    }

    ~ .c-toggle--bookmark {
        opacity: 1;

        &::after {
            border: 1px solid $c-transparent;
            transition: border $global-transition;
        }
    }
}
