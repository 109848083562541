/* ------------------------------------ *\
    #MODULE
\* ------------------------------------ */

/**
 * Begin of module
 */

/* 1. Default vertical spacing between two modules */
.o-module {
    @include moduleSpace();

    &:first-of-type {
        margin-top: 0;
    }

    .c-accordion & {
        margin-bottom: $spacing-xl;

        @include mq(md) {
            margin-bottom: $spacing-xl;
        }

        @include mq(lg) {
            margin-bottom: $spacing-2xl;
        }

        @include mq(xl) {
            margin-bottom: $spacing-3xl;
        }
    }
}

.o-module--no-margin-top {
    margin-top: 0;
}

.o-module--no-margin-bottom {
    margin-bottom: 0;
}

/*
    Module spanning the whole viewport width, being pulled out of the grid on both sides,
    then gets some padding added to make its content align elegantly with the grid again.
 */
.o-module--wide {
    padding-right: calc(50vw - 50%);
    padding-left: calc(50vw - 50%);
    margin-right: calc(-50vw + 50%);
    margin-left: calc(-50vw + 50%);

}

/*
    Modules with a background color should get some top and bottom padding.
 */
.o-module--bg-dark,
.o-module--bg-light {
    padding-top: $spacing-m;
    padding-bottom: $spacing-m;

    @include mq(md) {
        padding-top: $spacing-2xl;
        padding-bottom: $spacing-2xl;
    }

    @include mq(lg) {
        padding-top: $spacing-3xl;
        padding-bottom: $spacing-3xl;
    }

    @include mq(xl) {
        padding-top: $spacing-4xl;
        padding-bottom: $spacing-4xl;
    }

    @include mq(xxl) {
        padding-top: $spacing-5xl;
        padding-bottom: $spacing-5xl;
    }
}

/*
    Modules on a background with a dark bg color need to get a bright (i. e. white) font color
    for better contrast and readability.
 */
.o-module--bg-dark {
    color: $c-white;
}

.o-module--bg-light-grey {
    background-color: $c-grey-05;
}

.o-module--bg-grey {
    background-color: $c-grey-50;
}

.o-module--bg-red {
    background-color: $c-red;
}

/*
 * 1. Text copy shall only span 10 of the 12 grid columns on large screens.
 */
.o-module__header {
    @include mq(lg) {
        max-width: (100% / 12 * 10);
    }
}

.o-module__header--narrow {
    @include mq(lg) {
        max-width: (100% / 12 * 5);
    }
}

.o-module__header--split {
    @include mq(lg) {
        max-width: none;
    }
}

/*
 * Header variant with a border applied to its bottom.
 */
.o-module__header--spacer {
    position: relative;
    margin-bottom: 3em;
    padding-bottom: .1px;

    &::after {
        position: absolute;
        bottom: 0;
        display: block;
        width: 150px;
        height: 1px;
        background: $c-black;
        content: '';
    }
}

/*
 * A header that is constitutes of a headline, a link and a subheader (optional).
 */
.o-module__header-top--split {
    margin-bottom: 0;

    @include mq(lg) {
        display: flex;
        align-items: baseline;
    }
}

.o-module__header-left {
    flex: 1;
    margin-bottom: .5em;

    @include mq(lg) {
        margin-bottom: 0;
        padding-right: $grid-gutter-width * 2;
    }
}

.o-module__header-right {
    @include mq($until: lg) {
        .o-module__header--split & {
            display: none;
        }
    }
}

.o-module__footer {
    margin-top: $spacing / 3;

    @include mq(md) {
        margin-top: $spacing / 2;
    }

    @include mq(lg) {
        margin-top: $spacing * .625;
    }
}

.o-module__footer--hide\@large {
    @include mq(lg) {
        display: none;
    }
}

.o-module__headline {
    @include typo-h2;
}

.o-module__headline--large {
    @include typo-h1($margin: true);

    @include mq(lg) {
        margin-bottom: 1em;
        font-size: $font-size-h1;
    }
}

.o-module__subheader {
    margin-top: .5em;
    margin-bottom: 3em;

    .o-module__header--split & {
        @include mq($until: lg) {
            margin-bottom: 1.5em;
        }
    }

    .o-module__header--spacer & {
        margin-bottom: 1.5em;
    }
}

.o-module__intro {
    margin-bottom: $spacing * .4; /* [1] */

    @include mq(sm) {
        margin-bottom: $spacing * .5; /* [1] */
    }

    // @include mq(xxlg) {
    //     margin-bottom: $spacing * .6; /* [1] */
    // }

    .o-module__header--spacer & {
        margin-bottom: 1.5em;
    }
}

.o-module__header-link {
    margin-bottom: 1.5em;

    @include mq(lg) {
        position: relative;
        top: -.25em;
        margin-bottom: 0;
    }
}
