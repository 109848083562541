/* ------------------------------------ *\
    #SITE NAV
\* ------------------------------------ */

.c-site-nav {
    @include resetListStyles(false);

    display: none;
    flex-direction: column;

    @include mq(xl) {
        display: flex;
    }

    .c-mobile-nav & {
        display: flex;
        flex-direction: column;

        @include mq(md) {
            flex-direction: row;
            margin-right: $grid-gutter-width / -2;
            margin-left: $grid-gutter-width / -2;
        }

        @include mq(lg) {
            margin-right: $grid-gutter-width / -2;
            margin-left: $grid-gutter-width / -2;
        }

        @include mq(xl) {
            margin-right: 0;
            margin-left: 0;
        }
    }
}

.c-site-nav__item {
    @include resetListStyles(false);

    margin-bottom: 1px;

    @include mq(md) {
        flex: 1;
        margin-bottom: 0;
        padding-right: $grid-gutter-width / 2;
        padding-left: $grid-gutter-width / 2;
    }

    @include mq(xl) {
        width: calc(100% + #{$grid-gutter-width} + (#{$grid-gutter-width} / 2));
        margin-bottom: 1px;
        padding-right: 0;
        padding-left: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.c-site-nav__link {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px;
    padding-right: 20px;
    padding-left: 30px;
    color: $c-white;
    background: $c-grey-40;
    transition: $global-transition-config;
    transition-property: background;

    @include mq(xl) {
        padding-right: 45px;
    }

    &:hover,
    &:focus {
        color: $c-white;
        background-color: $c-brand;
    }
}

.c-site-nav__logo {
    height: 40px;
}

.c-site-nav__icon {
    position: absolute;
    right: 15px;
    display: block;
    width: 24px;
    height: 24px;
    fill: currentColor;

    @include mq(md) {
        right: 20px;
    }
    @include mq(lg) {
        right: 40px;
    }
}
