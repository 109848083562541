/* ------------------------------------ *\
   #ce09_content-teaser
\* ------------------------------------ */
.c-content-teaser {
    @include clearfix();

    position: relative;
    display: block;
    width: 100%;
    color: $c-text;

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: 0;

        &::after {
            opacity: 0;
        }

        .c-content-teaser__text-box-inner {
            background-color: $c-brand;
        }

        .c-content-teaser__topline,
        .c-content-teaser__headline,
        .c-content-teaser__text {
            color: $c-white;
            text-decoration: none;

            em {
                color: $c-white;
            }
        }

        .c-content-teaser__icon {
            color: $c-white;

            &::before {
                background-color: $c-white;
            }

            &::after {
                border-color: $c-white;
            }
        }

        .c-content-teaser__logo-box {
            opacity: 1;
        }
    }
}

.c-content-teaser__logo-box {
    @include zindex(base);

    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba($c-grey-50, .8);
    opacity: 0;
    transition: $global-transition-config;
    transition-property: opacity;

    @include mq(md) {
        width: calc(100% - #{$grid-gutter-width});
        margin-right: $grid-gutter-width /2;
        margin-left: $grid-gutter-width /2;
    }
    @include mq(lg) {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }
}

.c-content-teaser__logo {
    display: block;
    align-self: center;
    width: 230px;
    height: 70px;
    margin-top: -40px;

    @include mq(lg) {
        margin-top: 0;
    }

    @include mq(xl) {
        margin-top: -40px;
    }
}

.c-content-teaser__image-box {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + #{$grid-gutter-width});
    margin-right: $grid-gutter-width / -2;
    margin-left: $grid-gutter-width / -2;

    @include mq(sm) {
        width: calc(100% + (#{$grid-gutter-width} * 2) + #{$grid-gutter-width});
        margin-right: calc((#{$grid-gutter-width} + (#{$grid-gutter-width} / 2)) * -1);
        margin-left: calc((#{$grid-gutter-width} + (#{$grid-gutter-width} / 2)) * -1);
    }
    @include mq(md) {
        width: calc(100% + #{$grid-gutter-width});
        margin-right: $grid-gutter-width / -2;
        margin-left: $grid-gutter-width / -2;
    }
}

.c-content-teaser__img-wrap {
    @include make-col-ready();

    height: 0;

    @include mq($until: md) {
        padding-right: 0;
        padding-bottom: 50%;
        padding-left: 0;
    }

    @include mq(md) {
        @include make-col(12);

        padding-bottom: calc((100% - #{$grid-gutter-width}) / 2);
    }

    img {
        width: 100%;
        opacity: .3;
    }
}

.c-content-teaser__text-box-wrap {
    @include make-row();
}

.c-content-teaser__text-box {
    @include zindex(base);
    @include make-col-ready();
    @include make-col(12);
}

.c-content-teaser__text-box-inner {
    padding: 20px;
    background-color: $c-white;
    transition: $global-transition-config;
    transition-property: background-color;

    @include mq(lg) {
        height: 100%;
        padding: $grid-gutter-width;
    }
}

.c-content-teaser__topline {
    @include textEmphasizedSmall();

    display: block;
    margin-bottom: 10px;
    color: $c-grey-40;
    transition: $global-transition-config;
    transition-property: color;

    em {
        color: $c-grey-70;
        font-style: normal;
    }
}

.c-content-teaser__headline {
    margin-bottom: 15px;
    transition: $global-transition-config;
    transition-property: color;

    @include mq(xxl) {
        margin-bottom: 20px;
    }
}

.c-content-teaser__text {
    @include normal-font();

    margin-bottom: 20px;
    transition: $global-transition-config;
    transition-property: color;
}

.c-content-teaser__icon {
    @include zindex(above);
    @include textEmphasizedSmall();

    position: relative;
    display: flex;
    width: 100%;
    margin-top: 20px;
    padding-top: 2px;
    padding-bottom: 25px;
    padding-left: 34px;
    font-weight: bold;
    transition: $global-transition-config;
    transition-property: color;

    &::before,
    &::after {
        position: absolute;
        display: block;
        content: '';
    }

    &::before {
        top: 10px;
        bottom: 0;
        left: 0;
        width: 21px;
        height: 2px;
        background-color: $c-brand;
        transition: $global-transition-config;
        transition-property: background;
    }

    &::after {
        top: 5px;
        left: 9px;
        width: 12px;
        height: 12px;
        border-color: $c-brand;
        border-top: 2px solid $c-brand;
        border-right: 2px solid $c-brand;
        transform: rotate(45deg);
        transition: $global-transition-config;
        transition-property: border, left;
    }

    &.is-extern {
        &::before {
            top: 10px;
            left: 0;
            transform: rotate(-45deg);
        }

        &::after {
            top: 3px;
            left: 7px;
            transform: rotate(0);
        }
    }
}

.c-content-teaser--single-right,
.c-content-teaser--single-left {
    @include mq(xl) {
        min-height: 450px;
    }

    &:hover,
    &:focus {
        .c-content-teaser__text-box-inner {
            box-shadow: 0 5px 15px 0 rgba($c-grey-90, .2);
        }
    }

    .c-content-teaser__logo-box {
        @include mq(md) {
            width: calc(100% - #{$grid-gutter-width});
            margin-right: $grid-gutter-width /2;
            margin-left: $grid-gutter-width /2;
        }
        @include mq(lg) {
            width: calc((100% / 12 * 9) - #{$grid-gutter-width});
            margin-right: $grid-gutter-width /2;
            margin-left: $grid-gutter-width /2;
        }
        @include mq(xl) {
            width: calc((100% / 12 * 7) - #{$grid-gutter-width});
            margin-right: $grid-gutter-width /2;
            margin-left: $grid-gutter-width /2;
        }
    }

    .c-content-teaser__logo {
        @include mq(lg) {
            margin-top: -60px;
        }
        @include mq(xl) {
            margin-top: 0;
            margin-right: 20%;
        }
    }

    .c-content-teaser__text-box {
        margin-top: -44px;

        @include mq(sm) {
            margin-top: -60px;
        }
        @include mq(md) {
            @include make-col-offset(1);
            @include make-col(10);
        }
        @include mq(lg) {
            margin-top: -100px;
        }
        @include mq(xl) {
            min-height: 375px;
            margin-top: -295px;
        }
    }

    .c-content-teaser__img-wrap {
        padding-bottom: calc((100% - #{$grid-gutter-width}) / 2);

        @include mq(lg) {
            @include make-col(9);

            padding-bottom: calc(((100% / 12 * 9) - #{$grid-gutter-width}) / 2);
        }

        @include mq(xl) {
            @include make-col(7);

            padding-bottom: calc(((100% / 12 * 7) - #{$grid-gutter-width}) / 2);
        }
    }
}

.c-content-teaser--single-right {
    .c-content-teaser__img-wrap {
        @include mq(lg) {
            @include make-col-offset(3);
        }

        @include mq(xl) {
            @include make-col-offset(5);
        }
    }

    .c-content-teaser__text-box {
        @include mq(lg) {
            @include make-col-offset(0);
            @include make-col(9);
        }
        @include mq(xl) {
            @include make-col(7);
        }
    }
}

.c-content-teaser--single-left {
    .c-content-teaser__text-box {
        @include mq(lg) {
            @include make-col-offset(3);
            @include make-col(9);
        }
        @include mq(xl) {
            @include make-col-offset(5);
            @include make-col(7);
        }
    }
}

.c-content-teaser--image,
.c-content-teaser--text {
    height: 100%;

    &:hover,
    &:focus {
        text-decoration: none;
        box-shadow: 0 5px 15px 0 rgba($c-grey-90, .2);

        @include mq(md) {
            box-shadow: none;
        }

        .c-content-teaser__text-box-inner {
            @include mq(md) {
                box-shadow: 0 5px 15px 0 rgba($c-grey-90, .2);
            }
        }
    }

    &::after {
        @include zindex(above);

        position: absolute;
        bottom: 0;
        display: block;
        height: 1px;
        background-color: $c-grey-10;
        transition: $global-transition-config;
        transition-property: opacity;
        content: '';
    }

    .c-content-teaser__text-box-wrap {
        height: auto;

        @include mq(lg) {
            height: 100%;
        }
    }

    .c-content-teaser__text-box-inner {
        padding: 20px;

        @include mq(lg) {
            height: 100%;
            padding: 19px $grid-gutter-width / 2;
        }

        @include mq(xl) {
            height: 100%;
            padding: $grid-gutter-width;
        }
    }

    .c-content-teaser__text {
        @include mq($until: md) {
            @include small-font();
        }

        margin-bottom: 0;
        padding-bottom: 55px;
    }

    .c-content-teaser__icon {
        position: absolute;
        bottom: 10px;
        min-height: 40px;
        margin: 0;
        padding-top: 2px;
        padding-bottom: 0;
    }
}

.c-content-teaser--image {
    &::after {
        left: 0;
        width: 100%;

        @include mq(md) {
            left: calc(((100% + #{$grid-gutter-width}) / 12));
            width: calc(((100% + #{$grid-gutter-width}) / 12 * 10) - #{$grid-gutter-width});
        }
        @include mq(lg) {
            left: 0;
            width: 100%;
        }
        @include mq(xl) {
            left: 20px;
            width: calc(100% - 40px);
        }
    }

    .c-content-teaser__img-wrap {
        @include mq(lg) {
            @include make-col-offset(0);
            @include make-col(12);

            height: 0;
            padding-right: 0;
            padding-bottom: 50%;
            padding-left: 0;
        }
    }

    .c-content-teaser__image-box {
        @include mq(lg) {
            width: 100%;
            margin-right: 0;
            margin-left: 0;
        }
    }

    .c-content-teaser__text-box {
        margin-top: -44px;

        @include mq(sm) {
            margin-top: -60px;
        }
        @include mq(md) {
            @include make-col-offset(1);
            @include make-col(10);
        }
        @include mq(lg) {
            @include make-col-offset(0);
            @include make-col(12);

            width: 100%;
            margin-top: 0;
            margin-right: 0;
            margin-left: 0;
            padding-right: $grid-gutter-width /2;
            padding-left: $grid-gutter-width /2;
        }
        @include mq(xl) {
            margin-top: -60px;
            padding-right: $grid-gutter-width /2 + 20px;
            padding-left: $grid-gutter-width /2 + 20px;
        }
    }

    .c-content-teaser__icon {
        left: 20px;
        width: calc(100% - 40px);

        @include mq(md) {
            left: calc(((100% + #{$grid-gutter-width}) / 12) + 20px);
            width: 70%;
        }
        @include mq(lg) {
            left: $grid-gutter-width / 2;
            width: calc(100% - #{$grid-gutter-width});
        }
        @include mq(xl) {
            left: calc(20px + #{$grid-gutter-width});
            width: calc(100% - ((20px + #{$grid-gutter-width}) * 2));
        }
    }
}

.c-content-teaser--text {
    &:hover,
    &:focus {
        .c-content-teaser__text-box {
            &::before {
                background-color: $c-brand;
            }
        }
    }

    &::after {
        left: 0;
        width: 100%;
        background-color: $c-grey-10;

        @include mq(lg) {
            left: 0;
            width: 100%;
        }
    }

    .c-content-teaser__text-box {
        @include mq(md) {
            @include make-col-offset(0);
            @include make-col(12);
        }

        &::before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: $grid-gutter-width / 2;
            display: block;
            width: calc(100% - #{$grid-gutter-width});
            height: 2px;
            background-color: $c-grey-40;
            transition: $global-transition-config;
            transition-property: background;
            content: '';
        }
    }

    .c-content-teaser__icon {
        left: 20px;
        width: calc(100% - 40px);

        @include mq(lg) {
            left: $grid-gutter-width / 2;
            width: calc(100% - #{$grid-gutter-width});
        }
        @include mq(xl) {
            left: calc(20px + (#{$grid-gutter-width} / 2));
            width: calc(100% - ((20px + #{$grid-gutter-width}) * 1.5));
        }
    }
}
