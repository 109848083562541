/* -------------------------------------------------------------------------- *\
    #Tabs
\* -------------------------------------------------------------------------- */

.c-select {
    @include make-col-ready();
    @include make-col(12);

    @include mq(md) {
        @include make-col(8);
        @include make-col-offset(1);
    }

    @include mq(lg) {
        @include make-col(6);
        @include make-col-offset(1);
    }
}

.c-select--articles {
    @include mq(lg) {
        display: none;
    }
}

.c-select--filter {
    margin-top: -10px;
    margin-bottom: 20px;
    padding: 0;

    @include mq(md) {
        margin-top: -20px;
        margin-bottom: 40px;
    }

    @include mq(lg) {
        margin-top: -40px;
        margin-bottom: 60px;
    }
}

.c-select__box {
    position: relative;
    width: 100%;
    background: $c-transparent;
    border: 1px solid $c-grey-20;
    transition: $global-transition-config;
    transition-property: color, background-color, box-shadow;

    //&:hover {
    //    color: $c-white;
    //    text-decoration: none;
    //    background: $c-brand;
    //    border: 1px solid $c-brand;
    //    outline: 0;
    //    box-shadow: 0 5px 15px 0 rgba($c-grey-90, .2);
    //
    //    .c-select__input {
    //        color: $c-white;
    //    }
    //
    //    &::after {
    //        border-color: $c-white;
    //    }
    //}

    &::after {
        position: absolute;
        top: calc(50% - 9px);
        right: 24px;
        display: block;
        width: 12px;
        height: 12px;
        border-top: 3px solid $c-brand;
        border-right: 3px solid $c-brand;
        transform: rotate(135deg);
        transition: $global-transition;
        transition-property: border, transform;
        content: '';
    }
}

.c-select__label {
    @include sr-only();
}

/* stylelint-disable  */
.c-select__input {
    @include textEmphasized();

    position: relative;
    display: block;
    width: 100%;
    padding: 13px 30px 13px 20px;
    color: $c-text;
    font-weight: bold;
    background: $c-transparent;
    border: none;
    transition: $global-transition-config;
    transition-property: color, background-color;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
}
/* stylelint-enable */
