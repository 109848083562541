/* ------------------------------------ *\
    #ce03_textmedia
\* ------------------------------------ */

/*
 * 1. Text copy shall only span 10 of the 12 grid columns on large screens.
 */
.c-textmedia {
    @include make-row();

    counter-reset: main-counter;

    &.is-product-info {
        li {
            &::before {
                left: -2px;
                width: 16px;
                height: 7px;
                background-color: $c-transparent;
                border-bottom: 2px solid $c-brand;
                border-left: 2px solid $c-brand;
                border-radius: 0;
                transform: rotate(-45deg);
            }

            ul {
                li {
                    margin-bottom: 7px;

                    &::before {
                        left: 0;
                        width: $o-list-bullet-size;
                        height: $o-list-bullet-size;
                        border: 2px solid $c-grey-20;
                        border-radius: 100%;
                        transform: rotate(0);
                    }
                }
            }
        }
    }

    ol,
    ul {
        margin-bottom: $spacing-2xl;
        padding-left: 15px;
        list-style-type: none;

        @include mq(lg) {
            margin-bottom: $spacing-3xl;
        }
    }

    ul {
        @include list-unordered();
    }

    ol {
        @include list-ordered();
    }

    b,
    strong {
        font-weight: bold;
    }

    i,
    em {
        font-style: italic;
    }

    li {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.c-textmedia__body {
    @include make-col-ready();
    @include mq(lg) {
        @include make-col-offset(1);
        @include make-col(9);
    }
    @include mq(xxl) {
        @include make-col-offset(1);
        @include make-col(7);
    }

    ul,
    ol {
        &:last-child {
            margin-bottom: 0;
        }
    }

    p {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.c-textmedia__caption {
    @include mq($until: lg) {
        display: block;
    }

    .o-media--stacked & {
        display: block;
    }
}

.c-textmedia__media {
    margin-bottom: $spacing-m;

    @include mq($until: md) {
        display: block;
    }

    .o-media--below & {
        margin-top: $spacing-m;
        margin-bottom: 0;

        @include mq(md) {
            margin-top: $spacing-3xl;
        }

        @include mq(xl) {
            margin-top: $spacing-4xl;
        }
    }
}

.c-textmedia__topline {
    @include textEmphasized();

    display: block;
    margin-bottom: 10px;
    color: $c-grey-40;
}

.c-textmedia__headline {
    @include make-col-ready();
    @include mq(lg) {
        @include make-col-offset(1);
        @include make-col(9);
    }
}
