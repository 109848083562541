/* ------------------------------------ *\
    $HELPER
\* ------------------------------------ */
.text--right {
    text-align: right !important;
}

.grid-helper {
    @include zindex(grid-helper);

    position: fixed;
    top: 0;
    display: none;
    width: 100%;
    pointer-events: none;

    &.show {
        display: block;
    }
}

.grid-helper__container {
    width: auto;

    span {
        display: block;
        height: 100vh;
        background: rgba(blue, .1);
    }
}

.mq-indicator {
    position: fixed;
    right: 10px;
    bottom: 10px;
    padding: 0 10px;
    color: #fff;
    font-size: 14px;
    line-height: 24px;
    background: #333;
    border-radius: 3px;
    opacity: .5;

    &.is-desktop {
        box-shadow: 0 0 0 3px magenta;
    }
}
