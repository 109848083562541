/* ------------------------------------ *\
    #LIST ELEMENTS
\* ------------------------------------ */

/**
 * Begin of module
 */
ol > li,
ul > li {
    margin-bottom: 1em;
    padding-left: 15px;
}

li > ul,
li > ol {
    padding-top: 1em;
}
