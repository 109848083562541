/* ------------------------------------ *\
    #quote
\* ------------------------------------ */
.c-quote {
    @include make-row();

    position: relative;
    padding-top: 30px;

    @include mq($until: sm) {
        margin-right: 0;
    }

    @include mq(sm) {
        padding-top: 50px;
    }

    .o-module--wide & {
        padding-top: 0;
        margin-top: $spacing-3xl;
        margin-bottom: $spacing-3xl;
    }
}

.c-quote__blockquote {
    @include make-col-ready();
    @include mq(sm) {
        @include make-col-offset(2);
        @include make-col(10);
    }
    @include mq(xxl) {
        @include make-col(9);
    }

    .o-module--wide & {
        margin-bottom: 0;
    }
}

.c-quote__text {
    margin-bottom: 30px;
    padding-top: 70px;
    color: $c-grey-70;
    font-size: 26px;
    line-height: 42px;
    letter-spacing: .05em;

    .o-module--bg-dark & {
        color: inherit;
    }

    @include mq(sm) {
        padding-top: 0;
    }
    @include mq(lg) {
        margin-bottom: 40px;
        font-size: 36px;
        line-height: 50px;
    }

    &::before {
        position: absolute;
        top: 40px;
        left: 5px;
        display: block;
        width: 50%;
        color: $c-brand;
        font-size: 180px;
        text-align: left;
        content: '”';

        .o-module--bg-dark & {
            color: inherit;
        }

        @include mq(sm) {
            top: 25px;
            left: calc(100% / 12 * -2);
            width: calc(100% / 12 * 2);
            font-size: 180px;
            text-align: right;
        }
        @include mq(md) {
            left: calc((100% / 12 * -2) - 10px);
        }
        @include mq(lg) {
            top: 31px;
            font-size: 245px;
        }
    }
}

.c-quote__icon {
    display: inline;
    width: 0;

    &::after {
        position: absolute;
        display: inline-block;
        margin-left: -5px;
        color: $c-brand;
        font-size: 36px;
        content: '“';

        .o-module--bg-dark & {
            color: inherit;
        }
    }
}

.c-quote__author {
    @include textEmphasizedSmall();

    display: block;
    color: $c-grey-40;
    font-weight: bold;

    .o-module--bg-dark & {
        color: inherit;
    }

    em {
        color: $c-grey-70;
        font-style: normal;

        .o-module--bg-dark & {
            color: inherit;
        }
    }
}

.c-quote__author-position {
    display: block;
    color: $c-grey-40;

    .o-module--bg-dark & {
        color: inherit;
    }

    @include mq($until: xl) {
        margin-top: 10px;
    }

    @include mq(xl) {
        display: inline;
    }
}
