/* ------------------------------------ *\
   #ce38-text-image-group-item
\* ------------------------------------ */
.c-text-image-group-item {
    position: relative;
    display: block;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    margin-bottom: 0;
    color: $c-text;
}

.c-text-image-group-item__image {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    .c-text-image-group-item__caption {
        padding: 0;
    }
}

.c-text-image-group-item__img-wrap {
    display: flex;
    align-items: center;
    margin: auto;
    padding: 0;

    img {
        display: flex;
    }
}

.c-text-image-group-item__text-box {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.c-text-image-group-item__headline {
    @include typo-h3(false, true);

    margin-bottom: $spacing-xs;

    @include mq(lg) {
        margin-bottom: $spacing-s;
    }

    &.c-text-image-group-item__headline--h2 {
        @include typo-h2(false, true);

        margin-bottom: $spacing-s;

        @include mq(lg) {
            margin-bottom: $spacing-m;
        }
    }
}

.c-text-image-group-item__text {
    @include normal-font();

    margin-bottom: 0;
    padding-bottom: 30px;
    transition: $global-transition-config;
    transition-property: color;

    @include mq($until: md) {
        @include small-font();
    }
}

// style modifier col-2
.c-text-image-group-item--col-2 {
    @include make-col-ready();

    @include mq(md) {
        @include make-col(6);
    }
    @include mq(lg) {
        @include make-col(5);
    }

    &:nth-child(odd) {
        @include mq(lg) {
            @include make-col-offset(1);
        }
    }

    &:not(:first-child) {
        margin-top: $spacing-2xl;

        @include mq(md) {
            margin-top: 0;
        }
    }

    &:not(:last-child) {
        &::after {
            position: absolute;
            bottom: 0;
            left: $grid-gutter-width / 2;
            display: block;
            width: calc(100% - #{$grid-gutter-width});
            height: 1px;
            background-color: $c-light-grey;
            content: '';

            @include mq(lg) {
                display: none;
            }
        }
    }

    .c-text-image-group-item__image {
        @include mq(md) {
            margin-bottom: $spacing-l;
        }
        @include mq(lg) {
            margin-bottom: $spacing-xl;
        }
    }

    .c-text-image-group-item__text-box {
        @include mq(lg) {
            margin-bottom: $spacing-xl;
        }
    }

    .o-link {
        margin-bottom: $spacing-m;

        @include mq(lg) {
            margin-bottom: $spacing-l;
        }
    }
}

// style modifier col-3
.c-text-image-group-item--col-3 {
    @include make-col-ready();

    @include mq(md) {
        @include make-col(4);
    }

    &:not(:first-child) {
        margin-top: $spacing-2xl;

        @include mq(md) {
            margin-top: 0;
        }
    }

    &:not(:last-child) {
        &::after {
            position: absolute;
            bottom: 0;
            left: $grid-gutter-width / 2;
            display: block;
            width: calc(100% - #{$grid-gutter-width});
            height: 1px;
            background-color: $c-light-grey;
            content: '';

            @include mq(lg) {
                display: none;
            }
        }
    }

    .c-text-image-group-item__image {
        @include mq(md) {
            margin-bottom: $spacing-l;
        }
        @include mq(lg) {
            margin-bottom: $spacing-xl;
        }
    }

    .c-text-image-group-item__text-box {
        @include mq(lg) {
            margin-bottom: $spacing-xl;
        }
    }

    .o-link {
        margin-bottom: $spacing-m;

        @include mq(lg) {
            margin-bottom: $spacing-l;
        }
    }
}

// style modifier mosaic
.c-text-image-group-item--mosaic,
.c-text-image-group-item--mosaic-left,
.c-text-image-group-item--mosaic-right {
    width: auto;
    margin-bottom: $spacing-xl;
    padding-bottom: $spacing-xl;
    padding-left: 0;

    @include mq(md) {
        @include make-row();

        margin-bottom: $spacing-2xl;
        padding-right: 0;
        padding-bottom: $spacing-2xl;
        padding-left: 0;
    }

    &:not(:last-child) {
        &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 1px;
            background-color: $c-light-grey;
            content: '';

            @include mq(md) {
                left: $grid-gutter-width / 2;
                width: calc(100% - #{$grid-gutter-width});
            }

            @include mq(lg) {
                left: calc((100% / 12) + (#{$grid-gutter-width} / 2));
                width: calc((100% / 12 * 10) - #{$grid-gutter-width});
            }
        }
    }

    .c-text-image-group-item__image {
        margin-bottom: $spacing-s;

        @include mq(md) {
            @include make-col-ready();
            @include make-col(6);

            margin-bottom: 0;
        }
        @include mq(lg) {
            @include make-col(5);
        }
    }

    .c-text-image-group-item__text-box {
        @include mq(md) {
            @include make-col-ready();
            @include make-col(6);
        }
        @include mq(lg) {
            @include make-col(5);
        }
    }

    .c-text-image-group-item__text {
        padding-bottom: 1px;
    }

    .o-link {
        margin-top: $spacing-m;

        @include mq(lg) {
            margin-top: $spacing-l;
        }
    }
}

.c-text-image-group-item--mosaic {
    &:nth-child(odd) {
        .c-text-image-group-item__image {
            @include mq(md) {
                order: 2;
            }
            @include mq(lg) {
                margin-left: 0;
            }
        }

        .c-text-image-group-item__text-box {
            @include mq(md) {
                order: 1;
            }
            @include mq(lg) {
                @include make-col-offset(1);
            }
        }
    }

    .c-text-image-group-item__image {
        @include mq(lg) {
            @include make-col-offset(1);
        }
    }
}

// style modifier mosaic-left
.c-text-image-group-item--mosaic-left {
    .c-text-image-group-item__image {
        @include mq(lg) {
            @include make-col-offset(1);
        }
    }
}

// style modifier mosaic-right
.c-text-image-group-item--mosaic-right {
    .c-text-image-group-item__image {
        @include mq(md) {
            order: 2;
        }
    }

    .c-text-image-group-item__text-box {
        @include mq(md) {
            order: 1;
        }
        @include mq(lg) {
            @include make-col-offset(1);
        }
    }
}
