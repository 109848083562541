// primary colors
$c-red: #D60F3C;
$c-light-grey: #EDEDED;
$c-black: #000;
$c-white: #fff;
$c-transparent: transparent;

$c-blue-cottano: #005095;

// secondary colors
//$c-red-1: #870926;$c-red-1 nicht gewünscht!

// grey tones
$c-grey-90: #1A1A1A;
$c-grey-80: #333333;
$c-grey-70: #4D4D4D;
$c-grey-60: #666666;
$c-grey-50: #7F7F7F;
$c-grey-40: #ADADAD;
$c-grey-30: #B3B3B3;
$c-grey-20: #CCCCCC;
$c-grey-10: #E6E6E6;
$c-grey-05: #F2F2F2;

// alias
$c-brand: $c-red;
$c-brand--cottano: $c-blue-cottano;
$c-text: $c-grey-90;
