/* ------------------------------------ *\
    #Search
\* ------------------------------------ */

.c-search {
    @include clearfix();
}

//search on search-results page
.c-search--results {
    margin: 0 calc((100vw - 100%) / -2) $spacing-2xl;
    padding-top: $spacing-m;
    padding-right: calc((100vw - 100%) / 2);
    padding-bottom: $spacing-m;
    padding-left: calc((100vw - 100%) / 2);
    background-color: $c-grey-05;

    @include mq(md) {
        margin-bottom: $spacing-3xl;
        padding-top: $spacing-xl;
        padding-bottom: $spacing-xl;
    }

    @include mq(lg) {
        margin-bottom: $spacing-4xl;
    }

    @include mq(xl) {
        margin-bottom: $spacing-5xl;
        padding-top: $spacing-2xl;
        padding-bottom: $spacing-2xl;
    }

    .c-search__row {
        @include mq(lg) {
            @include make-col(10);
            @include make-col-offset(1);
        }

        @include mq(xl) {
            @include make-col(8);
            @include make-col-offset(2);
        }
    }

    .c-search__input {
        max-height: 50px;
        padding-top: 0;
        font-weight: bold;
    }
}

//search in metanav flyout
.c-search--flyout {
    width: 100%;

    .c-search__row {
        @include mq(xl) {
            width: 70%;
            margin-left: 30%;
        }
    }

    .c-search__input {
        @include normal-font();

        color: $c-white;
        font-weight: normal;

        &::placeholder {
            color: $c-grey-20;
            opacity: 1;
        }
    }
}

.c-search__inner {
    width: 100%;
}

.c-search__form {
    @include make-row();
}

.c-search__row {
    @include make-col-ready();

    display: flex;
    justify-content: stretch;
}

/* stylelint-disable  */
.c-search__input {
    @include typo-h4(false);

    flex: 2 2 0;
    width: auto;
    min-width: 50%;
    margin: 0 ($grid-gutter-width /2) 0 0;
    padding: 0 3px 5px;
    background: $c-transparent;
    border: none;
    border-bottom: 1px solid $c-grey-30;
    -webkit-appearance: none;

    @include mq(md) {
        @include typo-h2(false, true);
    }

    &:focus {
        background-color: $c-transparent;
    }

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }
}
/* stylelint-enable */

.c-search__label {
    @include sr-only();
}

.c-search__button {
    @include textEmphasized();

    display: flex;
    margin-left: $grid-gutter-width / 2;
    padding: 16px $grid-gutter-width /2;
    color: $c-brand;
    font-weight: bold;
    background-color: $c-white;
    border: $c-grey-20;
    cursor: pointer;
    transition: $global-transition-config;
    transition-property: background-color, border, box-shadow;

    @include mq(md) {
        padding-right: $grid-gutter-width;
        padding-left: $grid-gutter-width;
    }

    &:hover,
    &:focus {
        &:not(:disabled) {
            color: $c-white;
            background-color: $c-brand;
            border-color: $c-brand;
            box-shadow: 0 5px 15px 0 rgba($c-grey-90, .2);
            cursor: default;
        }
    }

    &:disabled {
        color: $c-grey-50;
        cursor: default;
    }
}
