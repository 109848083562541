/* ------------------------------------ *\
    #Note
\* ------------------------------------ */
.c-note {
    position: relative;

    .c-sticky-header__alternate & {
        position: absolute;
        top: 5px;
        right: 70px;

        @include mq(md) {
            right: 80px;
        }
    }
}

.c-note__btn {
    width: $shared-header-top-height;
    height: $shared-header-top-height;
    color: $c-grey-50;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    transition: $global-transition-config;
    transition-property: color, border-color;

    &:hover,
    &:focus {
        color: $c-brand;

        @include mq($until: xl) {
            border-top-color: $c-brand;

            .c-sticky-header__alternate & {
                border-color: $c-transparent;
            }
        }

        @include mq(xl) {
            border-bottom-color: $c-brand;

            .c-sticky-header__alternate & {
                border-color: $c-transparent;
            }
        }

        .c-note__icon {
            fill: $c-brand;
        }

        .c-note__btn-count {
            &::after {
                background-color: $c-brand;
            }
        }
    }

    &::before,
    &::after {
        position: absolute;
        right: 7px;
        display: block;
        width: 17px;
        height: 17px;
        background-color: $c-grey-50;
        border-radius: 100%;
        visibility: hidden;
        opacity: 0;
        transition: background-color $global-transition-speed;
        content: '';
    }

    &::before {
        top: 8px;
        transition: transform $global-transition-speed cubic-bezier(.39, .575, .565, 1);
    }

    &::after {
        top: 7px;
        background-color: $c-transparent;
        box-shadow: 0 0 0 0 rgba($c-brand, .3);
        transition: background-color $global-transition-speed linear, box-shadow $global-transition-speed cubic-bezier(.175, .885, .32, 1.275);
    }

    &.is-active {
        color: $c-grey-50;

        &::before {
            background-color: $c-brand;
        }
    }

    &.is-animation {
        &::before {
            transform: scale(1.2);
        }

        &::after {
            box-shadow: 0 0 0 8px rgba($c-brand, .5);
        }
    }
}

.c-note__count {
    @include zindex(above);

    position: absolute;
    top: 7px;
    right: 5px;
    display: block;
    width: 21px;
    color: $c-white;
    font-size: 11px;
    text-align: center;
    visibility: hidden;
    opacity: 0;
}

.c-note__label {
    @include text-hide();
}

.c-note__icon {
    @include zindex(above);

    position: absolute;
    top: 2px;
    left: -4px;
    width: 48px;
    height: 48px;
    fill: currentColor;
}

.js-bookmarks-template {
    display: none;
    visibility: hidden;

    .c-overlay & {
        display: block;
        visibility: visible;
    }
}

.is-bookmark-ready {
    .c-note__btn {
        &::before,
        &::after {
            visibility: visible;
            opacity: 1;
        }
    }

    .c-note__count {
        visibility: visible;
        opacity: 1;
    }
}
