/* ------------------------------------ *\
    #FOOTER NAV
\* ------------------------------------ */
.c-pager {
    @include clearfix();
    @include make-row();

    margin-bottom: $spacing-2xl;

    @include mq(md) {
        margin-bottom: $spacing-3xl;
    }

    @include mq(lg) {
        margin-bottom: $spacing-4xl;
    }

    @include mq(xl) {
        margin-bottom: $spacing-5xl;
    }
}

.c-pager__nav {
    @include make-col-ready();

    position: relative;
    display: flex;
    width: 100%;

    @include mq(xl) {
        @include make-col-offset(1);
        @include make-col(10);
    }
}

.c-pager__list {
    @include resetListStyles();

    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid $c-grey-20;
    border-bottom: 1px solid $c-grey-20;
}

.c-pager__item {
    @include resetListStyles();
}

.c-pager__link,
.c-pager__current {
    @include text-hide();

    position: relative;
    display: block;
    width: $spacing-s;
    height: $spacing-s;
    margin: 0 8px;
    border-radius: 100%;
    opacity: 1;
    transition: background-color $global-transition-speed $global-transition-easing;
}

.c-pager__link {
    background-color: $c-grey-40;
    cursor: pointer;
    transition: $global-transition-speed $global-transition-easing;
    transition-property: color, border, background-color, box-shadow;

    &.is-back,
    &.is-forward {
        position: relative;
        display: block;
        width: 52px;
        height: 52px;
        padding: 0;
        overflow: hidden;
        color: $c-brand;
        background-color: transparent;
        border: 1px solid $c-grey-20;
        border-radius: 0;

        &.is-disabled {
            color: $c-grey-10;
        }
    }

    &.is-forward {
        margin-right: 0;
    }

    &.is-disabled {
        color: $c-grey-10;
        cursor: default;
        pointer-events: none;
    }

    &:not(.is-disabled) {
        &:hover,
        &:focus {
            text-decoration: none;
            background-color: $c-brand;
            border-color: $c-brand;
            outline: 0;
            box-shadow: 0 5px 15px 0 rgba($c-grey-90, .2);

            .o-icon {
                fill: $c-white;
            }
        }
    }

    .o-icon {
        position: absolute;
        top: 2px;
        left: 1px;
        width: 48px;
        height: 48px;
    }
}

.c-pager__current {
    background-color: $c-grey-90;
}
