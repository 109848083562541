/* ------------------------------------ *\
    Filter
\* ------------------------------------ */
.c-filter {
    position: relative;
    background-color: $c-white;

    &.is-active {
        @include mq(md) {
            box-shadow: 0 5px 15px 0 rgba($c-grey-90, .2);
        }

        .c-filter__list {
            @include mq(md) {
                box-shadow: 0 5px 15px 0 rgba($c-grey-90, .2);
            }
        }
    }
}

.c-filter__heading {
    @include zindex(top);
    @include accordionArrow(15px, false);

    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 50px;
    padding-right: 40px;
    padding-left: 20px;
    color: $c-text;
    word-break: break-all;
    hyphens: auto;
    background-color: $c-white;
    transition: background-color $global-transition-config, color $global-transition-config;

    &.collapsed {
        @include zindex(base);
    }

    &:hover {
        color: $c-white;
        text-decoration: none;
        background-color: $c-brand;
        outline: 0;
    }

    //&:focus {
    //    outline: none;
    //    box-shadow: inset 0 0 0 1px $c-grey-30;
    //}
}

.c-filter__legend {
    @include textEmphasizedSmall();

    display: flex;
    font-weight: bold;
    text-align: left;
}

.c-filter__wrapper {
    @include zindex(above);

    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 2px;
    background-color: $c-white;

    @include mq(md) {
        position: absolute !important;
    }
}

.c-filter__list {
    display: block;
}

.c-filter__list-inner {
    max-height: 220px;
    overflow-y: auto;
    border-top: 1px solid $c-light-grey;
}

.c-filter__item {
    @include make-col-ready();

    width: 100%;
    padding-top: $spacing-xs;
    padding-bottom: $spacing-xs;
    border-top: 1px solid $c-light-grey;

    &.is-hidden {
        display: none;
    }
}

.c-filter__item-label {
    @include small-font();

    position: relative;
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
    padding-left: 45px;

    &:hover {
        cursor: pointer;
    }

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        width: 26px;
        height: 26px;
        margin-right: 10px;
        color: $c-white;
        line-height: 30px;
        text-align: center;
        background-color: $c-light-grey;
        content: '';
    }

    &::after {
        position: absolute;
        top: 8px;
        left: 5px;
        display: block;
        width: 16px;
        height: 7px;
        background-color: $c-transparent;
        transform: rotate(-45deg);
        content: '';
    }
}

/* stylelint-disable */
.c-filter__input {
    @include sr-only();

    &:disabled {
        & ~ .c-filter__item-label {
            color: $c-grey-20;
            cursor: default;

            &::before {
                background-color: $c-light-grey;
                border-color: $c-light-grey;
                opacity: .5;
            }
        }
    }

    &:checked {
        & ~ .c-filter__item-label {
            &::before {
                color: $c-brand;
                border-color: $c-brand;
                content: '';
            }

            &::after {
                border-bottom: 2px solid $c-brand;
                border-left: 2px solid $c-brand;
            }
        }
    }

    &:not(:disabled) {
        &:hover,
        &:focus {
            & ~ .c-filter__item-label {
                &::before {
                    border: 1px solid $c-brand;
                    box-shadow: 0 0 0 1px $c-brand;
                }
            }
        }
    }
}
/* stylelint-enable */
