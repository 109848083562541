/* ------------------------------------ *\
    #FORMS
\* ------------------------------------ */

/*
 * Form styling block.
 *
 * 1. Set header color for form titles.
 */
.o-form h2 {
    color: $c-grey-80; /* [1] */
}

/*
 * Fieldset styling block.
 *
 * 1. Add bottom margin for fieldsets that follow another fieldset.
 */
.o-form__fieldset:has(+ .o-form__fieldset) {
    margin-bottom: 50px; /* [1] */
}

/*
 * Fieldset header styling block.
 *
 * 1. Apply typography settings and color.
 * 2. Add margin based on screen size.
 */
.o-form__fieldset-header {
    @include typo-h3; /* [1] */

    color: $c-grey-70; /* [1] */

    @include mq($until: lg) {
        margin-bottom: .125em; /* [2] */
    }

    @include mq(lg) {
        margin-bottom: .125em; /* [2] */
    }
}

/*
 * Fieldset intro styling block.
 *
 * 1. Set font style and color for the intro text.
 */
.o-form__fieldset-intro {
    @include normal-font; /* [1] */

    color: $c-grey-50; /* [1] */
}

/*
 * Group styling block.
 *
 * 1. Add bottom margin to groups.
 */
.o-form__group {
    margin-bottom: 20px; /* [1] */
}

/*
 * Checkbox and radio item styling block.
 *
 * 1. Set display to flex and align items.
 */
.o-form__checkbox-item,
.o-form__radio-item {
    display: flex; /* [1] */
    align-items: center; /* [1] */
}

/*
 * Label styling block.
 *
 * 1. Apply font settings and display properties.
 * 2. Add transition for color changes.
 * 3. Change color on hover/focus and disabled states.
 */
.o-form__label {
    @include extra-small-font; /* [1] */

    display: block; /* [1] */
    margin-bottom: .25em; /* [1] */
    color: $c-grey-50; /* [1] */
    transition: color $global-transition-config; /* [2] */

    &:has(+ .o-form__input:hover),
    &:has(+ .o-form__select:hover),
    &:has(+ .o-form__textarea:hover),
    &:has(+ .o-form__input:focus),
    &:has(+ .o-form__select:focus),
    &:has(+ .o-form__textarea:focus) {
        color: $c-grey-70; /* [3] */
    }

    &:has(+ .o-form__input[disabled]),
    &:has(+ .o-form__select[disabled]),
    &:has(+ .o-form__textarea[disabled]) {
        color: $c-grey-30; /* [3] */
    }
}

/*
 * Checkbox label styling block.
 *
 * 1. Set display and alignment.
 * 2. Style the custom checkbox appearance.
 */
.o-form__label--checkbox {
    @include textEmphasizedSmall(); /* [1] */

    display: inline-flex; /* [1] */
    align-items: center; /* [1] */
    margin-bottom: 0; /* [1] */
    color: $c-grey-70; /* [1] */
    font-weight: bold; /* [1] */
    cursor: pointer; /* [1] */

    .o-form__checkbox:checked ~ & {
        color: $c-black; /* Change color when checked */

        &::before {
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIwLjU5IDI3LjU4TDE2LjQyIDIzLjQxTDE1IDI0LjgyTDIwLjU5IDMwLjQxTDMyLjU5IDE4LjQxTDMxLjE4IDE3TDIwLjU5IDI3LjU4WiIgZmlsbD0iIzFBMUExQSIvPgo8L3N2Zz4K'); /* Background for checked state */
        }
    }

    &::before {
        display: inline-block; /* [2] */
        flex-shrink: 0; /* Prevent shrinking */
        width: 40px; /* [2] */
        height: 40px; /* [2] */
        margin-right: 20px; /* [2] */
        background-color: $c-white; /* [2] */
        background-position: -4px -5px; /* [2] */
        border: 1px solid $c-grey-20; /* [2] */
        content: ''; /* [2] */
    }

    &:hover::before,
    &:focus::before {
        outline: 0; /* [3] */
        box-shadow: 0 5px 15px 0 rgba($c-grey-90, .2); /* [3] */
    }
}

/*
 * Input, textarea, and select styling block.
 *
 * 1. Set common styles for inputs.
 * 2. Define styles for non-disabled states.
 * 3. Add placeholder styles.
 */
.o-form__input,
.o-form__textarea,
.o-form__select {
    display: block; /* [1] */
    width: 100%; /* [1] */
    min-height: 50px; /* [1] */
    padding: 12px 20px; /* [1] */
    font-size: 16px;
    letter-spacing: .05em;
    text-align: left; /* [1] */
    word-break: break-all; /* [1] */
    hyphens: auto; /* [1] */
    border: 1px solid transparent; /* [1] */
    transition: color $global-transition-config, background-color $global-transition-config, box-shadow $global-transition-config; /* [1] */
}

.o-form__input:not([disabled]),
.o-form__textarea:not([disabled]),
.o-form__select:not([disabled]) {
    color: $c-grey-70; /* [2] */
    background-color: $c-white; /* [2] */
    border-color: $c-grey-20; /* [2] */
}

/*
 * Placeholder styling block.
 *
 * 1. Set placeholder color for inputs.
 */
.o-form__input::placeholder,
.o-form__textarea::placeholder,
.o-form__select::placeholder {
    color: $c-grey-30; /* [1] */
}

/*
 * Disabled state styling block.
 *
 * 1. Set styles for disabled inputs.
 */
.o-form__input[disabled],
.o-form__textarea[disabled],
.o-form__select[disabled] {
    color: $c-grey-30; /* [1] */
    background-color: $c-grey-10; /* [1] */
    border-color: $c-grey-20; /* [1] */

    &::placeholder {
        color: $c-grey-30; /* [2] */
    }
}

/*
 * Hover and focus state styling block.
 *
 * 1. Define styles for hover and focus states.
 */
.o-form__input:not([disabled]):hover,
.o-form__textarea:not([disabled]):hover,
.o-form__select:not([disabled]):hover,
.o-form__input:not([disabled]):focus,
.o-form__textarea:not([disabled]):focus,
.o-form__select:not([disabled]):focus {
    border-color: currentColor; /* [1] */
    outline: 0; /* [1] */
    box-shadow: 0 5px 15px 0 rgba($c-grey-90, .2); /* [1] */
}

/*
 * Textarea specific styling block.
 *
 * 1. Set minimum height for textarea.
 */
.o-form__textarea {
    min-height: 200px; /* [1] */
}

/*
 * Select element styling block.
 *
 * 1. Remove default styling and set custom padding.
 * 2. Add custom dropdown icon.
 */
.o-form__select {
    position: relative; /* [1] */
    padding: 13px 30px 13px 20px; /* [1] */
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMzLjE4IDE3TDI0IDI2LjE2TDE0LjgyIDE3TDEyIDE5LjgyTDI0IDMxLjgyTDM2IDE5LjgyTDMzLjE4IDE3WiIgZmlsbD0iI0Q2MEYzQyIvPgo8L3N2Zz4K'); /* [2] */
    background-repeat: no-repeat; /* [2] */
    background-position: calc(100% - 5px) center; /* [2] */
    background-size: 32px; /* [2] */
    cursor: pointer; /* [1] */
    appearance: none; /* [1] */

    &::after {
        position: absolute; /* [3] */
        top: calc(50% - 9px); /* [3] */
        right: 24px; /* [3] */
        display: block; /* [3] */
        width: 12px; /* [3] */
        height: 12px; /* [3] */
        border-top: 3px solid $c-brand; /* [3] */
        border-right: 3px solid $c-brand; /* [3] */
        transform: rotate(135deg); /* [3] */
        transition: border $global-transition, transform $global-transition; /* [3] */
        content: ''; /* [3] */
    }
}

/*
 * Required field indicator styling block.
 *
 * 1. Set color and font-weight for required fields.
 */
.form__required-indicator {
    color: $c-red; /* [1] */
    font-weight: bold; /* [1] */

    .o-form__group--checkbox & {
        margin-left: 5px; /* Adjust margin for checkbox group */
    }
}

/*
 * Invalid state styling block.
 *
 * 1. Set styles for invalid inputs.
 */
.was-validated .o-form__input:invalid,
.was-validated .o-form__textarea:invalid,
.was-validated .o-form__select:invalid {
    color: $c-red; /* [1] */
    border-color: currentColor; /* [1] */
}

/*
 * Focused invalid state styling block.
 *
 * 1. Remove outline for focused invalid inputs.
 */
.was-validated .o-form__input:focus:invalid,
.was-validated .o-form__textarea:focus:invalid,
.was-validated .o-form__select:focus:invalid {
    outline: none; /* [1] */
}

/*
 * Valid state styling block.
 *
 * 1. Set color for valid inputs.
 */
.o-form__input:not(:placeholder-shown):not(:invalid),
.o-form__textarea:not(:placeholder-shown):not(:invalid),
.o-form__select:not(:placeholder-shown):not(:invalid) {
    color: $c-black; /* [1] */

    &[disabled] {
        color: $c-grey-30;
    }
}

/*
 * Invalid placeholder styling block.
 *
 * 1. Set placeholder color for invalid fields.
 */
.was-validated .o-form__input:invalid::placeholder,
.was-validated .o-form__textarea:invalid::placeholder,
.was-validated .o-form__select:invalid::placeholder {
    color: $c-red; /* [1] */
}

/*
 * Checkbox styling block.
 *
 * 1. Visually hide checkbox.
 */
.o-form__checkbox {
    @include sr-only; /* [1] */

    order: 1; /* [1] */
}

/*
 * Error message styling block.
 *
 * 1. Set styles for error messages.
 */
.o-form__error-message {
    @include extra-small-font; /* [1] */

    display: block; /* [1] */
    margin-top: .25em; /* [1] */
    color: $c-red; /* [1] */

    .o-form__group--checkbox & {
        margin-left: 60px; /* Adjust margin for checkbox group */
    }
}

/*
 * Button styling block.
 *
 * 1. Set button styles and transitions.
 * 2. Define hover, active, focus, and disabled states.
 */
.o-form__button {
    @include textEmphasizedSmall(); /* [1] */

    position: relative; /* [1] */
    display: block; /* [1] */
    width: auto; /* [1] */
    margin-right: 20px; /* [1] */
    margin-bottom: 10px; /* [1] */
    padding: 12px 20px; /* [1] */
    color: $c-white; /* [1] */
    font-weight: bold; /* [1] */
    background: $c-grey-70; /* [1] */
    border: 1px solid $c-grey-70; /* [1] */
    cursor: pointer; /* [1] */
    transition: color $global-transition-config, background-color $global-transition-config, box-shadow $global-transition-config; /* [1] */

    &:hover,
    &:active,
    &:focus {
        &:not(:disabled) {
            @include mq(md) {
                color: $c-white; /* [2] */
                text-decoration: none; /* [2] */
                background: $c-brand; /* [2] */
                border: 1px solid $c-brand; /* [2] */
                outline: 0; /* [2] */
                box-shadow: 0 5px 15px 0 rgba($c-grey-90, .2); /* [2] */
            }
        }
    }

    &:disabled {
        background: $c-grey-20; /* [3] */
        border: 1px solid $c-grey-20; /* [3] */
        cursor: default; /* [3] */
    }
}

/*
 * Text styling block.
 *
 * 1. Apply small font styles and adjust for larger screens.
 */
.o-form__text {
    @include small-font; /* [1] */

    @include mq(lg) {
        @include normal-font; /* [1] */
    }
}
