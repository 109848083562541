/* bootstrap overrides */
$grid-columns: 12;
$grid-gutter-width-num: 30;
$grid-gutter-width-num-half: ($grid-gutter-width-num / 2);
$grid-gutter-width: $grid-gutter-width-num * 1px;
$grid-gutter-width-double: 60px;
$mobile-margin: 30;
$desktop-margin: 60;

/* Sizes */
$max-page-width: 1600px;
$max-content-width: $max-page-width - ($grid-gutter-width * 2);

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 755px,
    lg: 992px,
    xl: 1200px,
    xxl: 1600px
);

$container-max-widths: (
    xs: 100%,
    sm: 576,
    md: 755,
    lg: 992,
    xl: 1200,
    xxl: 1600
);
