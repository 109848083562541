/* ------------------------------------ *\
    #FOOTER NAV
\* ------------------------------------ */
.c-nav {
    @include make-row();

    position: relative;
    width: 100%;
}

.c-nav--footer {
    width: calc(100% + #{$grid-gutter-width});
    padding-top: 20px;
    padding-bottom: 20px;

    @include mq(sm) {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

.c-nav--sites {
    width: calc(100% + #{$grid-gutter-width});

    @include mq($until: lg) {
        display: none;
    }
    @include mq(lg) {
        flex-wrap: nowrap;
    }
}

.c-nav__block {
    @include make-col-ready();
    @include make-col(12);

    flex-direction: column;
    margin-top: $spacing-m;
    margin-bottom: $spacing-m;

    @include mq(sm) {
        @include make-col(6);
    }
    @include mq(md) {
        margin-top: $spacing-xl;
        margin-bottom: $spacing-xl;
    }
    @include mq(xl) {
        @include make-col(4);
    }

    .c-nav--sites & {
        @include mq(lg) {
            @include make-col(3);
        }

        @include mq(xl) {
            flex: 0 0 auto;
            width: auto;

            &:first-child {
                @include make-col(5);
            }
        }
    }
}

.c-nav__title {
    @include textEmphasizedSmall();

    display: block;
    margin-bottom: 1.5rem;
    color: $c-white;
    font-weight: bold;
    font-family: $global-font-family-regular;
}

.c-nav__text {
    @include small-font();

    color: $c-white;
    font-family: $global-font-family-regular;
}

.c-nav__list {
    @include resetListStyles(false);
    @include make-row();
}

.c-nav__item {
    @include resetListStyles(false);

    display: flex;
    width: 100%;
    margin-bottom: 6px;
}

.c-nav__link {
    @include small-font();

    position: relative;
    display: block;
    width: 100%;
    padding: 2px $grid-gutter-width / 2;
    color: $c-white;
    background-color: $c-transparent;
    transition: background-color $global-transition-speed $global-transition-easing;

    @include mq(lg) {
        margin-right: $grid-gutter-width / 2;
    }

    &:visited {
        color: $c-white;
    }

    &:hover,
    &:focus {
        color: $c-white;
        text-decoration: none;
        background-color: $c-brand;
        outline: none;

        .c-nav--sites & {
            color: $c-brand;
            background-color: $c-white;

            &.is-current {
                background-color: $c-white;
            }
        }
    }

    &.is-icon {
        padding-left: 55px;
    }

    .c-nav--sites & {
        &.is-current {
            background-color: darken($c-brand, 7%);
        }
    }

    .o-icon {
        position: absolute;
        top: -10px;
        left: 0;
        width: 48px;
        height: 48px;
    }
}
