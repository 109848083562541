/* ------------------------------------ *\
    #BASE FORM ELEMENTS
\* ------------------------------------ */

input,
textarea,
select {
    border-radius: 0;
}

button {
    padding: 0;
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
}
