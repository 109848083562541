/* ------------------------------------ *\
    #ce09-content-teaser-list
\* ------------------------------------ */
.c-content-teaser-list {
    @include clearfix();
    @include make-row();

    display: flex;
    flex-wrap: wrap;
    align-items: stretch;

    &.is-double {
        .c-content-teaser-list__item {
            @include mq(lg) {
                @include make-col(6);
            }
        }
    }
}

.c-content-teaser-list__header {
    @include make-col-ready();

    width: 100%;
    color: $c-grey-50;
}

.c-content-teaser-list__item {
    @include make-col-ready();
    @include make-col(12);

    height: auto;
    margin-bottom: 40px;

    @include mq(md) {
        width: 100%;
        margin-bottom: 60px;
    }
    @include mq(lg) {
        @include make-col(4);

        margin-bottom: 80px;
        overflow: hidden;
    }
    @include mq(xl) {
        margin-bottom: 100px;
    }
}
