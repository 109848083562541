/* -------------------------------------------------------------------------- *\
    #Banner
\* -------------------------------------------------------------------------- */
.c-banner {
    @include clearfix;
    @include zindex(notifications);

    position: fixed;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    background-color: $c-grey-90;

    .is-IE & {
        display: block;
    }
}

.c-banner__box {
    @include makeSection();
}

.c-banner__inner {
    @include make-row();

    padding-top: 20px;
    padding-bottom: 20px;

    @include mq(md) {
        padding-top: 60px;
        padding-bottom: 60px;
    }
}

.c-banner__title {
    @include make-col-ready();
    @include typo-h3(true);

    display: flex;
    color: $c-white;

    @include mq(lg) {
        @include make-col(8);
    }
    @include mq(lg) {
        @include make-col(9);
    }
    @include mq(xl) {
        @include make-col-offset(1);
        @include make-col(8);
    }
}

.c-banner__text {
    @include make-col-ready();
    @include normal-font();

    display: flex;
    color: $c-white;

    @include mq(lg) {
        @include make-col(9);
    }
    @include mq(xl) {
        @include make-col-offset(1);
        @include make-col(8);
    }
}
