/* ------------------------------------ *\
    #pe03_page-intro
\* ------------------------------------ */
.c-page-intro {
    position: relative;

    &.is-line {
        padding-top: 20px;

        @include mq(md) {
            padding-top: 30px;
        }

        @include mq(lg) {
            padding-top: 60px;
        }

        @include mq(xl) {
            padding-top: 60px;
        }

        &::before {
            position: absolute;
            top: 0;
            left: -$grid-gutter-width / 2;
            display: block;
            width: calc(100% + #{$grid-gutter-width});
            height: 2px;
            background-color: $c-grey-20;
            content: '';

            @include mq(sm) {
                left: -$grid-gutter-width - $grid-gutter-width *.5;
                width: calc(100% + (#{$grid-gutter-width} * 3));
            }

            @include mq(xl) {
                left: calc((100vw - 100%) / -2);
                width: calc(100% + (100vw - 100%));
            }
        }
    }

    p {
        margin-bottom: 0;
    }
}

.c-page-intro__image-box {
    position: relative;
    display: block;
    overflow: hidden;
}

.c-page-intro__img-wrap {
    @include mq(xl) {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 100%;
        overflow: hidden;
    }

    img {
        width: 100%;
    }
}

.c-page-intro__text-box {
    @include make-row();
    @include mq($until: sm) {
        margin-right: 0;
    }
}

.c-page-intro__text-box-inner {
    @include make-col-ready();
    @include mq(md) {
        @include make-col-offset(1);
        @include make-col(9);
    }
    @include mq(xl) {
        @include make-col-offset(1);
        @include make-col(8);
    }
}

.c-page-intro__topline {
    @include textEmphasizedSmall();

    display: block;
    margin-bottom: $spacing *2;
    color: $c-grey-40;

    em {
        color: $c-grey-70;
        font-style: normal;
    }
}

.c-page-intro__topline-division {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    color: $c-brand;

    @include mq(md) {
        display: inline;
        width: auto;
    }

    + em {
        @include mq($until: md) {
            display: none;
        }
    }
}

.c-page-intro__text {
    @include normal-font();

    color: $c-grey-50;

    @include mq(lg) {
        @include large-font();
    }
}

.c-page-intro--image-large,
.c-page-intro--image-small {
    margin-right: calc((100vw - 100%) / -2);
    margin-left: calc((100vw - 100%) / -2);

    .c-page-intro__text-box-wrap {
        @include makeSection();
        @include zindex(base);
    }

    .c-page-intro__text-box {
        &::before {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: calc(100% - (#{$grid-gutter-width} / 2));
            height: 100%;
            background-color: $c-white;
            content: '';

            @include mq(sm) {
                width: calc(((100% - (#{$grid-gutter-width}) * 2) / 12 * 11) + (#{$grid-gutter-width} / 2));
            }
            @include mq(md) {
                left: - $grid-gutter-width / 2;
                width: calc((((100% - (#{$grid-gutter-width}) * 2) / 12 * 11) ) + #{$grid-gutter-width});
            }
            @include mq(lg) {
                left: - $grid-gutter-width / 2;
                width: calc((((100% - (#{$grid-gutter-width}) * 2) / 12 * 11) ) + #{$grid-gutter-width});
            }
            @include mq(xl) {
                left: calc((100vw - 100%) / -2);
                width: calc(((100% - (#{$grid-gutter-width}) * 2) / 12 * 10) + ((100vw - 100%) / 2) + #{$grid-gutter-width} / 2);
            }
        }
    }

    .c-page-intro__text-box-inner {
        @include zindex(above);

        padding-bottom: 30px;

        @include mq(sm) {
            @include make-col(11);
        }

        @include mq(md) {
            @include make-col-offset(1);
            @include make-col(10);

            padding-bottom: 40px;
        }

        @include mq(lg) {
            padding-bottom: 60px;
        }

        @include mq(xl) {
            @include make-col-offset(1);
            @include make-col(9);
        }

        &::before {
            @include zindex(zero);

            position: absolute;
            top: 0;
            right: $grid-gutter-width / -2;
            display: block;
            width: 15px;
            height: 100%;
            background: linear-gradient(to right, rgba($c-grey-90, .1) 0%, rgba($c-grey-90, 0) 100%);
            content: '';

            @include mq(sm) {
                right: 0;
            }
        }
    }

    .c-page-intro__headline {
        padding-top: 30px;
        padding-right: 30px;

        @include mq(md) {
            padding-top: 40px;
            padding-right: 40px;
        }

        @include mq(xl) {
            padding-top: 60px;
            padding-right: 80px;
        }
    }

    .c-page-intro__text {
        padding-right: 30px;

        @include mq(md) {
            padding-right: 40px;
        }

        @include mq(xl) {
            padding-right: 80px;
        }
    }

    .c-page-intro__video-box {
        video {
            width: 100%;
            height: auto;
        }
    }
}

/* ------------------------------------ *\
    Context: Image small
\* ------------------------------------ */
.c-page-intro--image-large {
    .c-page-intro__image-box {
        @include mq(xl) {
            height: 600px;
        }
    }

    .c-page-intro__text-box-wrap {
        margin-top: -40px;

        @include mq(sm) {
            margin-top: -70px;
        }
        @include mq(md) {
            margin-top: -100px;
        }
        //@include mq(lg) {
        //    margin-top: -100px;
        //}
        //@include mq(xl) {
        //    margin-top: -100px;
        //}
    }
}

/* ------------------------------------ *\
    Context: Image small
\* ------------------------------------ */
.c-page-intro--image-small {
    .c-page-intro__image-box {
        @include mq(xl) {
            height: 400px;
        }
    }

    .c-page-intro__text-box-wrap {
        margin-top: -40px;

        @include mq(sm) {
            margin-top: -50px;
        }
        @include mq(md) {
            margin-top: -60px;
        }
        @include mq(lg) {
            margin-top: -80px;
        }
        @include mq(xl) {
            margin-top: -100px;
        }
    }
}
