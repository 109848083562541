/* ------------------------------------ *\
    #Product-Filter
\* ------------------------------------ */
.c-product-filter {
    position: relative;
    flex-direction: column;

    &.is-active {
        .c-product-filter__form {
            opacity: 1;
        }
    }

    .collapse,
    .collapsing {
        transition: $global-transition-easing-smooth;
        transition-duration: .2s;
        transition-property: height;
    }

    .c-product-teaser-list {
        display: none;
    }

    .c-product-teaser-list__item {
        opacity: 0;
        transition: transform .2s $global-transition-easing-slidein, opacity .5s linear;

        &.is-visible {
            opacity: 1;
        }
    }
}

.c-product-filter__form {
    display: none;
    opacity: 0;
    transition: opacity .2s linear;

    @include mq(md) {
        @include moduleSpace();

        display: flex;
        flex-wrap: wrap;
    }

    .c-overlay & {
        display: flex;
        flex-direction: column;
        height: calc(100% - 80px);
        padding-top: 70px;
        overflow-y: scroll;
        background-color: $c-light-grey;
        opacity: 1;
    }
}

.c-product-filter__form-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 80px;
    padding-right: 15px;
    background-color: $c-white;
    box-shadow: 0 5px 15px 0 rgba($c-grey-90, .2);

    @include mq(md) {
        display: none;
    }

    .c-product-filter__button {
        align-items: center;
        margin: auto 0 auto auto;
    }
}

.c-product-filter__menu {
    width: 100%;
    background-color: $c-light-grey;
}

.c-product-filter__title {
    @include zindex(topsy);
    @include typo-h3(false);

    position: fixed;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 70px;
    padding: 20px 60px 10px $grid-gutter-width /2;
    background-color: $c-light-grey;
    box-shadow: 0 5px 15px 0 rgba($c-grey-90, .2);

    @include mq(md) {
        display: none;
    }

    .c-overlay & {
        display: block;
    }
}

.c-product-filter__header {
    position: relative;

    .c-overlay & {
        display: none;

        @include mq(md) {
            display: none;
        }
    }
}

.c-product-filter__header-button {
    @include accordionArrow(36px, true);

    width: 100%;
    padding: 16px 60px 14px $grid-gutter-width /2;
    color: $c-text;
    font-weight: bold;
    line-height: 20px;
    text-align: left;
    background-color: $c-transparent;
    transition: background-color $global-transition-config, color $global-transition-config;
    fill: currentColor;

    @include mq(sm) {
        padding: 15px 60px 15px $grid-gutter-width /2;
    }

    @include mq(md) {
        padding-left: $grid-gutter-width /2;
    }

    @include mq(lg) {
        padding-left: 30px;
    }

    &:hover {
        color: $c-white;
        text-decoration: none;
        background-color: $c-brand;
        outline: 0;
    }

    .c-overlay & {
        &::after {
            display: none;
        }
    }
}

.c-product-filter__legend {
    @include typo-h3(false);
}

.c-product-filter__bar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding: $grid-gutter-width/2 $grid-gutter-width/2 0 $grid-gutter-width/2;
    border: 2px solid $c-light-grey;

    @include mq(md) {
        padding: 20px $grid-gutter-width/2 10px $grid-gutter-width/2;
    }
    @include mq(lg) {
        padding: 30px $grid-gutter-width 20px $grid-gutter-width;
    }
}

.c-product-filter__reset {
    @include textEmphasizedSmall();

    position: relative;
    display: block;
    width: auto;
    margin-right: 20px;
    margin-bottom: 10px;
    padding: 12px 20px;
    color: $c-white;
    font-weight: bold;
    text-align: left;
    word-break: break-all;
    hyphens: auto;
    background: $c-grey-70;
    border: 1px solid $c-grey-70;
    transition: $global-transition-config;
    transition-property: color, background-color, box-shadow;

    &:hover,
    &:active,
    &:focus {
        &:not(:disabled) {
            @include mq(md) {
                color: $c-white;
                text-decoration: none;
                background: $c-brand;
                border: 1px solid $c-brand;
                outline: 0;
                box-shadow: 0 5px 15px 0 rgba($c-grey-90, .2);
            }
        }
    }

    &:disabled {
        background: $c-grey-20;
        border: 1px solid $c-grey-20;
        cursor: default;
    }

    &.is-facet {
        padding-right: 40px;
        color: $c-text;
        background-color: $c-white;
        border: 1px solid $c-grey-20;

        &::before,
        &::after {
            @include zindex(base);

            position: absolute;
            top: calc(50% - 1px);
            right: 15px;
            display: block;
            width: 18px;
            height: 2px;
            background-color: $c-grey-50;
            transition: background $global-transition-config;
            content: '';
        }

        &::before {
            transform: rotate(45deg);
        }

        &::after {
            transform: rotate(-45deg);
        }

        &:hover,
        &:active,
        &:focus {
            &::before,
            &::after {
                @include mq(md) {
                    background-color: $c-white;
                }
            }
        }
    }
}

.c-product-filter__content {
    position: relative;
    display: flex;
    padding: 0 $grid-gutter-width /2;

    @include mq(md) {
        padding: 10px $grid-gutter-width /2;
    }

    @include mq(lg) {
        padding: 10px 0;
    }
}

.c-product-filter__list {
    @include make-row();

    @include mq(md) {
        width: calc(100% + #{$grid-gutter-width});
        margin-right: -$grid-gutter-width /2;
        margin-left: -$grid-gutter-width /2;
    }
    @include mq(lg) {
        margin-right: $grid-gutter-width /2;
        margin-left: $grid-gutter-width /2;
    }
}

.c-product-teaser-list__quantity {
    font-style: normal;
}

.c-product-filter__item {
    @include make-col-ready();

    position: relative;
    margin-bottom: 1px;

    @include mq(md) {
        @include make-col(6);

        margin-top: 5px;
        margin-bottom: 5px;
    }

    @include mq(lg) {
        @include make-col(4);

        margin-top: 5px;
        margin-bottom: 5px;
    }

    @include mq(xl) {
        @include make-col(3);
    }

    &.is-hidden {
        display: none;
    }
}

.c-product-filter__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.c-product-filter__button {
    @include textEmphasized();

    width: auto;
    padding: 13px 30px;
    color: $c-white;
    font-weight: bold;
    background-color: $c-grey-80;
    transition: $global-transition-config;
    transition-property: background-color, box-shadow;

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        background-color: $c-brand;
        outline: 0;
        box-shadow: 0 5px 15px 0 rgba($c-grey-90, .2);
    }

    &.is-hidden {
        display: none;
    }

    &.is-opener {
        margin-bottom: $spacing-3xl;

        @include mq(md) {
            display: none;
        }
    }
}

.c-product-filter__results {
    .c-product-teaser-list {
        display: flex;
    }
}
