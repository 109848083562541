/* ------------------------------------ *\
    #SLIDER-TEASER
\* ------------------------------------ */
$c-slider-teaser-image-offset-x-xs: 15px;

.c-stage {
    @include clearfix();

    position: relative;
}

.c-stage__item {
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    color: $c-text;

    @include mq($until:lg) {
        background-color: $c-light-grey;
    }

    &.is-dark {
        color: $c-white;

        @include mq($until:lg) {
            background-color: $c-grey-80;
        }
    }
}

.c-stage__media-box {
    position: relative;
    display: flex;
    width: 100%;
    height: 0;
    padding-bottom: 50%;

    @include mq(xl) {
        height: 600px;
        padding-bottom: 0;
    }

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: calc(100% + 1px);
        background: linear-gradient(-90deg, rgba($c-white, .05) 15%, rgba($c-white, .4) 100%);
        content: '';
    }

    .c-stage--large & {
        padding-bottom: 56.25%;

        @include mq(xxl) {
            height: 900px;
            padding-bottom: 0;
        }
    }

    .is-dark & {
        color: $c-white;

        &::after {
            background: linear-gradient(-90deg, rgba($c-grey-90, .05) 15%, rgba($c-grey-90, .4) 100%);
        }
    }
}

.c-stage__media {
    display: block;
    width: 100%;
    margin-bottom: 0;
    margin-left: 0;

    @include mq(xl) {
        position: absolute;
        left: 0;
        display: flex;
        align-self: center;
        justify-content: center;
    }

    .c-stage--large & {
        @include mq(xl) {
            position: static;
            align-self: flex-start;
            justify-content: flex-start;
        }

        @include mq(xxl) {
            position: absolute;
            left: 0;
            align-self: center;
            justify-content: center;
        }
    }
}

.c-stage__img-wrap {
    width: 100%;
    height: auto;
}

.c-stage__img {
    display: block;
    width: 100%;
}

.c-stage__video {
    display: block;
    width: 100%;
    height: auto;

    @include mq(xl) {
        height: 100%;
    }
}

.c-stage__text-box {
    @include makeSection();

    padding-bottom: $spacing-2xl;

    @include mq(lg) {
        position: absolute;
        bottom: 0;
        left: 0;
        padding-bottom: $spacing-3xl;
    }

    @include mq(xl) {
        padding-bottom: $spacing-4xl;
    }
    @include mq(xxl) {
        left: calc((100vw - (#{$max-page-width})) / 2);
        width: calc(#{$max-page-width} + #{$grid-gutter-width});
        max-width: none;
    }
}

.c-stage__text {
    @include make-col-ready();

    margin-top: 30px;

    @include mq(lg) {
        @include make-col(8);

        margin-top: 0;
    }

    @include mq(xl) {
        @include make-col-offset(1);
        @include make-col(7);
    }
}

.c-stage__headline {
    @include typo-stage();

    margin-bottom: $spacing-m;
    font-weight: normal;

    @include mq(lg) {
        margin-bottom: $spacing-xl;
        margin-left: -3px;
    }
    @include mq(xl) {
        margin-bottom: $spacing-2xl;
    }
}

.c-stage__topline {
    @include textEmphasized();

    display: block;
    margin-bottom: $spacing-s;
    margin-left: 3px;
    font-weight: bold;
}
