/* -------------------------------------------------------------------------- *\
    #LANGUAGE_NAV
\* -------------------------------------------------------------------------- */

/**
 * Begin of module
 */

.c-language-nav {
    width: 100%;
}

.c-language-nav__list {
    @include resetListStyles(false);

    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;

    @include mq(sm) {
        flex-direction: row;
    }

    @include mq(lg) {
        justify-content: flex-end;
    }
}

.c-language-nav__item {
    @include resetListStyles(false);

    width: 100%;
    margin-bottom: 1px;

    @include mq(sm) {
        width: auto;
        margin-bottom: 0;
        margin-left: 15px;
    }

    &:first-child {
        margin-left: 0;
    }
}

.c-language-nav__link {
    @include textEmphasized();

    display: block;
    padding: 13px 15px;
    color: $c-text;
    font-weight: bold;
    background: $c-grey-10;
    transition: $global-transition-config;
    transition-property: color, background-color;

    &.is-current {
        color: $c-black;
        background: $c-white;
    }

    &:hover,
    &:focus {
        text-decoration: none;

        &:not(.is-current) {
            color: $c-white;
            background: $c-brand;
        }
    }
}

.c-language-nav__icon {
    width: 48px;
    height: 48px;
    margin: -20px -12px -20px -5px;
}
