/* ------------------------------------ *\
    #ce36_product-pdf
\* ------------------------------------ */
.c-product-pdf {
    @include clearfix();

    position: relative;
    margin-right: 30px;
    margin-left: 60px;
    padding-bottom: 20px;
    page-break-before: always;

    .o-module {
        margin-top: 20px;
        margin-bottom: 60px;
    }

    .c-product-table {
        position: relative;
        margin: 0;
        padding: 0;
        page-break-inside: avoid;
        background-color: $c-white;

        .c-product-table__header {
            margin-bottom: 20px;
        }

        .c-product-table__headline {
            color: $c-text;
            font-size: 15px;
            line-height: 20px;
        }

        .c-product-table__topline {
            display: none;
        }

        .c-product-table__text {
            font-size: 12px;
            line-height: 16px;
        }

        .c-product-table__table {
            width: 100%;
            word-wrap: break-word;
            table-layout: fixed;
        }

        .c-product-table__row {
            border-bottom: 1px solid $c-text;
        }

        .c-product-table__title {
            width: auto;
            min-width: unset;
            padding: 3px 5px;
            font-size: 12px;
            line-height: 16px;
            border-right: 1px solid $c-white;

            &:last-of-type {
                border-right: 0;
            }

            &.is-colspan-2,
            &.is-colspan-3,
            &.is-colspan-4,
            &.is-colspan-5,
            &.is-colspan-6,
            &.is-colspan-7,
            &.is-colspan-8,
            &.is-colspan-9 {
                min-width: inherit;
            }
        }

        .c-product-table__cell {
            padding: 3px 5px;
            font-size: 12px;
            line-height: 16px;
            vertical-align: text-top;
            border-right: 1px solid $c-text;

            &:last-of-type {
                border-right: 0;
            }
        }

        .c-product-table__legend {
            margin-top: 5px;
            font-size: 12px;
            line-height: 16px;
        }
    }
}

.c-product-pdf__header {
    //
}

.c-product-pdf__headline {
    width: 100%;
    margin-bottom: 10px;
    font-size: 32px;
    line-height: 42px;

    .c-product-pdf__topline {
        @include textEmphasizedSmall();

        display: block;
        margin-bottom: 4px;
        color: $c-grey-40;
        font-size: 12px;
        line-height: 16px;

        em {
            color: $c-grey-70;
            font-style: normal;
        }
    }
}

.c-product-pdf__text {
    position: relative;
    display: inline-block;
    width: 60%;
    margin-bottom: 0;
}

.c-product-pdf__content {
    position: relative;
    display: inline-block;
    width: 100%;
    margin-bottom: 60px;
}

.c-product-pdf__image-box {
    position: relative;
    display: inline-block;
    float: right;
    width: 33%;
    margin-top: 5px;
    padding-left: $grid-gutter-width /2;

    img {
        width: 100%;
        height: auto;
    }
}

.c-product-pdf__text-box {
    position: relative;
    display: inline-block;
    width: 60%;
    margin-right: $grid-gutter-width;
    margin-bottom: 0;
}

.c-product-pdf__text {
    margin-bottom: 30px;
    color: $c-grey-50;
    font-size: 16px;
    line-height: 20px;
}

.c-product-pdf__info {
    margin-bottom: $spacing-xl;
    color: $c-text;
    page-break-inside: avoid;

    &:last-of-type {
        margin-bottom: 0;
    }
}

.c-product-pdf__info-headline {
    margin-bottom: 10px;
    font-size: 15px;
    line-height: 20px;
}

.c-product-pdf__info-body {
    font-size: 12px;
    line-height: 16px;
    page-break-inside: avoid;
    counter-reset: main-counter;

    h2,
    h3,
    h4,
    h5 {
        margin-bottom: 10px;
        font-size: 15px;
        line-height: 20px;
    }

    p {
        &:last-child {
            margin-bottom: 0;
        }
    }

    ol,
    ul {
        padding-left: 0;
        list-style-type: none;

        &:last-child {
            margin-bottom: 0;
        }
    }

    ul {
        @include list-unordered();
    }

    ol {
        @include list-ordered();
    }

    ul {
        li {
            margin-bottom: 2px;

            &:last-child {
                margin-bottom: 0;
            }

            &::before {
                top: 6px;
                left: 4px;
                width: 10px;
                height: 5px;
                background-color: transparent;
                border-bottom: 1px solid $c-brand;
                border-left: 1px solid $c-brand;
                border-radius: 0;
                /* stylelint-disable  */
                -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
                /* stylelint-enable */
            }

            > ul {
                padding-top: 2px;
            }

            li {
                &::before {
                    background-color: transparent;
                    border-bottom: 1px solid $c-grey-20;
                    border-left: 1px solid $c-grey-20;
                }
            }
        }
    }

    b,
    strong {
        font-weight: bold;
    }

    i,
    em {
        font-style: italic;
    }
}

.c-product-pdf__contact {
    position: relative;
    margin-top: 20px;
    page-break-inside: avoid;
}

.c-product-pdf__contact-headline {
    @include textBold();

    padding-bottom: 20px;
    font-size: 15px;
    line-height: 20px;
    border-bottom: 1px solid $c-text;
}

.c-product-pdf__contact-content {
    position: relative;
    /* stylelint-disable  */
    width: -webkit-calc(100% + 30px);
    /* stylelint-enable */
    margin-right: -$grid-gutter-width /2;
    margin-left: -$grid-gutter-width /2;
}

.c-product-pdf__contact-box {
    display: inline-block;
    float: left;
    width: 33.3%;
    padding: 0 $grid-gutter-width /2;
    vertical-align: top;

    &:last-of-type {
        margin-right: 0;
    }
}

.c-product-pdf__contact-text {
    margin-bottom: 5px;
    font-size: 12px;
    line-height: 16px;

    &:last-of-type {
        margin-bottom: 0;
    }

    strong {
        @include textBold();
    }
}

.c-product-pdf__contact-name {
    @include textBold();

    margin-bottom: 8px;
    font-size: 12px;
    line-height: 16px;
}

@media print {
    html {
        overflow-y: visible;
    }

    .c-product-pdf {
        letter-spacing: .1em;
        page-break-before: avoid;

        + .c-product-pdf {
            page-break-before: always;
        }

        .c-product-pdf__info,
        .c-product-pdf__contact,
        .c-product-table {
            page-break-inside: avoid;
        }
    }
}
