/* ------------------------------------ *\
    #ce32-product-table
\* ------------------------------------ */
.c-product-table {
    @include make-row();

    position: relative;
    padding-top: $spacing-xl;
    padding-right: $grid-gutter-width / 2;
    padding-bottom: $spacing-xl;
    padding-left: $grid-gutter-width / 2;
    background-color: $c-grey-05;

    @include mq(sm) {
        margin-right: calc((#{$grid-gutter-width} / -2) - #{$grid-gutter-width});
        margin-left: calc((#{$grid-gutter-width} / -2) - #{$grid-gutter-width});
        padding-right: calc((#{$grid-gutter-width} / 2) + #{$grid-gutter-width});
        padding-left: calc((#{$grid-gutter-width} / 2) + #{$grid-gutter-width});
    }

    @include mq(md) {
        padding-top: $spacing-2xl;
        padding-bottom: $spacing-2xl;
    }

    @include mq(lg) {
        padding-top: $spacing-4xl;
        padding-bottom: $spacing-4xl;
    }

    @include mq(xl) {
        margin-right: calc((100vw - 100%) / -2);
        margin-left: calc((100vw - 100%) / -2);
        padding-top: $spacing-5xl;
        padding-right: calc(((100vw - 100%) / 2));
        padding-bottom: $spacing-5xl;
        padding-left: calc(((100vw - 100%) / 2));
    }

    @include mq(xxl) {
        padding-top: $spacing-5xl;
        padding-bottom: $spacing-5xl;
    }

    .o-module {
        margin-top: 40px;
        margin-bottom: 40px;
    }
}

.c-product-table__header {
    margin-bottom: $spacing-m;

    @include mq($until: sm) {
        padding-right: 0;
        padding-left: 0;
    }
    @include mq(sm) {
        //margin-bottom: $spacing-2xl;
    }
    @include mq(lg) {
        @include make-col(9);
    }
    @include mq(xl) {
        margin-bottom: $spacing-xl;
    }
    @include mq(xxl) {
        @include make-col(7);
    }
}

.c-product-table__headline {
    @include typo-h2(false, true);

    color: $c-text;
}

.c-product-table__topline {
    @include textEmphasized();

    display: block;
    margin-bottom: 10px;
    color: $c-grey-40;
}

.c-product-table__text {
    margin-top: 1.25rem;

    @include mq(xl) {
        margin-top: 1.875rem;
    }
}

.c-product-table__table-wrapper {
    @include zindex(base);

    position: relative;
    width: 100%;
    overflow-x: auto;
    background-color: $c-white;
    background-image:
        linear-gradient(to right, $c-white, $c-white),
        linear-gradient(to right, $c-white, $c-white),
        linear-gradient(to right, rgba($c-grey-80, .2), rgba($c-white, 0)),
        linear-gradient(to left, rgba($c-grey-80, .25), rgba($c-white, 0));
    background-repeat: no-repeat;
    background-position: left center, right center, left center, right center;
    background-size: 20px 100%, 20px 100%, 20px 100%, 20px 100%;
    background-attachment: local, local, scroll, scroll; /* Opera doesn't support this in the shorthand */
}

.c-product-table__table {
    @include small-font();

    position: relative;
    width: auto;
    min-width: 100%;
    color: $c-text;
    text-align: left;
    caption-side: bottom;

    @include mq(lg) {
        @include normal-font();
    }

    abbr {
        margin: 0;
        padding: 0;
        text-decoration: none;
        border: 0;
        outline: 0;
    }
}

.c-product-table__row {
    border-bottom: 1px solid rgba($c-black, .05); //$c-grey-05;
}

.c-product-table__title {
    @include textEmphasized();

    min-width: 240px;
    padding: 11px 10px;
    color: $c-white;
    font-weight: bold;
    text-align: left;
    text-transform: none;
    vertical-align: text-top;
    background-color: $c-grey-70;
    border-right: 1px solid $c-grey-05; //rgba($c-black, .05); //$c-grey-05;

    @include mq(sm) {
        min-width: 280px;
    }

    @include mq(lg) {
        min-width: 300px;
        padding: 16px 15px;
    }

    &.is-subhead {
        color: $c-text;
        background-color: $c-transparent;
    }

    &.is-colspan-2 {
        min-width: calc(240px * 2);

        @include mq(sm) {
            min-width: calc(280px * 2);
        }

        @include mq(md) {
            min-width: calc(300px * 2);
        }
    }

    &.is-colspan-3 {
        min-width: calc(240px * 3);

        @include mq(sm) {
            min-width: calc(280px * 3);
        }

        @include mq(md) {
            min-width: calc(300px * 3);
        }
    }

    &.is-colspan-4 {
        min-width: calc(240px * 4);

        @include mq(sm) {
            min-width: calc(280px * 4);
        }

        @include mq(md) {
            min-width: calc(300px * 4);
        }
    }

    &.is-colspan-5 {
        min-width: calc(240px * 5);

        @include mq(sm) {
            min-width: calc(280px * 5);
        }

        @include mq(md) {
            min-width: calc(300px * 5);
        }
    }

    &.is-colspan-6 {
        min-width: calc(240px * 6);

        @include mq(sm) {
            min-width: calc(280px * 6);
        }

        @include mq(md) {
            min-width: calc(300px * 6);
        }
    }

    &.is-colspan-7 {
        min-width: calc(240px * 7);

        @include mq(sm) {
            min-width: calc(280px * 7);
        }

        @include mq(md) {
            min-width: calc(300px * 7);
        }
    }

    &.is-colspan-8 {
        min-width: calc(240px * 8);

        @include mq(sm) {
            min-width: calc(280px * 8);
        }

        @include mq(md) {
            min-width: calc(300px * 8);
        }
    }

    &.is-colspan-9 {
        min-width: calc(240px * 9);

        @include mq(sm) {
            min-width: calc(280px * 9);
        }

        @include mq(md) {
            min-width: calc(300px * 9);
        }
    }

    // counting through the cols to set width of cols
    &:first-of-type {
        &:nth-last-child(2) {
            width: 50%;

            ~ .c-product-table__title {
                width: 50%;
            }
        }

        &:nth-last-child(3) {
            width: 50%;

            @include mq(lg) {
                width: calc(100% / 3);
            }

            ~ .c-product-table__title {
                width: calc(100% / 2);

                @include mq(lg) {
                    width: calc(100% / 3);
                }
            }
        }

        &:nth-last-child(4) {
            width: 50%;

            @include mq(lg) {
                width: calc(100% / 4);
            }

            ~ .c-product-table__title {
                width: 50%;

                @include mq(lg) {
                    width: calc(100% / 4);
                }
            }
        }

        &:nth-last-child(5) {
            width: 50%;

            @include mq(xl) {
                width: calc(100% / 5);
            }

            ~ .c-product-table__title {

                @include mq(xl) {
                    width: calc(100% / 5);
                }
            }
        }

        &:nth-last-child(6),
        &:nth-last-child(7),
        &:nth-last-child(8),
        &:nth-last-child(9),
        &:nth-last-child(10),
        &:nth-last-child(11),
        &:nth-last-child(12) {
            width: 50%;

            ~ .c-product-table__title {
                width: auto;
            }
        }
    }
}

.c-product-table__cell {
    padding: 8px 10px;
    vertical-align: text-top;
    border-right: 1px solid rgba($c-black, .05); //$c-grey-05;

    @include mq(lg) {
        padding: 11px 15px;
    }
}

.c-product-table__legend {
    @include make-col-ready();
    @include extra-small-font();

    margin-top: $spacing-xs;
    color: $c-grey-50;
}

/* ------------------------------------ *\
    #ce32-product-table in PDF
\* ------------------------------------ */

@media print {
    .c-product-table--pdf {
        .c-product-table__header,
        .c-product-table__table-wrapper {
            letter-spacing: .1em;
        }
    }
}

.c-product-table--pdf {
    .c-product-table__title {
        min-width: unset;

        &.is-colspan-2,
        &.is-colspan-3,
        &.is-colspan-4,
        &.is-colspan-5,
        &.is-colspan-6,
        &.is-colspan-7,
        &.is-colspan-8,
        &.is-colspan-9 {
            width: auto;
            min-width: unset;
        }

        // counting through the cols to set width of cols
        &:first-of-type {
            &:nth-last-child(2),
            &:nth-last-child(3),
            &:nth-last-child(4),
            &:nth-last-child(5),
            &:nth-last-child(6),
            &:nth-last-child(7),
            &:nth-last-child(8),
            &:nth-last-child(9),
            &:nth-last-child(10),
            &:nth-last-child(11),
            &:nth-last-child(12) {
                width: auto;

                ~ .c-product-table__title {
                    width: auto;
                }
            }
        }
    }
}
