/* -------------------------------------------------------------------------- *\
    #MAIN_NAV
\* -------------------------------------------------------------------------- */

$c-main-nav-transition-speed:   $global-transition-speed;

/**
 * Begin of module
 */
.c-main-nav {
    display: flex;
    align-items: flex-end;
    align-self: stretch;
    height: 100%;

    .is-fff &,
    .is-cv & {
        align-items: flex-end;
        padding-top: $spacing-xl;
    }
}

.c-main-nav__list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.c-main-nav__list--l1 {
    @include small-font();

    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 15px;
    margin-left: -1em;

    .is-fff &,
    .is-cv & {
        margin-bottom: 0;
    }
}

.c-main-nav__list--l2 {
    @include make-row();

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    color: $c-white;
}

.c-main-nav__list--l3 {
    margin-right: -$grid-gutter-width / 2;
    margin-bottom: $spacing-xl;
    margin-left: -$grid-gutter-width / 2;
}

.c-main-nav__button {
    @include textEmphasizedSmall();

    position: relative;
    display: block;
    color: $c-grey-70;
    font-weight: bold;
    text-decoration: none;
    transition: $c-main-nav-transition-speed $global-transition-easing;
    transition-property: color, background-color;

    &.has-children::after {
        @include zindex(base);

        position: absolute;
        left: calc(50% - 10px);
        display: block;
        width: 20px;
        height: 20px;
        background: $c-white;
        transform: translateY(5px) rotate(45deg);
        opacity: 0;
        content: '';
        pointer-events: none;

        .is-fff &,
        .is-cv & {
            top: 55px;
        }
    }

    &:hover,
    &:focus,
    &.is-active {
        color: $c-white;
        background: $c-brand;
        border: 0;
        outline: 0;
        transition-delay: unset;
    }

    &:hover,
    &.is-active {
        &.has-children::after {
            opacity: 1;
            transition-delay: 0s;
        }
    }

    &.is-current {
        background: $c-grey-10;

        &:hover,
        &:focus {
            color: $c-white;
            background: $c-brand;
        }

        &:hover {
            &.has-children::after {
                opacity: 1;
            }
        }
    }
}

.c-main-nav__item {
    margin: 0;
    padding: 0;
}

.c-main-nav__item--l1 {
    margin-right: 1px;

    &:last-child {
        margin-right: 0;
    }

    &:nth-last-child(1) {
        .c-main-nav__list--l2 {
            justify-content: flex-end;
        }
    }

    &:nth-last-child(2) {
        .c-main-nav__list--l2 {
            justify-content: flex-end;
        }
    }

    &.is-active {
        .c-main-nav__button {
            color: $c-white;
            background: $c-brand;
            border: 0;
            transition-delay: unset;

            &.has-children::after {
                opacity: 1;
                transition-delay: 0s;
            }
        }
    }

    .is-fff &,
    .is-cv & {
        padding-bottom: $spacing-xl;
    }
}

/* stylelint-disable no-descending-specificity  */
.c-main-nav__item--l2 {
    @include make-col-ready();
    @include make-col(3);

    &:first-child {
        &:nth-last-child(2) {
            @include make-col-offset(6);
        }

        &:nth-last-child(3) {
            @include make-col-offset(3);
        }
    }

    &:not(.has-children) {
        .c-main-nav__link--l2 {
            margin-bottom: 0;
        }
    }
}
/* stylelint-enable */

.c-main-nav__item--l3 {
    margin-bottom: 2px;
}

.c-main-nav__link-wrap {
    position: relative;
    display: block;
    padding: 12px 17px;
    transition: $c-main-nav-transition-speed $global-transition-easing;
    transition-property: background-color;
}

.c-main-nav__link {
    display: block;
    color: $c-white;
    text-decoration: none;
    box-shadow: none;

    &:hover,
    &:focus,
    &.is-active {
        text-decoration: none;
    }
}

.c-main-nav__link--l2 {
    @include small-font();

    margin-bottom: 5px;
    margin-left: -15px;
    padding: 5px 15px 4px 15px;
    color: $c-white;
    font-weight: normal;
    font-size: 22px;
    font-family: $global-font-family-regular;
}

.c-main-nav__link--l3 {
    @include small-font();

    margin-right: $grid-gutter-width /2;
    padding: 0 $grid-gutter-width /2;
    color: $c-white;
    font-size: 18px;
    font-family: $global-font-family-bold;
    text-transform: none;
}

.c-main-nav__link--l2,
.c-main-nav__link--l3 {
    position: relative;
    transition: .5 * $c-main-nav-transition-speed $global-transition-easing;
    transition-property: background, color;

    &.is-current {
        color: $c-grey-80;
        background-color: $c-grey-20;

        &:hover,
        &:focus {
            color: $c-white;
            background-color: $c-brand;
        }
    }

    &:hover,
    &:focus {
        color: $c-white;
        background-color: $c-brand;
    }
}

/*
 * 1. Add an invisible block that places between the flyout and the nav item
 *    it belongs to. This is required in order to not lose mouseover state
 *    when moving the mouse from the item into the flyout and vice versa.
 */
.c-main-nav__flyout {
    position: absolute;
    right: 0;
    left: 0;
    display: none;
    margin-top: 15px;
    margin-right: calc((100vw - 100%) / -2);
    margin-left: calc((100vw - 100%) / -2);
    padding: 40px 0 40px;
    background: $c-grey-60;

    &::before {
        @include zindex(base);

        position: absolute;
        top: -15px;
        display: block;
        width: 100%;
        height: 15px;
        content: '';
    }

    .no-js .c-main-nav__item--l1:hover &,
    .no-js .c-main-nav__button:hover ~ &,
    .no-js .c-main-nav__item--l1.is-active & {
        display: block;
    }

    .is-fff &,
    .is-cv & {
        margin-top: $spacing-xl;
    }
}

.c-main-nav__subnav {
    @include makeSection();
}

/*
 * This element creates the semi-opaque dark layer that overlays the entire page
 * when the main navigation is opened.
 *
 * 1. Position the layer fixed to span the whole screen.
 * 2. Initially hide the layer.
 * 3. Show the layer when the main nav is opened.
 * 4. Set semi-opaque background color.
 * 5. Set transition config.
 */
.c-main-nav-overlay {
    @include zindex(below);

    position: fixed; /* [1] */
    top: 0; /* [1] */
    right: 0; /* [1] */
    bottom: 0; /* [1] */
    left: 0; /* [1] */
    background: $shared-menu-overlay-bg; /* [4] */
    opacity: 0; /* [2] */
    transition: $c-main-nav-transition-speed $global-transition-easing;
    transition-property: opacity; /* [5] */

    .main-nav-is-open & {
        opacity: 1;
    }

    &.is-visible {
        @include zindex(above);
    }
}
