/* ------------------------------------ *\
    #ce04_accordion
\* ------------------------------------ */

.c-accordion {
    @include clearfix();
    @include moduleSpace();

    .collapsing {
        transition: $global-transition-easing-smooth;
        transition-duration: .5s;
        transition-property: height;
    }
}

.c-accordion__header {
    @include make-row();

    margin-bottom: $spacing-m;

    @include mq(md) {
        margin-bottom: $spacing-xl;
    }

    @include mq(xl) {
        margin-bottom: $spacing-2xl;
    }
}

.c-accordion__topline {
    @include textEmphasized();

    display: block;
    margin-bottom: 10px;
    color: $c-grey-40;
}

.c-accordion__headline {
    @include make-col-ready();
    @include mq(lg) {
        @include make-col(10);
    }
    @include mq(xl) {
        @include make-col(9);
    }
    @include mq(xxl) {
        @include make-col(8);
    }
}

.c-accordion__intro {
    @include make-col-ready();
    @include mq(lg) {
        @include make-col(10);
    }
    @include mq(xl) {
        @include make-col(9);
    }
    @include mq(xxl) {
        @include make-col(8);
    }
}

.c-accordion__items {
    @include resetListStyles();
}

.c-accordion__item {
    margin: 0;
    padding: 0;
    border-bottom: 1px solid $c-grey-20;

    @include mq(sm) {
        margin-right: -$grid-gutter-width /2;
        margin-left: -$grid-gutter-width /2;
    }

    @include mq(lg) {
        margin-right: 0;
        margin-left: 0;
    }

    &:first-child {
        border-top: 1px solid $c-grey-20;
    }

    &:last-child {
        @include mq($until: sm) {
            border-bottom: none;
        }
    }
}

.c-accordion__item-header {
    position: relative;
    display: flex;
}

.c-accordion__button {
    @include textEmphasized();
    @include accordionArrow(25px, true);

    width: 100%;
    padding: 16px 60px 14px $grid-gutter-width /2;
    color: $c-text;
    font-weight: bold;
    line-height: 20px;
    text-align: left;
    background-color: $c-transparent;
    transition: background-color $global-transition-config, color $global-transition-config;
    fill: currentColor;

    @include mq(sm) {
        padding: 20px 60px 18px $grid-gutter-width /2;
    }

    @include mq(md) {
        padding: 30px 60px 26px $grid-gutter-width /2;
    }

    @include mq(lg) {
        padding-left: 30px;
    }

    &:hover {
        color: $c-white;
        text-decoration: none;
        background-color: $c-brand;
        outline: 0;
    }

    //&:focus {
    //    outline: none;
    //    box-shadow: inset 0 0 0 1px $c-brand;
    //}

    &:not(.collapsed) {
        .c-accordion__button-label {
            &::before,
            &::after {
                @include zindex(below);

                position: absolute;
                top: 0;
                display: none;
                width: 1px;
                height: 100%;
                background-color: $c-grey-20;
                transition: background $global-transition-config;
                content: '';
            }

            &::before {
                @include mq(sm) {
                    left: 0;
                    display: block;
                }
            }

            &::after {
                @include mq(sm) {
                    left: calc(100% - 1px);
                    display: block;
                }
            }
        }
    }
}

.c-accordion__content {
    position: relative;
    display: flex;
    padding-top: $spacing-l;

    @include mq(sm) {
        padding-right: $grid-gutter-width /2;
        padding-left: $grid-gutter-width /2;
    }

    @include mq(lg) {
        padding-top: $spacing-2xl;
        padding-right: 0;
        padding-left: 0;
    }

    @include mq(xl) {
        padding-top: $spacing-2xl;
    }

    &::before,
    &::after {
        position: absolute;
        top: 0;
        display: none;
        width: 1px;
        height: 100%;
        background-color: $c-grey-20;
        transition: background $global-transition-config;
        content: '';

        @include mq(sm) {
            display: block;
        }
    }

    &::before {
        left: 0;
    }

    &::after {
        left: calc(100% - 1px);
    }

    > div {
        width: 100%;
    }
}
