/* ------------------------------------ *\
    #MIXIN ALIASES
\* ------------------------------------ */

@mixin clearfix {
    &::after {
        display: block !important;
        clear: both !important;
        content: '' !important;
    }
}
