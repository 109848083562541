/* ------------------------------------ *\
    #CE39 Jump navigation
\* ------------------------------------ */
.c-anchor-navigation {
    @include make-row();

    position: relative;
    display: flex;
}

.c-anchor-navigation__headline {
    @include sr-only();
}

.c-anchor-navigation__inner {
    @include make-container();

    display: flex;
    align-items: center;

    @include mq(xl) {
        @include make-col-offset(1);
        @include make-col(9);
    }
}

.c-anchor-navigation__menu {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
