/* ------------------------------------ *\
    #LIST
\* ------------------------------------ */

$o-list-bullet-size: 10px;

/**
 * Begin of module
 */

@mixin o-list {
    @include resetListStyles(false);

    padding-left: 15px;
}

@mixin list-unordered {
    li {
        &::before {
            position: absolute;
            top: 10px;
            left: 0;
            display: block;
            width: $o-list-bullet-size;
            height: $o-list-bullet-size;
            border-radius: 100%;
            content: '';
        }

        > ul {
            margin-bottom: 0;
            padding-top: 10px;
            padding-left: 0;
        }

        li {
            &::before {
                background-color: $c-transparent;
                border: 2px solid $c-grey-20;
            }
        }
    }

    > li {
        position: relative;
        padding-left: 25px;

        &::before {
            background-color: $c-grey-30;
        }
    }
}

@mixin list-ordered {
    li {
        &::before {
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
            width: 50px;
            height: 100%;
            color: $c-grey-40;
            font-weight: bold;
            content: counter(main-counter) '.';
        }
    }

    > li {
        position: relative;
        padding-left: 30px;
        counter-increment: main-counter;

        ol {
            margin-bottom: 0;
            padding-top: 10px;
            padding-left: 0;
        }

        li {
            padding-left: 40px;
            counter-increment: sub-counter;

            &::before {
                color: $c-grey-20;
                content: counter(main-counter) '.' counter(sub-counter) ' ';
            }
        }
    }
}

.o-list {
    @include o-list;
}

.o-list--unordered {
    @include list-unordered;
}

.o-list--ordered {
    @include list-ordered;
}
