/* ------------------------------------ *\
    #PAGE-HEAD
\* ------------------------------------ */

// $c-page-head-height:                $global-page-head-height-default;
// $c-page-head-height-sticky:         $global-page-head-height-shrunk;
// $c-page-head-height-sticky-large:   $global-page-head-height-shrunk-large;

.c-page-head {
    @include clearfix;
    @include zindex(topsy);

    position: relative;
    background-color: $c-white;
}

.c-page-head__top {
    @include zindex(base);

    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    height: $shared-header-top-height;
    background: $c-light-grey;
    transition: $shared-sticky-header-transition-duration ease-in-out;
    transition-property: height, background;

    .header-is-shrunk & {
        height: $shared-sticky-header-height;
        background: $c-white;
        box-shadow: 0 5px 15px 0 rgba($c-grey-90, .2);
    }

    .is-cv & {
        z-index: 0;
        background: $c-white;

        @include mq($from: false, $until: xl) {
            z-index: 3;
            height: $shared-sticky-header-height;
            background: $c-white;
        }
    }

    .is-cv .header-is-shrunk & {
        box-shadow: none;
    }

    @include mq($from: false, $until: xl) {
        height: $shared-sticky-header-height;
        background: $c-white;
    }
}

.c-page-head__box {
    @include makeSection();
}

.c-page-head__bottom {
    @include clearfix;
    @include zindex(zero);

    position: relative;

    @include mq($from: false, $until: xl) {
        display: none;
    }
}

.c-page-head__content {
    @include make-row();

    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.c-page-head__brand {
    @include make-col-ready();
    @include make-col(12);

    position: static;
    display: flex;
    align-items: flex-start;
    padding-top: $spacing * 1.5;

    @include mq(xl) {
        @include make-col(9);

        flex-direction: column;
        padding-top: $spacing * 3;
    }

    .is-fff &,
    .is-cv & {
        @include make-col(12);

        flex-direction: row;
        justify-content: space-between;

        @include mq(lg) {
            padding-top: 0;
            padding-bottom: 0;
        }

        .c-logo {
            display: flex;
            align-self: center;
        }
    }
}

.is-cv {
    .c-page-head__brand {
        padding-top: $spacing * 1.5;
        padding-bottom: $spacing * 1.5;
    }
}

.c-page-head__site-nav {
    @include make-col-ready();
    @include make-col(12);

    @include mq(xl) {
        @include make-col(3);
    }
}

.c-page-head__meta {
    @include make-col-ready();
    @include make-col(12);

    @include mq(xl) {
        @include make-col(3);
    }
}
