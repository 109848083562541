/* ------------------------------------ *\
    #LINKLIST
\* ------------------------------------ */
.c-linklist {
    @include resetListStyles(false);

    position: relative;
    display: flex;

    &.is-horizontal {
        flex-direction: column;
        width: 100%;

        @include mq(sm) {
            flex-direction: row;
            margin-right: -$grid-gutter-width / 2;
            margin-left: -$grid-gutter-width / 2;
        }
    }

    &.is-contact {
        flex-direction: column;
        width: 100%;
        margin-top: 20px;
    }

    &.is-location {
        flex-direction: column;
        width: 100%;
        margin-top: 20px;
    }

    &.is-flyout-contact {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;

        @include mq(sm) {
            flex-direction: row;
            flex-wrap: wrap;
        }

        @include mq(md) {
            width: auto;
        }

        @include mq(lg) {
            flex-direction: column;
        }
    }
}

.c-linklist__item {
    position: relative;
    display: flex;
    margin: 0;
    padding: 0;

    @include mq($until: lg) {
        width: 100%;
    }

    &:not(:last-child) {
        @include mq($until: sm) {
            margin-bottom: 16px;
        }

        @include mq(sm) {
            margin-right: $grid-gutter-width;
        }
    }

    .is-location &,
    .is-contact & {
        &:not(:last-child) {
            margin-right: 0;
            margin-bottom: 10px;
        }
    }

    .is-flyout-contact & {
        flex-direction: column;
        width: 100%;

        @include mq(sm) {
            width: auto;
        }

        @include mq(lg) {
            margin-bottom: $spacing-s;
        }

        &:not(:last-child) {
            margin-bottom: $grid-gutter-width / 2;

            @include mq(sm) {
                margin-right: $grid-gutter-width / 2;
            }
        }
    }
}

.c-linklist__link {
    @include small-font();

    position: relative;
    display: block;
    padding: 2px $grid-gutter-width *.5;
    color: $c-white;
    background-color: $c-transparent;
    transition: background-color $global-transition-speed $global-transition-easing;

    @include mq($until: sm) {
        width: 100%;
    }

    &:visited {
        color: $c-white;
    }

    &:hover,
    &:focus {
        color: $c-white;
        text-decoration: none;
        background-color: $c-brand;
        outline: none;
    }

    .is-location & {
        width: 100%;
        color: $c-text;

        &.url,
        &.email {
            font-weight: bold;
        }
    }

    .is-location &,
    .is-contact &,
    .is-flyout-contact & {
        @include textEmphasized();

        padding: 11px 15px;
        background-color: $c-transparent;
        outline: 1px solid $c-grey-40;
        transition: background-color $global-transition-speed $global-transition-easing;

        &:hover,
        &:focus {
            color: $c-white;
            text-decoration: none;
            background-color: $c-brand;
            outline: 1px solid $c-brand;
            box-shadow: 0 5px 15px 0 rgba($c-grey-90, .2);
        }
    }

    .is-location &,
    .is-contact & {
        @include mq($until: lg) {
            width: 100%;
        }

        &.is-mobile-label {
            @include mq($until: lg) {
                color: $c-transparent;
                font-size: 0;
            }

            &:hover,
            &:focus {
                @include mq($until: lg) {
                    color: $c-transparent;
                    font-size: 0;
                }
            }

            .c-linklist__link-mobile {
                @include mq($until: lg) {
                    @include textEmphasized();

                    display: inline;
                }
            }
        }
    }

    .is-flyout-contact & {
        @include mq($until: sm) {
            width: auto;
        }
    }
}

.c-linklist__link-addon {
    font-weight: bold;

    .is-mobile-label & {
        @include mq($until: lg) {
            display: none;
        }
    }
}

.c-linklist__link-mobile {
    display: none;
    color: $c-white;
    font-weight: bold;
}
