/* ------------------------------------ *\
    #CE40 Keyfacts
\* ------------------------------------ */

$c-keyfacts-ul-bullet-size: 10px;

.c-keyfacts {
    @include mq(sm) {
        display: flex;
    }
}

.c-keyfacts__header {
    @include mq(sm) {
        @include make-col(6);

        padding-right: $grid-gutter-width;
    }
}

.c-keyfacts__body {
    padding: 10px 0 10px $grid-gutter-width * .5;
    border-left: 4px solid $c-red;

    @include mq(sm) {
        @include make-col(6);

        padding: 10px 0 10px $grid-gutter-width;
    }

    ul {
        padding-left: 0;
        list-style: none;
    }

    ol {
        padding-left: 25px;
    }

    ul > li {
        padding-left: $grid-gutter-width;

        &::before {
            display: inline-block;
            width: $c-keyfacts-ul-bullet-size;
            height: $c-keyfacts-ul-bullet-size;
            margin: 0 20px 0 -1 * $grid-gutter-width;
            background-color: $c-grey-30;
            border-radius: 50%;
            content: '';
        }
    }

    ol > li {
        padding-left: 5px;
    }
}

.c-keyfacts__headline {
    hyphens: auto;
}
