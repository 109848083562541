/* ------------------------------------ *\
    #ce15_contact
\* ------------------------------------ */
.c-contact {
    position: relative;
}

.c-contact__content {
    @include make-row();
}

.c-contact__text-box {
    @include make-col-ready();
    @include mq(lg) {
        @include make-col(10);
    }
    @include mq(xl) {
        @include make-col(5);
    }
}

.c-contact__text-box-inner {
    height: 100%;
    margin-right: -$grid-gutter-width / 2;
    margin-left: -$grid-gutter-width / 2;
    padding: 30px $grid-gutter-width / 2 60px;
    background: $c-grey-05;

    @include mq(sm) {
        margin-right: calc((#{$grid-gutter-width} / -2) - #{$grid-gutter-width});
        margin-left: calc((#{$grid-gutter-width} / -2) - #{$grid-gutter-width});
        padding-top: 40px;
        padding-right: calc((#{$grid-gutter-width} / 2) + #{$grid-gutter-width});
        padding-left: calc((#{$grid-gutter-width} / 2) + #{$grid-gutter-width});
    }
    @include mq(lg) {
        margin-right: 0;
        padding-top: 60px;
        padding-right: $grid-gutter-width;
    }
    @include mq(xl) {
        margin-right: -$grid-gutter-width / 2;
        margin-left: calc((100vw - 100%) / -2);
        padding-right: $grid-gutter-width;
        padding-left: calc(((100vw - 100%) / 2));
    }
}

.c-contact__headline {
    color: $c-text;
}

.c-contact__topline {
    @include textEmphasized();

    display: block;
    margin-bottom: 10px;
    color: $c-grey-40;
}

.c-contact__text {
    @include normal-font();

    margin-bottom: 0;
    color: $c-text;

    @include mq(xl) {
        padding-right: $grid-gutter-width;
    }

    + .c-contact__text {
        margin-top: 5px;
    }

    .c-contact__vcard & {
        color: $c-white;
    }
}

.c-contact__vcard-box {
    @include make-col-ready();
    @include mq(lg) {
        @include make-col-offset(2);
        @include make-col(10);
    }
    @include mq(xl) {
        @include make-col-offset(0);
        @include make-col(7);
    }
}

.c-contact__vcard {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;
    margin-right: -$grid-gutter-width / 2;
    margin-left: calc((100vw - 100%) / -2);
    padding: 40px $grid-gutter-width / 2 40px;
    color: $c-white;
    background: $c-grey-60;

    @include mq(sm) {
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin-right: calc((#{$grid-gutter-width} / -2) - #{$grid-gutter-width});
        margin-left: calc((#{$grid-gutter-width} / -2) - #{$grid-gutter-width});
        padding-top: 50px;
        padding-right: $grid-gutter-width;
        padding-bottom: 50px;
        padding-left: $grid-gutter-width;
    }
    @include mq(lg) {
        position: relative;
        width: calc(100% + (#{$grid-gutter-width} / 2) + #{$grid-gutter-width});
        margin-left: 0;
        padding-top: 55px;
        padding-bottom: 60px;
        padding-left: $grid-gutter-width;
    }
    @include mq(xl) {
        width: calc(100% + (#{$grid-gutter-width} * 2) + ((100vw - 100%) / 2));
        margin-right: calc((100vw - 100%) / -2);
        margin-left: -$grid-gutter-width / 2;
        padding-right: calc((100vw - 100%) / 2);
        padding-left: $grid-gutter-width;
    }
}

.c-contact__vcard-inner {
    display: flex;
    flex-direction: column;
    order: 2;
    width: 100%;
    padding-top: 20px;

    @include mq(sm) {
        order: 1;
        width: calc(100% / 12 * 6);
        padding-top: 0;
        padding-right: $grid-gutter-width / 2;
        padding-left: $grid-gutter-width / 2;
    }
    @include mq(md) {
        width: calc(100% / 12 * 7);
    }
    @include mq(lg) {
        flex: 0 0 auto;
        width: calc(((100% + #{$grid-gutter-width} + ( #{$grid-gutter-width} / 2)) / 10 * 6) - (#{$grid-gutter-width} + (#{$grid-gutter-width} / 2)));
        max-width: inherit;
        padding-right: 15px;
        padding-left: 0;
    }
    @include mq(xl) {
        width: calc((100% / 7 * 4) - (#{$grid-gutter-width}));
        max-width: inherit;
        padding-right: 0;
        padding-left: 0;
    }
}

.c-contact__name {
    @include typo-h5(false);

    margin-bottom: 10px;
}

.c-contact__image-box {
    position: relative;
    display: block;
    align-self: flex-end;
    order: 1;
    width: 200px;
    height: 200px;
    margin-top: -80px;

    @include mq(sm) {
        @include make-col-ready();
        @include make-col(6);

        align-self: flex-start;
        order: 2;
        height: 100%;
        margin-top: 5px;
    }

    @include mq(md) {
        @include make-col(5);
    }

    @include mq(lg) {
        flex: 0 0 auto;
        width: calc(((100% + #{$grid-gutter-width} + ( #{$grid-gutter-width} / 2)) / 10 * 4) - #{$grid-gutter-width});
        max-width: inherit;
        margin-right: 15px;
        padding-right: 0;
        padding-left: 0;
    }

    @include mq(xl) {
        width: calc((100% / 7 * 3) - (#{$grid-gutter-width}));
        max-width: inherit;
        margin-right: $grid-gutter-width + $grid-gutter-width * .5;
        padding-right: 0;
        padding-left: 0;
    }
}

.c-contact__img-wrap {
    img {
        width: 100%;
        box-shadow: 0 5px 15px 0 rgba($c-grey-90, .2);
    }
}
