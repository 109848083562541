/* -------------------------------------------------------------------------- *\
    #MOBILE_NAV
\* -------------------------------------------------------------------------- */

$c-mobile-nav-transition-speed: .3s !default;

$global-section-padding-x: $grid-gutter-width / 2;
$global-section-padding-x-md: $grid-gutter-width;

// Menu page overlays
$global-menu-overlay-bg-color: $c-black;
$global-menu-overlay-bg-opacity: .33;
$global-menu-overlay-bg: rgba($global-menu-overlay-bg-color, $global-menu-overlay-bg-opacity);

/* stylelint-disable  */
.c-mobile-nav {
    @include zindex(below);

    position: fixed;
    top: $shared-sticky-header-height;
    right: 0;
    bottom: 0;
    left: 0;
    height: calc(100vh - #{$shared-sticky-header-height}); /* Use vh as a fallback for browsers that do not support Custom Properties */
    height: calc((var(--vh, 1vh) * 100) - #{$shared-sticky-header-height});
    overflow-x: hidden;
    overflow-y: scroll;
    transition: $c-mobile-nav-transition-speed $global-transition-easing;
    transition-property: background, z-index, top;
    visibility: hidden;
    pointer-events: none;

    @include mq(xl) {
        display: none;
    }

    .is-cv & {
        overflow: hidden;
    }

    &.is-active {
        @include zindex(above);

        background: $global-menu-overlay-bg;
        visibility: visible;
        pointer-events: all;

        .c-mobile-nav__close {
            @include zindex(zero);
        }
    }
}
/* stylelint-enable */

.c-mobile-nav__content {
    @include zindex(top);

    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    color: $c-white;
    background: $c-grey-60;
    box-shadow: 0 2px 5px rgba($c-black, .15);
    transform: translate3d(0, -150%, 0);
    transition: $c-mobile-nav-transition-speed all $global-transition-easing;

    .c-mobile-nav.is-active & {
        transform: translate3d(0, 0, 0);
    }
}

.c-mobile-nav__nav {
    @include clearfix;
}

.c-mobile-nav__site-nav {
    @include clearfix;
    @include makeSection();

    padding-top: $grid-gutter-width /2;
    padding-bottom: $grid-gutter-width /2;
    background-color: $c-grey-50;

    @include mq(sm) {
        padding-top: $grid-gutter-width;
        padding-bottom: $grid-gutter-width;
    }
}

.c-mobile-nav__meta {
    background: $c-light-grey;

    @include mq($until: xl) {
        @include zindex(topsy);

        position: relative;

        .is-cv & {
            display: none;
        }
    }

    // WIP: better put these Styles as modifier to the meta-nav items itself
    .c-meta-nav {
        margin-right: 0;
    }

    .c-meta-nav__list {
        justify-content: space-between;

        @include mq(sm) {
            justify-content: flex-start;
        }
    }
}

.c-mobile-nav__close {
    @include zindex(below);

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;

    &:hover,
    &:focus {
        outline: none;
    }
}
