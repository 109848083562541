// Typography

$global-font-family-bold: 'HelveticaNeueBold', Arial, Verdana, sans-serif;
$global-font-family-regular: 'Helvetica Neue', Arial, Verdana, sans-serif;
$global-font-family-thin: 'HelveticaNeueThin', Arial, Verdana, sans-serif;

// Paragraphs

$font-size-large:                24px;
$line-height-large:              38px;
$line-height-large:              $line-height-large / $font-size-large;
$letter-spacing-large:           .05em;

$font-size-normal:               18px;
$line-height-normal:             29px;
$line-height-normal:             $line-height-normal / $font-size-normal;
$letter-spacing-normal:          .05em;

$font-size-small:                14px;
$line-height-small:              24px;
$line-height-small:              $line-height-small / $font-size-small;
$letter-spacing-small:           .05em;

$font-size-extra-small:          12px;
$line-height-extra-small:        18px;
$line-height-extra-small:        $line-height-extra-small / $font-size-extra-small;
$letter-spacing-extra-small:     .05em;

// emphasized

$font-size-emphasized:           15px;
$line-height-emphasized:         18px;
$line-height-emphasized:         $line-height-emphasized / $font-size-emphasized;
$letter-spacing-emphasized:      .15em;

$font-size-emphasized-small:     14px;
$line-height-emphasized-small:   17px;
$line-height-emphasized-small:   $line-height-emphasized-small / $font-size-emphasized-small;
$letter-spacing-emphasized-small:.15em;

// H1 styles

$font-size-h1:                   50px;
$line-height-h1:                 64px;
$line-height-h1:                 $line-height-h1 / $font-size-h1;
$letter-spacing-h1:              .05em;

$font-size-h1-small:             34px;
$line-height-h1-small:           41px;
$line-height-h1-small:           $line-height-h1-small / $font-size-h1-small;
$letter-spacing-h1-small:        .05em;

// H2 styles

$font-size-h2:                  34px;
$line-height-h2:                46px;
$line-height-h2:                $line-height-h2 / $font-size-h2;
$letter-spacing-h2:             .05em;

$font-size-h2-small:            30px;
$line-height-h2-small:          37px;
$line-height-h2-small:          $line-height-h2-small / $font-size-h2-small;
$letter-spacing-h2-small:       .05em;

// H3 styles

$font-size-h3:                  24px;
$line-height-h3:                34px;
$line-height-h3:                $line-height-h3 / $font-size-h3;
$letter-spacing-h3:             .05em;

// H4 styles

$font-size-h4:                  22px;
$line-height-h4:                30px;
$line-height-h4:                $line-height-h4 / $font-size-h4;
$letter-spacing-h4:             .05em;

// H5 styles

$font-size-h5:                  20px;
$line-height-h5:                30px;
$line-height-h5:                $line-height-h5 / $font-size-h5;
$letter-spacing-h5:             .05em;

// H6 styles

$font-size-h6:                  18px;
$line-height-h6:                28px;
$line-height-h6:                $line-height-h6 / $font-size-h6;
$letter-spacing-h6:             .05em;
