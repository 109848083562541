/* ------------------------------------ *\
    #pe03_product-intro
\* ------------------------------------ */
.c-product-intro {
    position: relative;
    margin-right: -$grid-gutter-width / 2;
    margin-left: -$grid-gutter-width / 2;
    padding-bottom: 20px;
    background-color: $c-grey-05;

    @include mq(sm) {
        margin-right: calc((#{$grid-gutter-width} / -2) - #{$grid-gutter-width});
        margin-left: calc((#{$grid-gutter-width} / -2) - #{$grid-gutter-width});
        padding-right: calc((#{$grid-gutter-width} / 2) + #{$grid-gutter-width});
        padding-left: calc((#{$grid-gutter-width} / 2) + #{$grid-gutter-width});
    }

    @include mq(md) {
        padding-bottom: 30px;
        background: none;
    }

    @include mq(lg) {
        margin-left: calc((100vw - 100%) / -2);
        padding-bottom: 60px;
        padding-left: calc(((100vw - 100%) / 2));
    }

    &::before {
        @include mq(md) {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: calc((100% / 12 * 9) - #{$grid-gutter-width});
            height: 100%;
            background: $c-grey-05;
            content: '';
        }

        @include mq(xl) {
            padding-bottom: 60px;
        }

        @include mq(xxl) {
            width: calc((100% / 12 * 9.5) - #{$grid-gutter-width});
        }
    }

    &::after {
        position: absolute;
        top: 0;
        left: -$grid-gutter-width / 2;
        display: block;
        width: calc(100% + #{$grid-gutter-width});
        height: 2px;
        background-color: $c-grey-20;
        content: '';

        @include mq(sm) {
            left: -$grid-gutter-width - $grid-gutter-width *.5;
            width: calc(100% + (#{$grid-gutter-width} * 3));
        }

        @include mq(md) {
            display: none;
        }
    }

    p {
        margin-bottom: 0;
    }
}

.c-product-intro__header {
    margin-right: -$grid-gutter-width * 1.5;
    margin-left: -$grid-gutter-width * 1.5;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: $grid-gutter-width * 1.5;
    background-color: $c-white;

    @include mq(md) {
        padding-bottom: 0;
    }
}

.c-product-intro__inner {
    padding-top: 35px;

    @include mq(sm) {
        @include make-row();
    }

    @include mq(md) {
        padding-top: 50px;
    }
}

.c-product-intro__image-box {
    @include make-col-ready();

    position: relative;

    @include mq(sm) {
        @include make-col-offset(2);
        @include make-col(8);
    }

    @include mq(md) {
        @include make-col-offset(0);
        @include make-col(5);

        margin-top: -60px;
    }

    @include mq(lg) {
        @include make-col-offset(1);
        @include make-col(4);
    }

    @include mq(xl) {
        @include make-col-offset(1);
        @include make-col(4);
    }
}

.c-product-intro__image {
    display: flex;
    margin-right: auto;
    margin-left: auto;
    padding: $grid-gutter-width;
    aspect-ratio: 1 / 1;
    background-color: $c-white;

    &::before {
        position: absolute;
        top: 80px;
        left: 0;
        display: block;
        width: 100%;
        height: calc(100% - 60px);
        background: $c-white;
        content: '';

        @include mq(sm) {
            left: -50%;
            width: 200%;
        }

        @include mq(md) {
            display: none;
        }
    }
}

.c-product-intro__img-wrap {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;

    img {
        width: 100%;
        object-fit: contain;
    }
}

.c-product-intro__text-box {
    @include make-col-ready();

    position: relative;

    @include mq(md) {
        @include make-col(7);
    }

    @include mq(lg) {
        @include make-col(6);
    }

    @include mq(xl) {
        @include make-col-offset(1);
        @include make-col(5);
    }
}

.c-product-intro__topline {
    @include textEmphasizedSmall();

    display: block;
    margin-bottom: $spacing *2;
    color: $c-grey-40;

    em {
        color: $c-grey-70;
        font-style: normal;
    }
}

.c-product-intro__text {
    @include normal-font();

    color: $c-grey-50;

    @include mq(lg) {
        @include large-font();
    }
}

.c-product-intro__button {
    @include textEmphasized();

    position: relative;
    display: inline-block;
    width: auto;
    margin-top: 20px;
    margin-bottom: 60px;
    padding: 13px 30px 13px 46px;
    color: $c-white;
    font-weight: bold;
    text-align: left;
    background-color: $c-brand;
    transition: $global-transition-config;
    transition-property: background-color, box-shadow;

    @include mq(md) {
        margin-bottom: 30px;
    }

    @include mq(lg) {
        margin-bottom: 0;
    }

    @include mq(xl) {
        margin-bottom: 60px;
    }

    &::before,
    &::after {
        position: absolute;
        display: block;
        transition: $global-transition;
        content: '';
    }

    &::before {
        top: calc(50% - 2px);
        left: 18px;
        width: 9px;
        height: 9px;
        border-bottom: 2px solid $c-white;
        border-left: 2px solid $c-white;
        transform: rotate(225deg) scaleY(-1);
        transition-property: border-color;
    }

    &::after {
        top: calc(50% - 7px);
        left: 21px;
        width: 2px;
        height: 15px;
        background-color: $c-white;
        transition-property: background;
    }

    &:hover,
    &:active,
    &:focus {
        color: $c-white;
        text-decoration: none;
        background-color: $c-grey-80;
        outline: 0;
        box-shadow: 0 5px 15px 0 rgba($c-grey-90, .2);
    }
}
