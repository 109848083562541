// Spacing

//$global-module-spacing: 40px;
$spacing: 10px;
//$global-module-spacing-xs: 20px;
//$global-module-spacing-s: 30px;
//$global-module-spacing-l: 60px;

$spacing-2xs: 5px;
$spacing-xs: 10px;
$spacing-s: 15px;
$spacing-m: 20px;
$spacing-l: 25px;
$spacing-xl: 30px;
$spacing-2xl: 40px;
$spacing-3xl: 60px;
$spacing-4xl: 80px;
$spacing-5xl: 100px;
$spacing-6xl: 120px;
