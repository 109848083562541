html.touchevents {
    -webkit-overflow-scrolling: touch;
}

html {
    // overflow-y: scroll;
}

/* stylelint-disable  */
body {
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    padding-top: $shared-sticky-header-height !important;
    overflow-x: hidden;

    @include mq(xl) {
        padding-top: $shared-header-top-height !important;
    }

    &::before {
        display: none;

        .debug-bp & {
            display: inline;
        }
    }

    &.mobile-nav-is-open {
        position: fixed;
        top: $shared-sticky-header-height;
        width: 100%;
        height: 100%;
        padding-top: 0 !important;
        overflow-y: hidden;
    }

    &.is-overlay-open {
        position: fixed;
        top: $shared-sticky-header-height;
        width: 100%;
        height: 100%;
        padding-top: 0 !important;
        overflow-y: hidden;

        @include mq(xl) {
            top: 50px;
        }
    }

    &.without-padding {
        padding-top: 0 !important;

        @include mq($from: false, $until: xl) {
            padding-top: $shared-sticky-header-height !important;
        }
    }

    * :focus {
        outline-color: $c-brand;
    }

    ::selection {
        color: $c-white;
        background: $c-brand;
    }
}
/* stylelint-enable */
