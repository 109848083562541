/* ------------------------------------ *\
    #Collection
\* ------------------------------------ */
.c-collection {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;

    @include mq(md) {
        width: calc(100% - (#{$grid-gutter-width} * 2));
        margin-right: $grid-gutter-width;
        margin-left: $grid-gutter-width;
    }

    @include mq(lg) {
        width: calc(((100% + #{$grid-gutter-width}) / 10 * 8 ) - #{$grid-gutter-width});
        margin-right: calc((100% + #{$grid-gutter-width}) / 10);
        margin-left: calc((100% + #{$grid-gutter-width}) / 10);
    }

    .c-product-teaser-list {
        .c-product-teaser-list__item {
            display: flex;
        }
    }

    .c-product-teaser-list__item {
        display: none;
        margin-bottom: $spacing-xl;
    }
}

.c-collection__headline {
    @include typo-h1(false);

    margin-bottom: $spacing-l;

    @include mq(md) {
        margin-bottom: $spacing-xl;
    }

    @include mq(lg) {
        margin-bottom: $spacing-2xl;
    }
}

.c-collection__text {
    @include normal-font();

    margin-bottom: $spacing-xl;
    color: $c-grey-50;

    @include mq(md) {
        margin-bottom: $spacing-2xl;
    }

    @include mq(lg) {
        @include large-font();

        margin-bottom: $spacing-3xl;
    }
}

.c-collection__content {
    display: none;
    flex-direction: column;
    width: 100%;
    visibility: hidden;

    &.is-visible {
        display: flex;
        visibility: visible;
    }
}

.c-collection__links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-top: 20px;
    padding-bottom: 10px;

    @include mq(sm) {
        padding-bottom: 20px;
    }

    @include mq(md) {
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
    }

    &:first-of-type {
        margin-bottom: $spacing-xl;
        border-bottom: 1px solid $c-grey-20;
    }

    &:last-of-type {
        border-top: 1px solid $c-grey-20;
    }

    &.is-align-left {
        justify-content: flex-start;
        border-top: none;
        border-bottom: none;

        .o-link {
            margin-left: 0;
        }
    }

    .o-link {
        min-width: 51%;
        margin-bottom: 10px;
        margin-left: 0;

        @include mq(sm) {
            min-width: unset;
            margin-right: 10px;
        }

        @include mq(md) {
            margin-right: 0;
            margin-bottom: 0;
            margin-left: 20px;
        }
    }
}
