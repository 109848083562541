/* ------------------------------------ *\
    # TYPOGRAPHY
\* ------------------------------------ */

/**
 * Basis typographic settings. This includes the definition of @font-face rules.
 */

/* HelveticaNeueThin: 'HelveticaNeueW02-Thin' */
@include font-face(
    $family: 'Helvetica Neue',
    $src: '../fonts/775a65da-14aa-4634-be95-6724c05fd522',
    $woff2: true,
    $weight: 100
);

/* HelveticaNeueRegular: 'HelveticaNeueW01-55Roma' */
@include font-face(
    $family: 'Helvetica Neue',
    $src: '../fonts/d5af76d8-a90b-4527-b3a3-182207cc3250',
    $woff2: true
);

/* HelveticaNeueBold: 'HelveticaNeueW02-75Bold' */
@include font-face(
    $family: 'Helvetica Neue',
    $src: '../fonts/4a9c62ab-b359-4081-8383-a0d1cdebd111',
    $woff2: true,
    $weight: bold
);

@mixin normal-font {
    font-size: $font-size-normal;
    line-height: $line-height-normal;
    letter-spacing: $letter-spacing-normal;
}

@mixin large-font {
    font-size: $font-size-large;
    line-height: $line-height-large;
    letter-spacing: $letter-spacing-large;
}

@mixin small-font {
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-small;
}

@mixin extra-small-font {
    font-size: $font-size-extra-small;
    line-height: $line-height-extra-small;
    letter-spacing: $letter-spacing-extra-small;
}

@mixin typo-h1(
    $margin: true,
    $responsive: true
) {
    @if $margin {
        margin-bottom: 20px;
    } @else {
        margin-bottom: 0;
    }

    font-weight: bold;
    font-size: $font-size-h1-small;
    font-family: $global-font-family-regular;
    line-height: $line-height-h1-small;
    letter-spacing: $letter-spacing-h1-small;
    word-break: break-word;

    @if $responsive {
        @include mq(lg) {
            font-size: $font-size-h1;
            line-height: $line-height-h1;
            letter-spacing: $letter-spacing-h1;
        }
    }
}

@mixin typo-h2(
    $margin: true,
    $responsive: true
) {
    @if $margin {
        margin-bottom: 1.25rem;

        @include mq(xl) {
            margin-bottom: 1.875rem;
        }
    } @else {
        margin-bottom: 0;
    }

    font-weight: bold;
    font-size: $font-size-h2-small;
    font-family: $global-font-family-regular;
    line-height: $line-height-h2-small;
    letter-spacing: $letter-spacing-h2-small;
    word-break: break-word;

    @if $responsive {
        @include mq(lg) {
            font-size: $font-size-h2;
            line-height: $line-height-h2;
            letter-spacing: $letter-spacing-h2;
        }
    }
}

@mixin typo-h3(
    $margin: true,
    $responsive: true
) {
    @if $margin {
        margin-bottom: 1.25rem;

        @include mq(xl) {
            margin-bottom: 1.5rem;
        }
    } @else {
        margin-bottom: 0;
    }

    font-weight: bold;
    font-size: $font-size-h3;
    font-family: $global-font-family-regular;
    line-height: $line-height-h3;
    letter-spacing: $letter-spacing-h3;
    word-break: break-word;
}

@mixin typo-h4(
    $margin: true,
    $responsive: true
) {
    @if $margin {
        margin-bottom: 1rem;

        @include mq(xl) {
            margin-bottom: 1.25rem;
        }
    } @else {
        margin-bottom: 0;
    }

    font-weight: bold;
    font-size: $font-size-h4;
    font-family: $global-font-family-regular;
    line-height: $line-height-h4;
    letter-spacing: $letter-spacing-h4;
    word-break: break-word;
}

@mixin typo-h5(
    $margin: true
) {
    @if $margin {
        margin-bottom: 1rem;

        @include mq(xl) {
            margin-bottom: 1.25rem;
        }
    } @else {
        margin-bottom: 0;
    }

    font-weight: bold;
    font-size: $font-size-h5;
    font-family: $global-font-family-regular;
    line-height: $line-height-h5;
    letter-spacing: $letter-spacing-h5;
}

@mixin typo-h6(
    $margin: true
) {
    @if $margin {
        margin-bottom: 1rem;

        @include mq(xl) {
            margin-bottom: 1.25rem;
        }
    } @else {
        margin-bottom: 0;
    }

    font-weight: bold;
    font-size: $font-size-h6;
    font-family: $global-font-family-regular;
    line-height: $line-height-h6;
    letter-spacing: $letter-spacing-h6;
}

@mixin textEmphasized() {
    font-size: $font-size-emphasized;
    line-height: $line-height-emphasized;
    letter-spacing: $letter-spacing-emphasized;
    text-transform: uppercase;
}

@mixin textEmphasizedSmall(
    $largeLineHeight: false
) {
    font-size: $font-size-emphasized-small;
    letter-spacing: $letter-spacing-emphasized-small;
    text-transform: uppercase;

    @if $largeLineHeight {
        line-height: $line-height-small;
    } @else {
        line-height: $line-height-emphasized-small;
    }
}

@mixin typo-stage() {
    font-size: 30px;
    font-family: $global-font-family-regular;
    line-height: 45px;
    letter-spacing: $letter-spacing-h6;
    word-break: break-word;

    @include mq(lg) {
        font-size: 40px;
        line-height: 60px;
    }
    @include mq(xl) {
        font-size: 60px;
        line-height: 80px;
    }
}
