.u-lazyload {
    height: 0;
    opacity: 0;

    &.has-loaded {
        @include a-fadein();

        height: auto;
    }
}

@supports not (animation) {
    .u-lazyload.has-loaded {
        opacity: 1;
    }
}

/* stylelint-disable no-descending-specificity  */
.u-lazyload-item--slide {
    position: relative;
    display: block;
    overflow: hidden;

    .u-lazyload {
        opacity: 1;
        transition: none;
    }

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        background: $c-white;
        transition: transform 1s $global-transition-easing-slidein;
        transition-delay: inherit;
        content: '';
    }

    &.has-loaded::after {
        transform: translateX(100%);
    }
}
/* stylelint-enable */
