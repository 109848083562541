/* ------------------------------------ *\
    #Article Wall
\* ------------------------------------ */

.c-article-wall {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: -$spacing-m;

    @include mq(md) {
        margin-top: -$spacing-xl;
    }

    @include mq(lg) {
        margin-top: -$spacing-2xl;
    }

    @include mq(xl) {
        margin-top: -$spacing-3xl;
    }

    .c-content-teaser-list__item {
        transform: translateY(200px);
        opacity: 0;
        transition: transform .5s $global-transition-easing-slidein, opacity .5s linear;
        transition-delay: calc(var(--cycle) * .2s);

        &.is-visible {
            transform: translateY(0);
            opacity: 1;
        }
    }
}

.c-article-wall__header {
    @include make-row();

    width: calc(100% + #{$grid-gutter-width});
}

.c-article-wall__header {
    @include make-row();

    width: calc(100% + #{$grid-gutter-width});
}

.c-article-wall__articles {
    .c-content-teaser-list {
        display: none;
    }

    .is-hidden {
        display: none;
    }
}

.c-article-wall__results {
    @include moduleSpace();

    position: relative;
    width: 100%;

    @include mq(md) {
        margin-bottom: 0;
    }

    @include mq(lg) {
        margin-bottom: 0;
    }

    @include mq(xl) {
        margin-bottom: 0;
    }
}

.c-article-wall__highlight {
    @include moduleSpace();

    transform: translateY(200px);
    opacity: 0;
    transition: transform .5s $global-transition-easing-slidein, opacity .5s linear;

    @include mq(md) {
        margin-bottom: 0;
    }

    @include mq(lg) {
        margin-bottom: 0;
    }

    @include mq(xl) {
        margin-bottom: 0;
    }

    &.is-hidden {
        display: none;
        transform: translateY(200px);
    }

    &.is-visible {
        transform: translateY(0);
        opacity: 1;
    }
}

.c-article-wall__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.c-article-wall__button {
    @include textEmphasized();

    width: auto;
    padding: 13px 30px;
    color: $c-white;
    font-weight: bold;
    background-color: $c-grey-80;
    transition: $global-transition-config;
    transition-property: background-color, box-shadow;

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        background-color: $c-brand;
        outline: 0;
        box-shadow: 0 5px 15px 0 rgba($c-grey-90, .2);
    }

    &.is-hidden {
        display: none;
    }
}
