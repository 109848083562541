/* ------------------------------------ *\
    #ce29-location-teaser-list
\* ------------------------------------ */
.c-location-teaser-list {
    @include clearfix();
    @include make-row();

    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
}

.c-location-teaser-list__header {
    @include make-col-ready();

    width: 100%;
    color: $c-grey-50;
}

/* stylelint-disable */
.c-location-teaser-list__item {
    @include make-col-ready();
    @include make-col(12);

    position: relative;
    height: auto;
    margin-bottom: $spacing-2xl;

    @include mq(md) {
        width: 100%;
        margin-bottom: $spacing-3xl;
    }

    @include mq(xl) {
        @include make-col(4);

        margin-bottom: $spacing-3xl;
        overflow: hidden;
    }

    @include mq(xxl) {
        margin-bottom: $spacing-5xl;
    }

    // counting through the items and remove margin on the last items of the row
    &:first-of-type {
        &:nth-last-child(1),
        &:nth-last-child(2),
        &:nth-last-child(3) {
            @include mq(xl) {
                margin-bottom: 0;
            }

            ~ .c-location-teaser-list__item {
                @include mq(xl) {
                    margin-bottom: 0;
                }
            }
        }

        &:nth-last-child(4),
        &:nth-last-child(7),
        &:nth-last-child(10),
        &:nth-last-child(13) {
            ~ .c-location-teaser-list__item {
                &:last-of-type {
                    @include mq(xl) {
                        margin-bottom: 0;
                    }
                }
            }
        }

        &:nth-last-child(5),
        &:nth-last-child(8),
        &:nth-last-child(11),
        &:nth-last-child(14) {
            ~ .c-location-teaser-list__item {
                &:nth-last-of-type(-n+2) {
                    @include mq(xl) {
                        margin-bottom: 0;
                    }
                }
            }
        }

        &:nth-last-child(6),
        &:nth-last-child(9),
        &:nth-last-child(12),
        &:nth-last-child(15) {
            ~ .c-location-teaser-list__item {
                &:nth-last-of-type(-n+3) {
                    @include mq(xl) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
/* stylelint-enable */
