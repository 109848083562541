// Variables and mixins
@import 'mixins';

// Bootstrap
@import 'bootstrap-units';
@import 'bootstrap';

// Basic setup and variables
@import '0-settings/.importer';

// Functions and mixins
@import '1-tools/.importer';

// Base setup
@import '3-elements/.importer';

// Objects (e.g. o-button)
@import '4-objects/.importer';

// Helpers (e.g. u-size)
@import '6-utilities/.importer';

@mixin hide-elements {
    display: none !important;
    visibility: hidden !important;
}

@mixin show-elements {
    display: block !important;
    visibility: visible !important;
}

// ==========================================================================
// Print styles
// ==========================================================================

@media print {
    // Avoid page breaks after headings and title elements
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $c-text;
        page-break-after: avoid;
    }

    // Displayed components
    .collapse:not(.show) {
        @include show-elements;
    }

    .copyright {
        position: absolute;
        right: 0;
        bottom: 0;
        display: none;
    }

    .logo-print {
        margin: 24px 0 0;
        page-break-inside: auto;
    }
}
