/* Background-Images
-------------------------------------------------------------- */

@mixin cover() {
    background-repeat: no-repeat;
    background-size: cover;
}

/* break long words
-------------------------------------------------------------- */

@mixin hyphenate() {
    hyphens: auto;
}

@mixin hyphenate-wordbreak() {
    word-break: break-word;
    hyphens: auto;
}

/* position
-------------------------------------------------------------- */

@mixin hidden() {
    position: absolute;
    top: -10000px;
    left: -10000px;
    display: inline;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

@mixin visible() {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: auto;
    height: auto;
    overflow: visible;
}

@mixin sr-only() {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    border: 0;
    clip: rect(0, 0, 0, 0);
}

/* transform
-------------------------------------------------------------- */

@mixin transform($rotate: 90deg, $scale: 1, $skew: 1deg, $translate: 10px) {
    transform: rotate($rotate) scale($scale) skew($skew) translate($translate);
}

/* form
-------------------------------------------------------------- */

@mixin placeholder() {
    ::-webkit-input-placeholder {
        color: $arguments;
    }

    ::-moz-placeholder {
        color: $arguments;
    }

    :-ms-input-placeholder {
        color: $arguments;
    }
}

/* transition
-------------------------------------------------------------- */

@mixin animation($name, $duration: 300ms, $delay: 0, $ease: ease) {
    animation: $name $duration $delay $ease;
}

@mixin animated(
    $animationDurationTime: 1s,
    $animationDelayTime: 0s,
    $animationFillMode: both
) {
    animation-duration: $animationDurationTime;
    animation-delay: $animationDelayTime;
    animation-fill-mode: $animationFillMode;
}

@mixin transition($duration: .5s, $ease: ease, $name: all) {
    transition: $duration $ease $name;
}

@mixin transition-custom($duration: .5s, $ease: ease, $name: all) {
    transition: $duration $ease $name;
}

/* animation duration
-------------------------------------------------------------- */

@mixin animateFaster() {
    @include animated($animationDurationTime: .3s);
}

@mixin animateSlower() {
    @include animated($animationDurationTime: 1s);
}

@mixin animateSlowest() {
    @include animated($animationDurationTime: 1.5s);
}

/* z-index mapping
-------------------------------------------------------------- */

$z-index: (
    // popping UI elements
    notifications: 5000,
    grid-helper: 2000,
    overlay: 1010,
    overlay-background: 1000,
    // default levels
    topsy: 4,
    top: 3,
    above: 2,
    base: 1,
    zero: 0,
    below: -1,
    abyss: -999
);

@function zindex($key, $modifier) {
    @return map-get($z-index, $key) + $modifier;
}

// use as "@include zindex(base);"
@mixin zindex($key, $modifier: 0) {
    z-index: zindex($key, $modifier);
}

// page container
@mixin pageContainer() {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    max-width: $max-page-width;
    margin: 0 auto;
}

@mixin textBold() {
    font-weight: bold;
}

@mixin textThin() {
    font-family: $global-font-family-thin;
}

@mixin clearfix {
    &::after {
        display: block !important;
        clear: both !important;
        content: '' !important;
    }
}

@mixin resetListStyles($resetLi:false) {
    margin: 0;
    padding: 0;
    list-style-type: none;

    @if $resetLi {
        > li {
            margin: 0;
            padding: 0;
        }
    }
}

@mixin verticalSpacing() {
    margin-top: $spacing-m;
    margin-bottom: $spacing-m;

    @include mq(md) {
        margin-top: $spacing-2xl;
        margin-bottom: $spacing-2xl;
    }
}

@mixin makeSection() {
    position: relative;
    width: 100%;
    max-width: $max-page-width;
    margin: 0 auto;
    padding-right: $grid-gutter-width *.5;
    padding-left: $grid-gutter-width *.5;

    @include mq(sm) {
        padding-right: $grid-gutter-width + $grid-gutter-width *.5;
        padding-left: $grid-gutter-width + $grid-gutter-width *.5;
    }
}

@mixin moduleSpace() {
    margin-top: $spacing-2xl;
    margin-bottom: $spacing-2xl;

    @include mq(md) {
        margin-top: $spacing-3xl;
        margin-bottom: $spacing-3xl;
    }

    @include mq(lg) {
        margin-top: $spacing-4xl;
        margin-bottom: $spacing-4xl;
    }

    @include mq(xl) {
        margin-top: $spacing-5xl;
        margin-bottom: $spacing-5xl;
    }
}

@mixin accordionArrow($posRight: 20px, $large: false) {
    &:hover {
        color: $c-white;
        text-decoration: none;
        background-color: $c-brand;
        outline: 0;
    }

    &::after {
        position: absolute;
        right: $posRight;
        display: block;

        @if $large {
            top: calc(50% - 9px);
            width: 15px;
            height: 15px;
        } @else {
            top: calc(50% - 5px);
            width: 9px;
            height: 9px;
        }

        transform: rotate(225deg) scaleY(-1);
        transition: transform $global-transition-speed $global-transition-easing, top $global-transition-speed $global-transition-easing;
        transition-property: background;
        content: '';
    }

    &:not(.collapsed) {
        &::after {
            @if $large {
                top: calc(50% - 5px);
                border-bottom: 3px solid $c-brand;
                border-left: 3px solid $c-brand;
            } @else {
                top: calc(50% - 3px);
                border-bottom: 2px solid $c-brand;
                border-left: 2px solid $c-brand;
            }

            transform: rotate(135deg);
        }

        &:hover {
            &::after {
                border-color: $c-white;
            }
        }
    }

    &.collapsed {
        &::after {
            @if $large {
                border-bottom: 3px solid $c-brand;
                border-left: 3px solid $c-brand;
            } @else {
                border-bottom: 2px solid $c-brand;
                border-left: 2px solid $c-brand;
            }
        }

        &:hover {
            &::after {
                border-color: $c-white;
            }
        }
    }
}
