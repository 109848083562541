/* ------------------------------------ *\
    search-results
\* ------------------------------------ */
.c-search-results {
    @include clearfix();
    @include make-row();

    margin-bottom: $spacing-2xl;
    visibility: visible;
    opacity: 1;
    transition: opacity $global-transition-config;

    @include mq($until: sm) {
        margin-right: 0;
        margin-left: 0;
    }

    @include mq(md) {
        margin-bottom: $spacing-3xl;
    }

    @include mq(lg) {
        margin-bottom: $spacing-4xl;
    }

    @include mq(xl) {
        margin-bottom: $spacing-5xl;
    }

    &.is-hidden {
        visibility: hidden;
        opacity: 0;
    }

    ~ .c-pager {
        margin-top: -$spacing-2xl;

        @include mq(md) {
            margin-top: -$spacing-3xl;
        }

        @include mq(lg) {
            margin-top: -$spacing-4xl;
        }

        @include mq(xl) {
            margin-top: -$spacing-5xl;
        }
    }
}

.c-search-results__header {
    @include make-col-ready();

    margin-bottom: $spacing-xs;

    @include mq($until: sm) {
        padding-right: 0;
        padding-left: 0;
    }

    @include mq(md) {
        margin-bottom: $spacing-l;
    }

    @include mq(xl) {
        @include make-col-offset(1);
        @include make-col(9);

        margin-bottom: $spacing-xl;
    }

    @include mq(xxl) {
        @include make-col(7);
    }
}

.c-search-results__headline {
    @include typo-h3(false);

    margin-bottom: 5px;
    color: $c-text;

    @include mq(lg) {
        @include typo-h2(false, false);

        margin-bottom: 10px;
    }
}

.c-search-results__text {
    margin-top: 1.25rem;

    @include mq(xl) {
        margin-top: 1.875rem;
    }
}

.c-search-results__list {
    @include resetListStyles(false);
    @include make-col-ready();

    @include mq($until: sm) {
        padding-right: 0;
        padding-left: 0;
    }
    @include mq(xl) {
        @include make-col-offset(1);
        @include make-col(10);
    }
}

.c-search-results__item {
    @include resetListStyles(false);

    margin-top: -1px;
}
