/* ------------------------------------ *\
    #CE41 Contact form
\* ------------------------------------ */

/*
 * Contact form styling block.
 *
 * 1. Set up grid and padding for the contact form.
 * 2. Center the form using margin adjustments.
 */
.c-contact-form {
    @include make-row(); /* [1] */

    margin-right: calc((100vw - 100%) / -2); /* [2] */
    margin-left: calc((100vw - 100%) / -2); /* [2] */
    padding-top: 100px; /* [1] */
    padding-right: calc((100vw - 100%) / 2); /* [2] */
    padding-bottom: 100px; /* [1] */
    padding-left: calc((100vw - 100%) / 2); /* [2] */
    background-color: $c-grey-05; /* [1] */
}

/*
 * Contact form content styling block.
 *
 * 1. Prepare layout for contact form content.
 * 2. Adjust for larger screen sizes.
 */
.c-contact-form__content {
    @include make-col-ready(); /* [1] */

    padding: 0;

    @include mq(lg) {
        @include make-col-offset(1); /* [2] */
        @include make-col(9); /* [2] */
    }

    @include mq(xxl) {
        @include make-col-offset(1); /* [3] */
        @include make-col(6); /* [3] */
    }
}
