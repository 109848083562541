/* ------------------------------------ *\
   #ce29_location-teaser
\* ------------------------------------ */
.c-location-teaser {
    @include clearfix();

    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    color: $c-text;
    border-top: 1px solid $c-grey-70;

    @include mq(xl) {
        padding-bottom: $spacing-3xl;
        border-bottom: 1px solid $c-grey-20;
    }
}
