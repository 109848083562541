// Standardise some UI treatments.
$global-transition-speed: 200ms;
$global-transition-duration: $global-transition-speed;
$global-transition-easing: ease-in-out;
$global-transition-easing-slidein: cubic-bezier(.27, .11, .32, .94);
$global-transition-easing-smooth: cubic-bezier(.34, .01, .7, .99);
$global-transition-config: $global-transition-speed $global-transition-easing;
$global-transition-properties: all;
$global-transition: $global-transition-properties $global-transition-speed $global-transition-easing !default;

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes slideIn {
    0% {
        transform: translate(-25px, 0);
        opacity: 0;
    }

    100% {
        transform: translate(0, 0);
        opacity: 1;
    }
}

@mixin a-fadein($duration: $global-transition-duration, $delay: 0s) {
    animation: fadeIn;
    animation-duration: $duration;
    animation-timing-function: ease-in-out;
    animation-delay: $delay;
    animation-fill-mode: forwards;
}

@mixin a-fadeout($duration: $global-transition-duration, $delay: 0s) {
    animation: fadeIn;
    animation-duration: $duration;
    animation-timing-function: ease-in-out;
    animation-delay: $delay;
    animation-direction: reverse;
    animation-fill-mode: forwards;
}

@mixin a-fadeinout($duration: $global-transition-duration, $delay: 0s) {
    animation: fadeIn;
    animation-duration: $duration;
    animation-timing-function: ease-in-out;
    animation-delay: $delay;
    animation-iteration-count: 2;
    animation-direction: alternate;
    animation-fill-mode: forwards;
}

@mixin a-slidein($duration: $global-transition-duration, $delay: 0s) {
    animation: slideIn;
    animation-duration: $duration;
    animation-timing-function: ease-in-out;
    animation-delay: $delay;
    animation-fill-mode: forwards;
}
