/* ------------------------------------ *\
    #MEDIA OBJECT
\* ------------------------------------ */

$o-media-spacing-x: $grid-gutter-width !default;
$o-media-spacing-y: $grid-gutter-width !default;
$o-media-last-element-no-bottom-margin: true;

.o-media__body {
    @if $o-media-last-element-no-bottom-margin {
        > :last-child {
            margin-bottom: 0;
        }
    }
}

.o-media__media {
    width: 100%;

    > img {
        display: block;
        width: 100%;
        height: auto;
    }
}

.o-media__img-wrap {
    @include make-col-ready();

    display: block;

    > img {
        width: 100%;
    }

    .is-border {
        outline: 1px solid $c-grey-20;
    }
}

.o-media__licence {
    position: absolute;
    margin-top: -2.15em;
    margin-left: 1em;
    letter-spacing: 0;
}

.o-media__licence--dark {
    color: $c-text;
}

.o-media__licence--light {
    color: $c-white;
}

.o-media__caption {
    @include make-col-ready();
    @include extra-small-font;

    margin-top: 10px;
    color: $c-grey-50;
    letter-spacing: 0;
    caption-side: bottom;
}

.o-media--left,
.o-media--right {
    > .o-media__media {
        @include mq(lg) {
            display: flex;
        }
    }

    .o-media__img-wrap {
        @include mq(lg) {
            @include make-col(6);
        }
    }

    .o-media__caption {
        @include mq(lg) {
            @include make-col(4);

            margin-top: 0;
        }

        @include mq(xl) {
            @include make-col(3);
        }
    }
}

.o-media--left {
    .o-media__img-wrap {
        @include mq(lg) {
            @include make-col-offset(1);
        }
    }
}

.o-media--right {
    > .o-media__media {
        &.is-without-caption {
            .o-media__img-wrap {
                @include mq(lg) {
                    @include make-col-offset(4);
                }
            }
        }
    }

    .o-media__img-wrap {
        @include mq(lg) {
            order: 1;
        }
    }

    .o-media__caption {
        @include mq(lg) {
            @include make-col-offset(1);

            text-align: right;
        }

        @include mq(xl) {
            @include make-col-offset(2);
        }
    }
}

//small portrait pictures
.o-media--right-small,
.o-media--left-small {
    > .o-media__media {
        display: flex;
        flex-direction: column;

        @include mq(md) {
            flex-direction: row;
        }
    }

    .o-media__img-wrap {
        @include make-col(12);

        display: block;
        flex: none;

        @include mq(sm) {
            @include make-col(8);
        }

        @include mq(md) {
            @include make-col(7);

            display: flex;
            flex: 0 0 100%;
        }

        @include mq(lg) {
            @include make-col(5);
        }

        @include mq(xl) {
            @include make-col(4);
        }

        @include mq(xxl) {
            @include make-col(3);
        }
    }

    .o-media__caption {
        @include make-col(12);

        @include mq(sm) {
            @include make-col(8);
        }

        @include mq(md) {
            @include make-col(5);

            margin-top: 0;
        }

        @include mq(lg) {
            @include make-col(4);
        }

        @include mq(xl) {
            @include make-col(3);
        }

        @include mq(xxl) {
            @include make-col(3);
        }
    }
}

.o-media--right-small {
    > .o-media__media.is-without-caption .o-media__img-wrap {
        @include mq(md) {
            @include make-col-offset(5);
        }

        @include mq(lg) {
            @include make-col-offset(6);
        }

        @include mq(xl) {
            @include make-col-offset(7);
        }

        @include mq(xxl) {
            @include make-col-offset(6);
        }
    }

    .o-media__img-wrap {
        @include mq(sm) {
            @include make-col-offset(4);
        }

        @include mq(md) {
            @include make-col-offset(0);

            order: 1;
        }
    }

    .o-media__caption {
        @include mq(sm) {
            @include make-col-offset(4);
        }

        @include mq(md) {
            @include make-col-offset(0);

            text-align: right;
        }

        @include mq(lg) {
            @include make-col-offset(2);
        }

        @include mq(xl) {
            @include make-col-offset(4);
        }

        @include mq(xxl) {
            @include make-col-offset(3);
        }
    }
}

.o-media--left-small {
    > .o-media__media.is-without-caption .o-media__img-wrap {
        @include mq(md) {
            @include make-col-offset(0);
        }

        @include mq(lg) {
            @include make-col-offset(1);
        }

        @include mq(xl) {
            @include make-col-offset(1);
        }

        @include mq(xxl) {
            @include make-col-offset(1);
        }
    }

    .o-media__img-wrap {
        @include mq(sm) {
            @include make-col-offset(0);
        }

        @include mq(md) {
            @include make-col-offset(0);

            order: 0;
        }

        @include mq(lg) {
            @include make-col-offset(1);
        }

        @include mq(xl) {
            @include make-col-offset(1);
        }

        @include mq(xxl) {
            @include make-col-offset(1);
        }
    }

    .o-media__caption {
        @include mq(sm) {
            @include make-col-offset(0);
        }

        @include mq(md) {
            @include make-col-offset(0);

            text-align: left;
        }

        @include mq(lg) {
            @include make-col-offset(0);
        }

        @include mq(xl) {
            @include make-col-offset(0);
        }

        @include mq(xxl) {
            @include make-col-offset(0);
        }
    }
}

//upright pictures
.o-media--left-upright,
.o-media--right-upright {
    > .o-media__media {
        display: flex;
        flex-direction: column;

        @include mq(md) {
            flex-direction: row;
        }
    }

    .o-media__img-wrap {
        @include make-col(12);

        display: block;
        flex: none;

        @include mq(sm) {
            @include make-col(8);
        }
        @include mq(md) {
            @include make-col(7);

            display: flex;
            flex: 0 0 100%;
        }
        @include mq(lg) {
            @include make-col(6);
        }
        @include mq(xl) {
            @include make-col(5);
        }
    }

    .o-media__caption {
        @include make-col(12);
        @include mq(sm) {
            @include make-col(8);
        }
        @include mq(md) {
            @include make-col(5);

            margin-top: 0;
        }
        @include mq(lg) {
            @include make-col(4);
        }
        @include mq(xl) {
            @include make-col(3);
        }
    }
}

.o-media--right-upright {
    > .o-media__media {
        &.is-without-caption {
            .o-media__img-wrap {
                @include mq(md) {
                    @include make-col-offset(5);
                }
                @include mq(lg) {
                    @include make-col-offset(6);
                }
            }
        }
    }

    .o-media__img-wrap {
        @include make-col-offset(0);

        @include mq(sm) {
            @include make-col-offset(4);
        }
        @include mq(md) {
            @include make-col-offset(0);

            order: 1;
        }
    }

    .o-media__caption {
        @include mq(sm) {
            @include make-col-offset(4);
        }
        @include mq(md) {
            @include make-col-offset(0);

            text-align: right;
        }
        @include mq(lg) {
            @include make-col-offset(2);
        }
        @include mq(xl) {
            @include make-col-offset(3);
        }
    }
}

.o-media--left-upright {
    .o-media__img-wrap {
        @include mq(xl) {
            @include make-col-offset(3);
        }
    }
}

.o-media--below {
    > .o-media__media,
    > .o-media__list {
        margin-top: $o-media-spacing-y;
    }
}

.o-media--stacked {
    > .o-media__media {
        width: 100%;
    }

    > .o-media__body {
        clear: both;
    }

    .o-media__caption {
        @include mq(lg) {
            @include make-col(9);
        }
    }
}

.o-media--mobile-below {
    > .o-media__media {
        order: 1;
    }
}
