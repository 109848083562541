/* ------------------------------------ *\
   #ce37_image_sequence
\* ------------------------------------ */
.c-image-sequence {
    position: relative;
    margin-bottom: $spacing_2xl;

    @include mq(lg) {
        margin-bottom: 0;
    }
}

.c-image-sequence__image {
    width: 100%;

    @include mq(lg) {
        display: none;
    }
}

.c-image-sequence__picture {
    display: block;

    > img {
        width: 100%;
    }

    .is-border {
        outline: 1px solid $c-grey-20;
    }
}

.c-image-sequence__images {
    @include zindex('below');
    @include make-row();

    position: sticky;
    top: $shared-sticky-header-height;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    //width: 100vw;
    //height: calc(100vh - #{$shared-sticky-header-height});
    //margin-right: ($grid-gutter-width *.5) * -1;
    //margin-left: ($grid-gutter-width *.5) * -1;
    overflow: hidden;

    @include mq(sm) {
        //margin-right: ($grid-gutter-width + ($grid-gutter-width * .5)) * -1;
        //margin-left: ($grid-gutter-width + ($grid-gutter-width * .5)) * -1;
    }

    @include mq(lg) {
        display: block;
    }
}

.c-image-sequence__images-container {
    @include make-col-ready();
    @include make-col(10);

    position: relative;
    margin-top: $spacing-3xl;
}

.c-image-sequence__images-box {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; //proportion value to aspect ratio 16:9 (9 / 16 = 0.5625 or 56.25%)
}

.c-image-sequence__element {
    @include make-row();

    @include mq(lg) {
        margin-top: 90vh;
        margin-bottom: 90vh;
    }

    &:last-child {
        @include mq(lg) {
            margin-bottom: 0;
            padding-bottom: 90vh;
        }
    }

    &[data-scroll] {
        transform: translateY(calc(var(--viewport-y) * 40px));
        // opacity: calc(var(--visible-y));
    }
}

.c-image-sequence__textbox {
    @include make-col-ready();

    margin-bottom: $spacing-s;
    color: $c-text;

    @include mq(md) {
        margin-bottom: $spacing-l;
    }

    @include mq(lg) {
        @include make-col(6);

        margin: 0;
    }

    @include mq(xl) {
        @include make-col(5);
    }

    // variation textbox right
    &.c-image-sequence__textbox--right {
        @include mq(lg) {
            @include make-col-offset(6);
        }

        @include mq(xl) {
            @include make-col-offset(7);
        }
    }
}

.c-image-sequence__textbox-inner {
    @include normal-font();

    padding-bottom: $spacing-l;
    border-bottom: 1px solid $c-grey-20;
    counter-reset: main-counter;

    @include mq(lg) {
        padding: $spacing-xl $spacing-xl $spacing-2xl $spacing-xl;
        background-color: $c-white;
        border-bottom: none;
        box-shadow: 0 0 45px 8px rgba($c-black, .05);
    }

    ol,
    ul {
        margin-bottom: $spacing-m;
        padding-left: 15px;
        list-style-type: none;

        @include mq(lg) {
            margin-bottom: $spacing-l;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    ul {
        @include list-unordered();
    }

    ol {
        @include list-ordered();
    }

    b,
    strong {
        font-weight: bold;
    }

    i,
    em {
        font-style: italic;
    }

    li {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    p {
        margin-bottom: $spacing-s;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .o-link--rte {
        padding-left: $spacing-m;
        color: $c-white;
        background-color: $c-brand;
        box-shadow: none;
        transition: color $global-transition-config, background-color $global-transition-config;

        &::before,
        &::after {
            display: none;
        }

        &:hover,
        &:focus {
            background-color: $c-grey-80;
            box-shadow: 0 5px 15px 0 rgba($c-grey-90, .2);
        }
    }
}

.c-image-sequence__headline {
    @include typo-h3(true, true);

    margin-bottom: $spacing-2xs;

    @include mq(lg) {
        margin-bottom: $spacing-s;
        font-size: $font-size-h2;
        line-height: $line-height-h2;
    }
}

.c-image-sequence__topline {
    @include textEmphasized();

    display: block;
    margin-bottom: 10px;
    color: $c-grey-40;
}

// variations image-sequence right / left
.c-image-sequence--left {
    .c-image-sequence__textbox {
        @include mq(lg) {
            @include make-col-offset(6);
        }

        @include mq(xl) {
            @include make-col-offset(7);
        }
    }
}

.c-image-sequence--right {
    .c-image-sequence__images-container {
        @include make-col-offset(2);
    }
}
