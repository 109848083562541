/* -------------------------------------------------------------------------- *\
    #ACCORDION_NAV
\* -------------------------------------------------------------------------- */

/**
 * Begin of module
 */
.c-accordion-nav {
    @include makeSection();
}

.c-accordion-nav--mobile-sites {
    @include zindex(top);

    @include mq($until: lg) {
        padding-right: 0;
        padding-left: 0;
    }
    @include mq(lg) {
        display: none;
    }

    .c-accordion-nav__item--l1 {
        border-bottom: 1px solid rgba($c-white, .3);

        &:last-child {
            border-bottom: 1px solid rgba($c-white, .3);
        }
    }
}

.c-accordion-nav__list {
    @include resetListStyles(false);
}

.c-accordion-nav__list--l2 {
    margin-left: -$grid-gutter-width /2;
}

.c-accordion-nav__item {
    @include resetListStyles();
}

.c-accordion-nav__item--l1 {
    border-bottom: 1px solid rgba($c-white, .15);

    &:last-child {
        border-bottom: 0;
    }
}

.c-accordion-nav__item--l2 {
    padding-left: $grid-gutter-width / 2;
}

.c-accordion-nav__heading {
    position: relative;
    display: flex;
    width: 100%;
    padding: 20px 0 18px $grid-gutter-width / 2;
    color: $c-white;
    text-align: left;

    &.is-current {
        color: $c-grey-80;
        background-color: $c-grey-20;

        .c-accordion-nav--mobile-sites & {
            background-color: darken($c-brand, 7%);
        }

        &:hover,
        &:focus {
            color: $c-white;
            background-color: $c-brand;
        }
    }

    &:hover,
    &:focus {
        color: $c-white;
        text-decoration: none;
        background-color: $c-brand;
        outline: none;

        .c-accordion-nav--mobile-sites & {
            color: $c-brand;
            background-color: $c-white;

            &::after {
                border-top-color: $c-brand;
                border-right-color: $c-brand;
            }
        }
    }
}

button.c-accordion-nav__heading--l1,
button.c-accordion-nav__heading--l2 {
    &::after {
        position: absolute;
        right: $grid-gutter-width / 2;
        display: block;
        width: 9px;
        height: 9px;
        border-top: 2px solid $c-white;
        border-right: 2px solid $c-white;
        transform: rotate(225deg) scaleY(-1);
        opacity: .2;
        transition: transform $global-transition-speed /2 $global-transition-easing, top $global-transition-speed /2 $global-transition-easing;
        content: '';
    }

    &.collapsed {
        &::after {
            transform: rotate(135deg);
        }
    }
}

.c-accordion-nav__heading--l1 {
    @include textEmphasizedSmall();

    font-weight: bold;
    transition: $global-transition-config;

    &::after {
        top: 28px;
        border-top-color: $c-white;
        border-right-color: $c-white;
    }

    &.collapsed {
        &::after {
            top: 24px;
            opacity: 1;
        }
    }

    &:hover {
        background: $c-brand;

        .c-accordion-nav--mobile-sites & {
            color: $c-brand;
            background-color: $c-white;
        }
    }

    &:hover,
    &:focus {
        text-decoration: none;

        &::after {
            opacity: 1;
        }
    }
}

.c-accordion-nav__heading--l2 {
    @include small-font();

    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: $grid-gutter-width /2;
    color: $c-light-grey;
    font-family: $global-font-family-regular;
    border-top: 1px solid $c-grey-60;

    &::after {
        top: 20px;
    }

    &.collapsed {
        &::after {
            top: 16px;
            transform: rotate(135deg);
            opacity: 1;
        }
    }

    &:hover {
        color: $c-white;
        background: $c-brand;

        .c-accordion-nav--mobile-sites & {
            color: $c-brand;
            background-color: $c-white;
        }
    }

    &:hover,
    &:focus {
        text-decoration: none;
        background-color: $c-brand;

        &::after {
            opacity: 1;
        }

        .c-accordion-nav--mobile-sites & {
            color: $c-brand;
            background-color: $c-white;
        }
    }

    .c-accordion-nav--mobile-sites & {
        color: $c-white;
        border-top: 1px solid $c-brand;
    }
}

.c-accordion-nav__heading--l3 {
    @include small-font();

    padding-top: 7px;
    padding-bottom: 6px;
    padding-left: 30px;
    color: $c-light-grey;
    font-family: $global-font-family-regular;

    &:hover,
    &:focus {
        color: $c-white;
        background-color: $c-brand;

        .c-accordion-nav--mobile-sites & {
            color: $c-brand;
            background-color: $c-white;
        }
    }

    .c-accordion-nav--mobile-sites & {
        color: $c-white;
        border-top: 1px solid $c-brand;
    }
}

.c-accordion-nav__label {
    display: block;
    flex: 1;
}
