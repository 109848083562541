.c-video {
    @include make-col-ready();

    .c-video__content {
        position: relative;
        height: 0;
        margin: auto;
        padding-bottom: 56.25%; //proportion value to aspect ratio 16:9 (9 / 16 = 0.5625 or 56.25%)
        overflow: hidden;
        background: $c-light-grey;
    }

    .c-video__cookie-consent {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $c-grey-80;
    }

    .c-video__cookie-consent-inner {
        display: flex;
        flex-direction: column;
        align-self: center;
        justify-content: center;
        max-width: 100%;
        height: 100%;
        margin-top: auto;
        margin-bottom: auto;
        padding: $spacing-l;
        color: $c-white;

        .o-media--stacked & {
            @include mq(md) {
                max-width: 80%;
                margin: auto;
            }

            @include mq(lg) {
                max-width: 70%;
                margin: auto;
            }

            @include mq(xl) {
                max-width: 60%;
            }

            @include mq(xxl) {
                max-width: 50%;
            }
        }
    }

    .c-video__cookie-consent-icon {
        position: absolute;
        top: 92px;
        left: 120px;
        display: none;
        opacity: .05;

        @include mq(md) {
            display: block;
        }
    }

    .o-icon {
        transform: scale(14);
    }

    .o-link--rte {
        margin-right: auto;
        padding-left: $spacing-m;
        color: $c-white;
        background-color: $c-brand;

        &:hover,
        &:active {
            background-color: $c-grey-40;
        }

        &::before,
        &::after {
            display: none;
        }
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
