.font-bold {
    font-weight: bold;
}

.font-thin {
    font-weight: 100;
}

.placeholder-box {
    padding: 20px;
    background: #f0f0f0;
}
