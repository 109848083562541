/* -------------------------------------------------------------------------- *\
    #Tabs
\* -------------------------------------------------------------------------- */

.c-tabs {
    @include make-col-ready();
    @include mq(md) {
        @include make-col-offset(1);
        @include make-col(9);
    }
    @include mq(xl) {
        @include make-col-offset(1);
        @include make-col(8);
    }
}

.c-tabs--articles {
    @include mq($until: lg) {
        display: none;
    }
}

.c-tabs__headline {
    @include sr-only();
}

.c-tabs__list {
    @include resetListStyles(false);

    position: relative;
    display: flex;
    width: 100%;

    @include mq(lg) {
        justify-content: flex-start;
    }
}

.c-tabs__item {
    @include resetListStyles(false);

    width: 100%;
    margin-bottom: 1px;

    @include mq(sm) {
        width: auto;
        margin-bottom: 0;
        margin-left: 10px;
    }

    &:first-child {
        margin-left: 0;
    }
}

.c-tabs__button {
    @include textEmphasized();

    display: block;
    padding: 13px 20px;
    color: $c-text;
    font-weight: bold;
    background: $c-transparent;
    border: 1px solid $c-grey-20;
    transition: $global-transition-config;
    transition-property: color, background-color, box-shadow;

    @include mq(md) {
        padding-right: 30px;
        padding-left: 30px;
    }

    &.is-active {
        color: $c-white;
        background: $c-brand;
        border: 1px solid $c-brand;
    }

    &:hover,
    &:active,
    &:focus {
        color: $c-white;
        text-decoration: none;
        background: $c-brand;
        border: 1px solid $c-brand;
        outline: 0;
        box-shadow: 0 5px 15px 0 rgba($c-grey-90, .2);
    }
}
