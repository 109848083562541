/* ------------------------------------ *\
    #META NAV
\* ------------------------------------ */
.c-meta-nav {
    @include mq(xl) {
        @include make-col-ready();
        @include make-col(4);
    }

    .is-fff &,
    .is-cv & {
        @include mq(xl) {
            @include make-col(12);
        }
    }

    .is-cv & {
        flex: 0 0 auto;
        width: auto;
        height: 100%;

        .c-meta-nav__content {
            justify-content: end;
            height: 100%;

            .c-meta-nav__list {
                height: 100%;

                .c-meta-nav__item {
                    .c-meta-nav__link {
                        height: 100%;
                        border-top: 0;
                    }
                }
            }
        }

        @include mq($until: xl) {
            height: unset;
        }
    }
}

.c-meta-nav__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .is-bp & {
        justify-content: flex-start;

        @include mq(xl) {
            justify-content: flex-end;
        }
    }
}

.c-meta-nav__title {
    @include textEmphasizedSmall();

    display: flex;
    flex-direction: row;
    align-self: center;
    margin-top: auto;
    margin-bottom: 1.25rem;
    margin-left: $grid-gutter-width * -.5;
    color: $c-grey-50;
    font-weight: bold;
    font-family: $global-font-family-regular;
    word-break: break-word;

    @include mq($until: xl) {
        display: none;
    }
}

.c-meta-nav__subtitle {
    position: relative;
    padding-left: 45px;

    &::after {
        @include zindex(base);

        position: absolute;
        top: 8px;
        left: 15px;
        display: block;
        width: 15px;
        height: 1px;
        background-color: $c-grey-40;
        content: '';
        pointer-events: none;
    }
}

.c-meta-nav__list {
    @include resetListStyles(false);

    position: relative;
    display: flex;
    justify-content: flex-end;
}

.c-meta-nav__item {
    margin: 0;
    padding: 0;

    @include mq($from: sm, $until: xl) {
        margin-right: $grid-gutter-width;
    }

    @include mq(xl) {
        margin-right: $spacing-m;
    }

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }

    &.is-note {
        display: none;

        @include mq(xl) {
            display: flex;
        }
    }

    &.is-translate {
        .c-meta-nav__link {
            width: $shared-header-top-height + $spacing-m;
        }

        .c-meta-nav__icon {
            right: 25px;
            left: -5px;
        }

        .c-meta-nav__label {
            @include textEmphasizedSmall();

            padding-left: 35px;
            font-weight: bold;
        }
    }
}

.c-meta-nav__link {
    width: $shared-header-top-height;
    height: $shared-header-top-height;
    color: $c-grey-50;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    transition: $global-transition-config;
    transition-property: color, border-color;

    &::after {
        @include zindex(base);

        position: absolute;
        left: calc(50% - 15px);
        display: block;
        width: 0;
        height: 0;
        border-right: 15px solid transparent;
        border-left: 15px solid transparent;
        opacity: 0;
        transition: $global-transition-config;
        transition-property: opacity;
        content: '';
        pointer-events: none;

        @include mq($until: xl) {
            bottom: -17px;
            border-top: 15px solid $c-light-grey;

            .is-cv & {
                bottom: -16px;
                border-top-color: $c-white;
            }
        }

        @include mq(xl) {
            top: -15px;
            border-bottom: 15px solid $c-light-grey;

            .is-cv & {
                top: -30px;
                border-bottom: 15px solid $c-white;
            }
        }
    }

    &:hover,
    &:focus {
        color: $c-brand;

        @include mq($until: xl) {
            border-top-color: $c-brand;
        }

        @include mq(xl) {
            border-bottom-color: $c-brand;
        }

        .c-meta-nav__label {
            &::after {
                background-color: $c-brand;
            }
        }
    }

    &.is-active {
        color: $c-text;

        @include mq($until: xl) {
            border-top-color: $c-black;
        }

        @include mq(xl) {
            border-bottom-color: $c-black;
        }

        &::after {
            opacity: 1;
        }

        .c-meta-nav__label {
            color: $c-text;

            &::after {
                background-color: $c-text;
            }
        }
    }
}

.c-meta-nav__label[title] {
    @include zindex(above);

    text-decoration: none;
    cursor: inherit;
}

.c-meta-nav__icon {
    @include zindex(above);

    position: relative;
    width: 48px;
    height: 48px;
}
