/* ------------------------------------ *\
    #TOPLINE
\* ------------------------------------ */

.o-topline {
    @include textEmphasized();

    display: block;
    margin-bottom: 10px;
    color: $c-grey-40;
}
