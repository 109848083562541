/* ------------------------------------ *\
    # APPLY TYPOGRAPHY
\* ------------------------------------ */

body {
    @include normal-font;

    color: $c-text;
    font-family: $global-font-family-regular;

    @include mq($until: md) {
        hyphens: auto;
    }
}

a {
    text-decoration: none;
}

a:hover,
a:focus,
a:active,
a.is-active {
    text-decoration: underline;
}

p {
    margin-bottom: 1rem;

    @include mq(lg) {
        margin-bottom: 2rem;
    }
}

h1,
.h1 {
    @include typo-h1();
}

h2,
.h2 {
    @include typo-h2();
}

h3,
.h3 {
    @include typo-h3();
}

h4,
.h4 {
    @include typo-h4();
}

h5,
.h5 {
    @include typo-h5();
}

h6,
.h6 {
    @include typo-h6();
}

strong {
    font-weight: bold;
}

sub,
sup {
    font-size: 65%;
}
