@mixin font-face(
    $family,
    $src:
    $family,
    $weight: normal,
    $style: normal,
    $woff2: true,
    $legacySupport: false) {

    @if $legacySupport {
        @font-face {
            font-weight: $weight;
            font-family: $family;
            font-style: $style;
            src: url('#{$src}.eot');
            font-display: swap;
        }
    }

    @font-face {
        font-weight: $weight;
        font-family: $family;

        @if $woff2 {
            src:
                url('#{$src}.woff2') format('woff2'),
                url('#{$src}.woff') format('woff');
        } @else {
            src: url('./#{$src}.woff') format('woff');
        }

        font-style: $style;
        font-display: swap;
    }
}
