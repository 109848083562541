/* ------------------------------------ *\
    #SLIDER
\* ------------------------------------ */

.c-slider {
    @include make-row();

    &.is-single {
        .c-slider__controls {
            display: none;
        }
    }

    .swiper-slide {
        display: flex;
        height: auto;
    }

    .swiper-container {
        @include zindex(zero);
    }

    .swiper-pagination {
        &.swiper-pagination-bullets {
            position: relative;
        }
    }

    .swiper-pagination-bullet {
        position: relative;
        width: $spacing-s;
        height: $spacing-s;
        background: rgba($c-grey-90, .5);
        opacity: 1;
        transition: transform $global-transition-config;

        &:hover,
        &:active,
        &:focus {
            background-color: $c-brand;
        }
    }

    .swiper-container-horizontal {
        .swiper-pagination-bullet {
            margin: 0 $spacing-xs;
        }
    }

    .swiper-pagination-bullet-active {
        background-color: $c-grey-90;
    }

    .is-dark {
        .swiper-pagination-bullet {
            background: rgba($c-white, .5);

            &:hover,
            &:active,
            &:focus {
                background-color: $c-brand;
            }
        }

        .swiper-pagination-bullet-active {
            background-color: $c-white;

            &:hover,
            &:active,
            &:focus {
                background-color: $c-brand;
            }
        }
    }
}

.c-slider--stage {
    .c-slider__controls {
        @include makeSection();

        position: absolute;
        left: 0;
    }

    .c-slider__button-wrap {
        justify-self: flex-end;
        margin-right: $grid-gutter-width / 2;
        margin-left: auto;
    }

    .c-slider__button {
        @include mq($until: sm) {
            display: none;
        }
    }

    .c-slider__pagination-wrap {
        @include mq(sm) {
            display: none;
        }
    }
}

.c-slider__headline {
    @include make-col-ready();

    margin-bottom: 30px;
    color: $c-white;
}

/*
    UI ELEMENTS
*/

.c-slider__controls {
    @include zindex(top);

    position: absolute;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    transform: translate3d(0, -100%, 0);
    opacity: 1;
    transition: opacity $global-transition-config;
}

.c-slider__controls--show {
    opacity: 1;
}

.c-slider__button-wrap {
    display: flex;
    order: 1;
}

.c-slider__pagination-wrap {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
    padding: 0 8px;
    padding-bottom: $spacing-xs;
}

.c-slider__button {
    width: 60px;
    height: 60px;
    color: $c-grey-90;
    background: rgba($c-white, .4);
    transition: color $global-transition-config, background-color $global-transition-config;

    @include mq(md) {
        width: 80px;
        height: 80px;
    }

    &:last-child {
        margin-left: 1px;
    }

    &:hover,
    &:active,
    &:focus {
        color: $c-white;
        background: $c-brand;
    }

    .is-dark & {
        color: $c-white;
        background: rgba($c-grey-90, .4);

        &:hover,
        &:active,
        &:focus {
            background: $c-brand;
        }
    }

    .o-icon {
        width: 50px;
        height: 50px;

        @include mq(md) {
            width: 66px;
            height: 66px;
        }
    }
}

.c-slider__container-wrap {
    display: flex;
    width: 100%;
}

.c-slider__container {
    width: 100%;
}

/*
    GLOBAL TEXT

    if global text is set, slider-teasers have only have 50% width
    to show global text beside the image
*/

//.c-slider__container-wrap--global-text {
//}

/*
    SINGLE ITEM

    sliders that only have a single item should not display
    prev/next buttons and pagination
*/
.c-slider--single-item {
    .c-slider__controls {
        display: none;
    }
}
