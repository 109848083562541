/* ------------------------------------ *\
    #Toggle
\* ------------------------------------ */
.c-toggle {
    //
}

.c-toggle--bookmark {
    position: absolute;
    top: 20px;
    right: 20px;
    display: block;
    width: 34px;
    height: 34px;
    transition: $global-transition-config;
    transition-property: opacity;

    @include mq(lg) {
        opacity: 0;
    }

    &::before {
        @include zindex(top);

        position: absolute;
        top: -15px;
        right: 0;
        display: block;
        width: 60px;
        height: 60px;
        background-color: transparent;
        content: '';
    }

    &::after {
        @include zindex(base);

        position: absolute;
        top: 0;
        right: 14px;
        display: block;
        width: 34px;
        height: 34px;
        background-color: $c-brand;
        border-radius: 100%;
        content: '';
    }

    &:hover,
    &:focus {
        opacity: 1;
    }

    &.is-added {
        .c-toggle__label {
            display: none;
        }

        .c-toggle__label-active {
            display: flex;
        }
    }

    .c-toggle__label {
        @include text-hide();

        position: relative;
        display: flex;

        &::before {
            @include zindex(above);

            position: absolute;
            top: -1px;
            right: 22px;
            display: block;
            width: 18px;
            height: 2px;
            background-color: $c-white;
            content: '';
        }

        &::after {
            @include zindex(above);

            position: absolute;
            top: -9px;
            right: 30px;
            display: block;
            width: 2px;
            height: 18px;
            background-color: $c-white;
            content: '';
        }
    }

    .c-toggle__label-active {
        @include text-hide();

        position: relative;
        display: none;

        &::before {
            @include zindex(above);

            position: absolute;
            top: -1px;
            right: 22px;
            display: block;
            width: 18px;
            height: 2px;
            background-color: $c-white;
            content: '';
        }
    }
}

.c-toggle--bookmark-details {
    @include textEmphasized();

    position: relative;
    display: inline-block;
    width: auto;
    margin-top: 20px;
    padding: 13px 30px 13px 46px;
    color: $c-text;
    font-weight: bold;
    text-align: left;
    background-color: $c-white;
    transition: $global-transition-config;
    transition-property: background-color, box-shadow;

    @include mq(md) {
        margin-top: 30px;
    }

    @include mq(lg) {
        margin-top: 40px;
    }

    &::before {
        @include zindex(top);

        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: transparent;
        content: '';
    }

    &:hover,
    &:active,
    &:focus {
        color: $c-white;
        text-decoration: none;
        background-color: $c-brand;
        outline: 0;
        box-shadow: 0 5px 15px 0 rgba($c-grey-90, .2);

        .c-toggle__label,
        .c-toggle__label-active {
            &::before,
            &::after {
                background-color: $c-white;
            }
        }
    }

    &.is-added {
        .c-toggle__label {
            display: none;
        }

        .c-toggle__label-active {
            display: flex;
        }
    }

    .c-toggle__label {
        position: relative;
        display: flex;

        &::before {
            @include zindex(above);

            position: absolute;
            top: 50%;
            left: -32px;
            display: block;
            width: 18px;
            height: 2px;
            background-color: $c-brand;
            content: '';
        }

        &::after {
            @include zindex(above);

            position: absolute;
            top: calc(50% - 8px);
            left: -24px;
            display: block;
            width: 2px;
            height: 18px;
            background-color: $c-brand;
            content: '';
        }
    }

    .c-toggle__label-active {
        position: relative;
        display: none;

        &::before {
            @include zindex(above);

            position: absolute;
            top: 50%;
            left: -32px;
            display: block;
            width: 18px;
            height: 2px;
            background-color: $c-brand;
            content: '';
        }
    }
}
