/* ------------------------------------ *\
   teaser
\* ------------------------------------ */
.c-teaser {
    @include clearfix();
    @include zindex(base);

    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    width: calc(100% + #{$grid-gutter-width});
    margin-right: calc((100vw - 100%) / -2);
    margin-left: calc((100vw - 100%) / -2);
    padding: 10px calc((100vw - 100%) / 2);
    color: $c-text;
    background-color: $c-transparent;
    transition: $global-transition-config;
    transition-property: background-color, border-color;

    @include mq(sm) {
        width: 100%;
        margin: 0;
        padding-right: 10px;
        padding-left: 10px;
    }

    @include mq(md) {
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        padding: 20px;
    }

    @include mq(lg) {
        padding: 30px;
    }

    &:hover,
    &:focus {
        @include zindex(top);

        text-decoration: none;
        background-color: $c-brand;
        border-color: $c-grey-20;

        &::after {
            background-color: $c-brand;
        }

        .c-teaser__headline,
        .c-teaser__text {
            color: $c-white;
            text-decoration: none;
        }

        .c-teaser__icon {
            color: $c-white;

            &::before {
                background-color: $c-white;
            }

            &::after {
                border-color: $c-white;
            }
        }

        mark {
            color: $c-brand;
            font-weight: bold;
            background-color: $c-white;
        }
    }

    &::after {
        position: absolute;
        top: 0;
        left: $grid-gutter-width / 2;
        display: block;
        width: calc(100% - #{$grid-gutter-width});
        height: 1px;
        background-color: $c-grey-20;
        transition: $global-transition-config;
        transition-property: background-color;
        content: '';

        @include mq(sm) {
            left: 0;
            width: 100%;
        }
    }

    mark {
        font-weight: bold;
        background-color: $c-grey-10;
        transition: $global-transition-config;
        transition-property: color, background-color;
    }
}

.c-teaser__text-box {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    @include mq(md) {
        width: calc(100% - 40px);
    }
}

.c-teaser__headline {
    @include typo-h5(false);

    width: 100%;
    margin-bottom: 5px;
    transition: $global-transition-config;
    transition-property: color;

    @include mq(lg) {
        @include typo-h3(false, false);

        margin-bottom: 15px;
    }
}

.c-teaser__text {
    @include small-font();

    margin-bottom: 0;
    transition: $global-transition-config;
    transition-property: color;

    @include mq(md) {
        @include normal-font();

        padding-right: $grid-gutter-width;
    }
}

.c-teaser__icon {
    @include text-hide();

    position: relative;
    display: flex;
    width: 10px;
    height: 32px;
    transition: $global-transition-config;
    transition-property: color;

    @include mq(md) {
        height: auto;
        margin-right: $grid-gutter-width /2;
        margin-left: $grid-gutter-width;
    }

    @include mq(xl) {
        margin-right: $grid-gutter-width;
    }

    &::before,
    &::after {
        position: absolute;
        display: block;
        content: '';
    }

    &::before {
        top: 20px;
        bottom: 0;
        left: 0;
        width: 21px;
        height: 2px;
        background-color: $c-brand;
        transition: $global-transition-config;
        transition-property: background;

        @include mq(md) {
            top: -2px;
        }
    }

    &::after {
        top: 15px;
        left: 9px;
        width: 12px;
        height: 12px;
        border-color: $c-brand;
        border-top: 2px solid $c-brand;
        border-right: 2px solid $c-brand;
        transform: rotate(45deg);
        transition: $global-transition-config;
        transition-property: border, left;

        @include mq(md) {
            top: -7px;
        }
    }
}
