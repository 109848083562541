/* ------------------------------------ *\
    #LOGO
\* ------------------------------------ */

$logo-width-xl: 225px;
$logo-width-xs: 139px;
$logo-cottano-width-xl: 135px;
$logo-cottano-width-xs: 90px;
$logo-cottano-width-footer: 110px;

.c-logo {
    display: block;

    .is-sticky & {
        margin-top: 0;
        margin-right: 0;
    }
}

.c-logo__icon {
    width: $logo-width-xs;

    .c-logo--cottano & {
        width: $logo-cottano-width-xs;
        fill: $c-brand--cottano;
    }

    .c-footer .c-logo--cottano & {
        width: $logo-cottano-width-footer;
        margin-bottom: .5em;
        fill: $c-white;
    }

    @include mq(xl) {
        width: $logo-width-xl;

        .c-logo--cottano & {
            width: $logo-cottano-width-xl;
        }

        .c-footer .c-logo--cottano & {
            width: $logo-cottano-width-footer;
            margin-bottom: .5em;
        }

        .is-sticky & {
            width: $logo-width-xs;
        }

        .is-sticky .c-logo--cottano & {
            width: $logo-cottano-width-xs;
        }
    }
}
