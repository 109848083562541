/* ------------------------------------ *\
    #ce38_text-image-group
\* ------------------------------------ */

.c-text-image-group {
    @include clearfix();
    @include moduleSpace();
}

.c-text-image-group__header {
    @include make-row();

    margin-bottom: $spacing-m;

    @include mq(md) {
        margin-bottom: $spacing-xl;
    }

    @include mq(lg) {
        margin-bottom: $spacing-2xl;
    }
}

.c-text-image-group__topline {
    @include textEmphasized();

    display: block;
    margin-bottom: 10px;
    color: $c-grey-40;
}

.c-text-image-group__headline {
    @include make-col-ready();
    @include mq(lg) {
        @include make-col(10);
        @include make-col-offset(1);
    }
    @include mq(xl) {
        @include make-col(9);
    }
    @include mq(xxl) {
        @include make-col(8);
    }
}

.c-text-image-group__intro {
    @include make-col-ready();

    margin-bottom: 0;

    @include mq(lg) {
        @include make-col(10);
        @include make-col-offset(1);
    }
    @include mq(xl) {
        @include make-col(9);
    }
    @include mq(xxl) {
        @include make-col(8);
    }
}

.c-text-image-group__items {
    @include resetListStyles();

    width: 100%;
}

// style modifier col-2
.c-text-image-group--col-2 {
    .c-text-image-group__items {
        @include make-row();

        width: auto;
    }
}

// style modifier col-3
.c-text-image-group--col-3 {
    .c-text-image-group__items {
        @include make-row();

        width: auto;
    }
}
