/* ------------------------------------ *\
    #ce16-download
\* ------------------------------------ */
.c-download {
    @include clearfix();
    @include make-row();

    @include mq($until: sm) {
        margin-right: 0;
        margin-left: 0;
    }
}

.c-download__header {
    @include make-col-ready();

    margin-bottom: $spacing-xl;

    @include mq($until: sm) {
        padding-right: 0;
        padding-left: 0;
    }
    @include mq(sm) {
        margin-bottom: $spacing-2xl;
    }
    @include mq(lg) {
        @include make-col-offset(1);
        @include make-col(9);
    }
    @include mq(xxl) {
        @include make-col(7);
    }
}

.c-download__headline {
    @include typo-h2(false, true);

    color: $c-text;
}

.c-download__topline {
    @include textEmphasized();

    display: block;
    margin-bottom: 10px;
    color: $c-grey-40;
}

.c-download__text {
    margin-top: 1.25rem;

    @include mq(xl) {
        margin-top: 1.875rem;
    }
}

.c-download__list {
    @include resetListStyles(false);
    @include make-col-ready();

    @include mq($until: sm) {
        padding-right: 0;
        padding-left: 0;
    }
    @include mq(lg) {
        @include make-col-offset(2);
        @include make-col(9);
    }
    @include mq(xl) {
        @include make-col(8);
    }
}

.c-download__item {
    @include resetListStyles(false);
    @include normal-font();

    color: $c-text;
}

.c-download__link {
    @include textEmphasized();

    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px 60px 20px 20px;
    color: $c-text;
    font-weight: bold;
    background-color: $c-transparent;
    transition: background-color $global-transition-config, color $global-transition-config;
    fill: currentColor;

    @include mq(sm) {
        flex-direction: row;
        justify-content: space-between;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    @include mq(md) {
        padding-right: 80px;
    }
    @include mq(lg) {
        padding-left: 30px;
    }

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 1px;
        background-color: $c-grey-20;
        transition: background $global-transition-config;
        content: '';
    }

    &:hover,
    &:focus {
        color: $c-white;
        text-decoration: none;
        background-color: $c-brand;

        &::before {
            background-color: $c-brand;
        }

        .o-icon {
            fill: $c-white;
        }
    }

    .o-icon {
        position: absolute;
        right: 5px;
        bottom: 7px;
        display: block;
        width: 48px;
        height: 48px;
        fill: $c-brand;

        @include mq(sm) {
            top: 15px;
            bottom: inherit;
        }

        @include mq(lg) {
            right: 14px;
        }
    }
}

.c-download__link-label {
    display: flex;
    width: auto;

    @include mq(sm) {
        min-width: calc(100% - 110px);
        padding-top: 0;
    }
}

.c-download__link-addon {
    @include small-font();

    width: 100%;
    padding-top: 20px;
    font-weight: normal;
    line-height: 19px;
    text-align: right;

    @include mq(sm) {
        min-width: 110px;
        padding-top: 0;
    }
}
