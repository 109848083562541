$o-btn-height-default: 40px;
$o-btn-width-default: 40px;
$o-btn-bg-default: $c-white !default;
$o-btn-bg-hover-default: transparent !default;
$o-btn-color-default: $c-brand !default;
$o-btn-color-hover-default: $c-brand !default;

/**
 * $BUTTONS
 * ==========================================================================
 *  Recommended use:
 *
 *  <a class="o-btn [o-btn--modifier]" role="button" href="[url]">Button text</a>
 *  <button class="o-btn [o-btn--modifier]" type="submit">Button text</button>
 *  <input class="o-btn [o-btn--modifier]" type="submit" value="Button text">
 */

@mixin o-btn-base {
    @include textEmphasized();

    display: inline-block;
    font-family: inherit;
    border-radius: 0;
    cursor: pointer;
    user-select: none;

    &::-moz-focus-inner {
        padding: 0;
        border: 0;
    }

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
    }

    &:disabled,
    &.is-disabled {
        cursor: default;
    }
}

@mixin o-btn {
    @include o-btn-base;

    color: $o-btn-color-default;
    // background-color: $o-btn-bg-default;
    // border: 1px solid $o-btn-bg-default;

    &:hover,
    &:active,
    &:focus {
        color: $o-btn-color-hover-default;
        // background-color: $o-btn-bg-hover-default;
        // border-color: currentColor;
    }
}

.o-btn {
    @include o-btn;

    &::after {
        font-family: Verdana, Arial, sans-serif;
        content: '';
    }
}

/**
 * Button Functions
 * --------------------------------------------------------------------------
 */

.o-btn--cta {
    @include zindex(zero);

    position: relative;
    padding: $spacing $spacing $spacing $spacing * 4;
    color: $c-text;
    transition: $global-transition-config;

    &::before {
        position: absolute;
        top: 50%;
        left: 10px;
        width: $default-icon-size;
        height: $default-icon-size;
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'%3E%3Cpath fill='%23D60F3C' d='M24 7.969l16.031 16.031-16.031 16.031-2.813-2.813 11.156-11.25h-24.375v-3.938h24.375l-11.156-11.25z'%3E%3C/path%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: right center;
        background-size: $default-icon-size $default-icon-size;
        transform: translateY(-50%);
        content: '';
        fill: $c-brand;
    }

    &::after {
        @include zindex(zero, -1);

        position: absolute;
        top: 0;
        left: 0;
        width: 2px;
        height: 100%;
        background: $c-brand;
        transition: width $global-transition-config;
        content: '';
    }

    &:hover,
    &:focus {
        color: $c-white;
        background-color: $c-brand;
        border-color: $c-brand;

        &::before {
            background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'%3E%3Cpath fill='%23ffffff' d='M24 7.969l16.031 16.031-16.031 16.031-2.813-2.813 11.156-11.25h-24.375v-3.938h24.375l-11.156-11.25z'%3E%3C/path%3E%3C/svg%3E");
        }

        &::after {
            width: 100%;
        }
    }
}

.o-btn--icon {
    position: relative;
    display: block;
    width: $o-btn-width-default;
    height: $o-btn-height-default;

    > .o-icon {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        fill: currentColor;
    }
}

.o-btn--text {
    position: relative;
    align-self: flex-start;
    padding: 3px;

    &:hover,
    &:focus {
        color: inherit;
    }
}

.o-btn__text-hidden {
    @include sr-only();
}
