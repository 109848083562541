/* ------------------------------------ *\
    #LINK OBJECT
\* ------------------------------------ */

/**
 * Begin of module
 */

@mixin link {
    color: $c-text;
    box-shadow: 0 2px 0 0 $c-brand;
    transition: color $global-transition-config;

    &:hover,
    &:active,
    &:focus {
        color: $c-brand;
        text-decoration: none;
    }
}

.o-link {
    @include link();
}

/**
 * Highlight link - (stage)
 * --------------------------------------------------------------------------
 */
.o-link--highlight {
    @include textEmphasized();

    padding: 12px 15px 11px 15px;
    color: $c-white;
    font-weight: bold;
    background-color: $c-grey-80;
    border: none;
    box-shadow: none;
    transition: color $global-transition-config, background-color $global-transition-config;

    @include mq(md) {
        padding-right: 20px;
        padding-left: 20px;
    }

    @include mq(lg) {
        padding-right: 30px;
        padding-left: 30px;
    }

    &:hover,
    &:focus {
        color: $c-white;
        background-color: $c-brand;
        border: none;
        box-shadow: 0 5px 15px 0 rgba($c-grey-90, .2);

        .is-dark & {
            color: $c-white;
            background-color: $c-brand;
        }
    }

    .is-dark & {
        color: $c-text;
        background-color: $c-white;
    }
}

/**
 * Exernal link
 * --------------------------------------------------------------------------
 */
.o-link--external {
    &:hover,
    &:focus {
        // background-image: url('../svg/background/link-external-hover.svg');
    }
}

/**
 * Footer link
 * --------------------------------------------------------------------------
 */

.o-link--footer {
    @include small-font();

    position: relative;
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    color: $c-white;
    box-shadow: none;

    .o-link__inner {
        @include zindex(above);

        display: flex;
        width: auto;
        padding-bottom: 1px;
        color: $c-white;
        background-color: $c-transparent;
        border-bottom: 2px solid $c-white;
        box-shadow: none;
        transition: $global-transition-speed linear;
        transition-property: border-bottom-color, background;
    }

    &:hover,
    &:active,
    &:focus {
        outline: none;

        .o-link__inner {
            background-color: $c-brand;
            border-bottom: 2px solid $c-brand;
        }
    }

    &.is-on-red {
        &:hover,
        &:focus {
            .o-link__inner {
                color: $c-brand;
                background-color: $c-white;
                border-bottom: 2px solid $c-white;
            }
        }
    }
}

/**
 * TextMedia link
 * --------------------------------------------------------------------------
 */

.o-link--rte {
    @include textEmphasizedSmall();

    position: relative;
    display: inline-block;
    padding: 11px 20px 12px 50px;
    font-weight: bold;
    background-color: $c-transparent;
    box-shadow: none;
    transition: $global-transition-config;
    transition-property: color, background-color;

    &:hover,
    &:focus {
        color: $c-white;
        background-color: $c-brand;

        &::before {
            background-color: $c-white;
        }

        &::after {
            border-color: $c-white;
        }
    }

    &::before,
    &::after {
        position: absolute;
        display: block;
        content: '';
    }

    &::before {
        top: 18px;
        bottom: 0;
        left: 15px;
        width: 21px;
        height: 2px;
        background-color: $c-brand;
    }

    &::after {
        top: 13px;
        left: 24px;
        width: 12px;
        height: 12px;
        border-color: $c-brand;
        border-top: 2px solid $c-brand;
        border-right: 2px solid $c-brand;
        transform: rotate(45deg);
    }

    &.is-extern {
        &::before {
            top: 18px;
            left: 15px;
            transform: rotate(-45deg);
        }

        &::after {
            top: 11px;
            left: 22px;
            transform: rotate(0);
        }
    }
}

/**
 * text-image-group link
 * --------------------------------------------------------------------------
 */

.o-link--content {
    @include textEmphasizedSmall();

    position: relative;
    display: flex;
    align-self: flex-start;
    padding: 11px 20px 12px 50px;
    font-weight: bold;
    background-color: $c-transparent;
    box-shadow: none;
    transition: $global-transition-config;
    transition-property: color, background-color;

    &:hover,
    &:focus {
        color: $c-white;
        background-color: $c-brand;

        &::before {
            background-color: $c-white;
        }

        &::after {
            border-color: $c-white;
        }
    }

    &::before,
    &::after {
        position: absolute;
        display: block;
        content: '';
    }

    &::before {
        top: 18px;
        bottom: 0;
        left: 15px;
        width: 21px;
        height: 2px;
        background-color: $c-brand;
    }

    &::after {
        top: 13px;
        left: 24px;
        width: 12px;
        height: 12px;
        border-color: $c-brand;
        border-top: 2px solid $c-brand;
        border-right: 2px solid $c-brand;
        transform: rotate(45deg);
    }

    &.is-extern {
        &::before {
            top: 18px;
            left: 15px;
            transform: rotate(-45deg);
        }

        &::after {
            top: 11px;
            left: 22px;
            transform: rotate(0);
        }
    }
}

/**
 * Icon link - (function bar)
 * --------------------------------------------------------------------------
 */
.o-link--icon {
    @include text-hide();

    position: relative;
    display: flex;
    width: 52px;
    height: 52px;
    background-color: $c-transparent;
    border: 1px solid $c-grey-20;
    box-shadow: none;
    transition: $global-transition-config;
    transition-property: outline, background-color;

    &:hover,
    &:focus {
        color: $c-white;
        text-decoration: none;
        background-color: $c-brand;
        border: 1px solid $c-brand;

        .o-icon {
            fill: $c-white;
        }
    }

    .o-icon {
        position: absolute;
        top: 2px;
        right: 2px;
        display: block;
        width: 48px;
        height: 48px;
        transition: $global-transition-config;
        transition-property: fill;
        fill: $c-grey-50;
    }
}

/**
 * Back link - (function-bar)
 * --------------------------------------------------------------------------
 */

.o-link--back {
    @include textEmphasizedSmall();

    position: relative;
    display: flex;
    padding: 16px 20px 10px 57px;
    font-weight: bold;
    background-color: $c-transparent;
    border: 1px solid $c-grey-20;
    box-shadow: none;
    transition: $global-transition-config;
    transition-property: color, background-color;

    &:hover,
    &:focus {
        color: $c-white;
        background-color: $c-brand;
        border: 1px solid $c-brand;

        &::before {
            background-color: $c-white;
        }

        &::after {
            border-color: $c-white;
        }
    }

    &::before,
    &::after {
        position: absolute;
        display: block;
        content: '';
    }

    &::before {
        top: 22px;
        bottom: 0;
        left: 17px;
        width: 21px;
        height: 2px;
        background-color: $c-brand;
        transition: $global-transition-config;
        transition-property: background;
    }

    &::after {
        top: 17px;
        left: 18px;
        width: 12px;
        height: 12px;
        border-color: $c-brand;
        border-bottom: 2px solid $c-brand;
        border-left: 2px solid $c-brand;
        transform: rotate(45deg);
        transition: $global-transition-config;
        transition-property: border, left;
    }
}

/**
 * Back link - (product-detail page)
 * --------------------------------------------------------------------------
 */

.o-link--product-back {
    @include textEmphasizedSmall();

    position: relative;
    display: flex;
    padding: 16px 20px 10px 40px;
    font-weight: bold;
    background-color: $c-transparent;
    border: none;
    box-shadow: none;
    transition: $global-transition-config;
    transition-property: color, background-color;

    &:hover,
    &:focus {
        color: $c-brand;
    }

    &::before,
    &::after {
        position: absolute;
        display: block;
        content: '';
    }

    &::before {
        top: 22px;
        bottom: 0;
        left: 5px;
        width: 21px;
        height: 2px;
        background-color: $c-brand;
        transition: $global-transition-config;
        transition-property: background;
    }

    &::after {
        top: 17px;
        left: 5px;
        width: 12px;
        height: 12px;
        border-color: $c-brand;
        border-bottom: 2px solid $c-brand;
        border-left: 2px solid $c-brand;
        transform: rotate(45deg);
        transition: $global-transition-config;
        transition-property: border, left;
    }
}

/**
 * send email link - (collection)
 * --------------------------------------------------------------------------
 */
.o-link--link,
.o-link--email {
    @include textEmphasizedSmall();

    position: relative;
    display: flex;
    margin-left: 20px;
    padding: 15px 20px 15px 57px;
    font-weight: bold;
    background-color: $c-transparent;
    border: 1px solid $c-grey-20;
    box-shadow: none;
    transition: $global-transition-config;
    transition-property: color, background-color;

    &:hover,
    &:focus {
        color: $c-white;
        background-color: $c-brand;
        border: 1px solid $c-brand;
        box-shadow: 0 5px 15px 0 rgba($c-grey-90, .2);

        &::before {
            background-color: $c-white;
        }

        &::after {
            border-color: $c-white;
        }
    }

    &::before,
    &::after {
        position: absolute;
        display: block;
        content: '';
    }

    &::before {
        top: 22px;
        bottom: 0;
        left: 15px;
        width: 21px;
        height: 2px;
        background-color: $c-brand;
        transition: $global-transition-config;
        transition-property: background;
    }

    &::after {
        top: 17px;
        left: 24px;
        width: 12px;
        height: 12px;
        border-color: $c-brand;
        border-top: 2px solid $c-brand;
        border-right: 2px solid $c-brand;
        transform: rotate(45deg);
        transition: $global-transition-config;
        transition-property: border, left;
    }
}

/**
 * send pdf link - (collection)
 * --------------------------------------------------------------------------
 */
.o-link--download {
    @include textEmphasizedSmall();

    position: relative;
    display: flex;
    margin-left: 20px;
    padding: 15px 20px 15px 57px;
    font-weight: bold;
    background-color: $c-transparent;
    border: 1px solid $c-grey-20;
    box-shadow: none;
    transition: $global-transition-config;
    transition-property: color, background-color;

    &:hover,
    &:focus {
        color: $c-white;
        background-color: $c-brand;
        border: 1px solid $c-brand;
        box-shadow: 0 5px 15px 0 rgba($c-grey-90, .2);

        .o-icon {
            fill: $c-white;
        }
    }

    .o-icon {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 5px;
        margin: auto 0;
        fill: $c-brand;
    }
}

/**
 * Jump anchor link - (anchor-navigation)
 * --------------------------------------------------------------------------
 */
.o-link--anchor {
    @include textEmphasized();

    position: relative;
    display: inline-block;
    width: 100%;
    margin-bottom: $spacing-2xs;
    padding: 13px 30px 13px 46px;
    color: $c-brand;
    font-weight: bold;
    text-align: left;
    background-color: $c-white;
    border: 1px solid $c-brand;
    box-shadow: 0 0 0 0 $c-transparent;
    transition: $global-transition-config;
    transition-property: background-color, box-shadow;

    @include mq(md) {
        width: auto;
        margin-right: $spacing-xs;
        margin-bottom: $spacing-xs;
    }

    &::before,
    &::after {
        position: absolute;
        display: block;
        transition: $global-transition;
        content: '';
    }

    &::before {
        top: calc(50% - 2px);
        left: 18px;
        width: 9px;
        height: 9px;
        border-bottom: 2px solid $c-brand;
        border-left: 2px solid $c-brand;
        transform: rotate(225deg) scaleY(-1);
        transition-property: border-color;
    }

    &::after {
        top: calc(50% - 7px);
        left: 21px;
        width: 2px;
        height: 15px;
        background-color: $c-brand;
    }

    &:hover,
    &:active,
    &:focus {
        color: $c-white;
        text-decoration: none;
        background-color: $c-brand;
        outline: 0;
        box-shadow: 0 5px 15px 0 rgba($c-grey-90, .2);

        &::before {
            border-bottom: 2px solid $c-white;
            border-left: 2px solid $c-white;
        }

        &::after {
            background-color: $c-white;
        }
    }
}
