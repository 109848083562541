/* ------------------------------------ *\
    #Function-bar
\* ------------------------------------ */
.c-function-bar {
    @include make-container();

    position: relative;
    display: flex;
    justify-content: flex-end;

    &:not(.is-top) {
        @include moduleSpace();
    }

    &.is-top {
        + .o-module {
            margin-top: $spacing-xl;

            @include mq(sm) {
                margin-top: $spacing-xl;
            }

            @include mq(md) {
                margin-top: $spacing-xl;
            }

            @include mq(lg) {
                margin-top: $spacing-2xl;
            }

            @include mq(xl) {
                margin-top: $spacing-3xl;
            }
        }
    }
}

.c-function-bar__headline {
    @include sr-only();
}

.c-function-bar__menu {
    @include make-row();

    display: flex;
    align-items: center;

    .o-link--back {
        @include text-hide();
        @include zindex(base);

        width: 42px;
        height: 42px;
        margin-right: -1px;
        padding: 0;
        border: 1px solid $c-grey-20;
        transition: $global-transition-config;
        transition-property: background-color, box-shadow;

        @include mq(md) {
            @include textEmphasizedSmall();

            width: auto;
            height: 52px;
            margin-right: 10px;
            padding: 17px 20px 16px 57px;
            color: $c-text;
            font-weight: bold;
            font-family: $global-font-family-regular;
        }

        &:hover,
        &:active,
        &:focus {
            @include zindex(top);

            color: $c-white;
            background-color: $c-brand;
            border: 1px solid $c-brand;
            outline: 0;

            @include mq(md) {
                box-shadow: 0 5px 15px 0 rgba($c-grey-90, .2);
            }

            &::before {
                background-color: $c-white;
            }

            &::after {
                border-color: $c-white;
            }
        }

        &::before {
            top: 19px;
            left: 11px;

            @include mq(md) {
                top: 24px;
                left: 17px;
            }
        }

        &::after {
            top: 14px;
            left: 12px;

            @include mq(md) {
                top: 19px;
                left: 18px;
            }
        }
    }
}

.c-function-bar__sharing {
    display: flex;
}

.c-function-bar__list {
    @include resetListStyles();

    display: flex;
    flex-wrap: nowrap;
    margin: 0;
    padding: 0;
}

.c-function-bar__item {
    @include resetListStyles();

    position: relative;
    display: flex;
    margin: 0 -1px 0 0;
    padding: 0;

    @include mq(md) {
        margin: 0 10px 0 0;
    }

    &:last-child {
        @include mq(md) {
            margin-right: 0;
        }
    }
}

.c-function-bar__link {
    @include zindex(base);

    display: flex;
    width: 42px;
    height: 42px;
    background-color: $c-transparent;
    border: 1px solid $c-grey-20;
    box-shadow: none;
    transition: $global-transition-config;
    transition-property: outline, background-color, box-shadow;

    @include mq(md) {
        width: 52px;
        height: 52px;
    }

    &:hover,
    &:active,
    &:focus {
        @include zindex(top);

        text-decoration: none;
        background-color: $c-brand;
        border: 1px solid $c-brand;
        outline: 0;

        @include mq(md) {
            box-shadow: 0 5px 15px 0 rgba($c-grey-90, .2);
        }

        .o-icon {
            fill: $c-white;
        }
    }

    .o-icon {
        position: absolute;
        top: -3px;
        right: -3px;
        display: block;
        width: 48px;
        height: 48px;
        transition: $global-transition-config;
        transition-property: fill;
        fill: $c-grey-50;

        @include mq(md) {
            top: 2px;
            right: 2px;
        }
    }
}

.c-function-bar__label {
    @include sr-only();
}
