/* ------------------------------------ *\
    #WIDTH_UTILITIES
\* ------------------------------------ */

head {
    font-family: 'xs';

    @include mq(sm) {
        font-family: 'sm';
    }

    @include mq(md) {
        font-family: 'md';
    }

    @include mq(lg) {
        font-family: 'lg';
    }

    @include mq(xl) {
        font-family: 'xl';
    }

    @include mq(xxl) {
        font-family: 'xxl';
    }
}
