/* -------------------------------------------------------------------------- *\
    #SHARED VARIABLES
\* -------------------------------------------------------------------------- */

$shared-header-top-height: 50px;
$shared-sticky-header-height: 60px;
$shared-sticky-header-transition-duration: .5s;

// Menu page overlays
$shared-menu-overlay-bg-color: #000000;
$shared-menu-overlay-bg-opacity: .66;
$shared-menu-overlay-bg: rgba($shared-menu-overlay-bg-color, $shared-menu-overlay-bg-opacity);
