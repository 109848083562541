/* ------------------------------------ *\
    #HEADER_FLYOUT
\* ------------------------------------ */

.c-header-flyout__wrap--mobile {
    overflow: hidden;
}

.c-header-flyout__wrap {
    @include mq(xl) {
        @include zindex(top);

        position: fixed;
        top: 0;
        width: 100%;
    }
}

.c-header-flyout {
    @include zindex(top);

    position: relative;
}

.c-header-flyout__content-wrap {
    @include clearfix();

    display: none;
    width: 100%;
    margin: 0;
    color: $c-white;
    background: $c-grey-50;

    .c-header-flyout--sites & {
        background-color: $c-brand;
    }

    &.is-visible {
        display: block;
    }
}

.c-header-flyout__box {
    @include makeSection();
}

.c-header-flyout__content {
    @include make-row();

    display: flex;
    padding: 40px 0;
}

.c-header-flyout__header,
.c-header-flyout__body {
    @include make-col-ready();
}

.c-header-flyout__header {
    @include make-col(12);

    margin-bottom: 20px;

    @include mq(lg) {
        @include make-col(5);
    }

    @include mq(xl) {
        @include make-col(4);
    }

    .c-header-flyout--contact & {
        @include mq(lg) {
            @include make-col(5);

            margin-bottom: 0;
        }

        @include mq(xl) {
            @include make-col(3);
        }
    }

    .c-header-flyout--sites & {
        @include mq(lg) {
            @include make-col(12);

            margin-bottom: 0;
        }
    }
}

.c-header-flyout__body {
    @include make-col(12);

    position: relative;
    display: flex;
    flex-direction: row;

    @include mq(lg) {
        @include make-col(7);

        flex-wrap: nowrap;
        justify-content: space-between;
    }

    @include mq(xl) {
        @include make-col(8);
    }

    .c-header-flyout--contact & {
        flex-wrap: wrap;

        @include mq(lg) {
            @include make-col(12);
        }

        @include mq(xl) {
            @include make-col(9);
        }
    }

    .c-header-flyout--sites & {
        @include mq(lg) {
            @include make-col(12);
        }
    }
}

.c-header-flyout__headline {
    @include typo-h5();

    margin-bottom: 0;
    font-weight: bold;

    .c-header-flyout--contact & {
        margin-bottom: $spacing-s;

        @include mq(xl) {
            margin-bottom: 0;
        }
    }
}

.c-header-flyout__text {
    @include normal-font();

    display: block;
    align-self: flex-start;
    width: 100%;

    @include mq(lg) {
        width: auto;
        margin-bottom: 30px;
    }

    @include mq(xl) {
        max-width: 50%;
        margin-bottom: 0;
    }

    .c-header-flyout--contact & {
        width: 100%;
        max-width: 100%;
        margin-bottom: $spacing-s;

        @include mq(xl) {
            margin-bottom: $spacing-l;
        }
    }
}

.c-header-flyout__col {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: $spacing-2xl;

    @include mq(lg) {
        width: calc(100% / 9 * 4);
    }

    @include mq(xl) {
        margin-bottom: 0;
    }

    &:first-child {
        @include mq(lg) {
            padding-right: $grid-gutter-width *.5;
        }

        @include mq(xl) {
            padding-right: 0;
        }
    }

    &:last-child {
        @include mq(lg) {
            padding-left: $grid-gutter-width *.5;
        }

        @include mq(xl) {
            padding-right: 0;
        }
    }
}

/*
 * The close button. On small screens this transforms into a clickable page
 * overlay that, when clicked, closes the flyout.
 *
 * 1. Set position.
 * 2. Set icon color.
 * 3. Move it up a bit.
 * 4. On mouseover change the icon color.
 * 5. Adjust position on very large screens.
 * 6. On small screens transform the button into a semi-opaque layer…
 * 7. …hide it when the flyout is closed…
 * 8. …show it once the flyout opens.
 */
.c-header-flyout__close {
    position: absolute; /* [1] */
    top: $grid-gutter-width * 2; /* [1] */
    right: $grid-gutter-width; /* [1] */
    color: inherit; /* [2] */
    transform: translateY(-10px); /* [3] */
    cursor: pointer;

    &:hover {
        color: $c-brand; /* [4] */
    }
}

.c-header-flyout__close-svg {
    display: inline-block;
    width: 22px;
    height: 22px;
    vertical-align: middle;
    pointer-events: none;
    fill: currentColor; /* [2] */
}
