/* ------------------------------------ *\
    #FOOTER
\* ------------------------------------ */
.c-footer {
    @include clearfix;

    width: 100%;
    overflow: hidden;
    background-color: $c-grey-60;
}

.c-footer__inner {
    @include makeSection();
}

.c-footer__content {
    @include make-row();

    background-color: $c-grey-60;
}

.c-footer__nav {
    @include make-col-ready();
    @include make-col(12);
    @include mq(lg) {
        @include make-col(8);

        order: 1;
    }
    @include mq(xl) {
        @include make-col(9);
    }

    order: 2;

    &.c-footer--is-cv {
        display: flex;

        .c-nav {
            width: 33.33%;

            .c-nav__block {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }

        .c-vcard--footerNav {
            width: 33.33%;
            margin: 30px 0;
            padding: 0 15px;

            .c-vcard__block {
                margin: 0;
                padding: 0;

                .c-vcard__title {
                    font-size: 14px;
                    font-family: 'Helvetica Neue', Arial, Verdana, sans-serif;
                    line-height: 1.2142857143;
                    letter-spacing: .15em;
                    text-transform: uppercase;
                }
            }
        }
    }
}

.c-footer__info {
    @include make-col-ready();
    @include make-col(12);
    @include mq(lg) {
        @include make-col(4);

        order: 2;
    }
    @include mq(xl) {
        @include make-col(3);
    }

    order: 1;
    background-color: $c-brand;

    &::before,
    &::after {
        position: absolute;
        top: 0;
        width: $grid-gutter-width;
        height: 100%;
        background: $c-brand;
        content: '';

        @include mq(xl) {
            width: 200%;
        }
    }

    &::before {
        left: -$grid-gutter-width;

        @include mq(lg) {
            display: none;
        }
    }

    &::after {
        right: -$grid-gutter-width;

        @include mq(xl) {
            right: -199%;
        }
    }

    .c-footer--cottano & {
        background-color: $c-brand--cottano;

        &::before,
        &::after {
            background-color: $c-brand--cottano;
        }
    }
}

.c-footer__services {
    width: 100%;
    padding-top: 24px;
    padding-bottom: 21px;
    background-color: $c-grey-90;
}

.c-footer__services-inner {
    @include makeSection();

    padding-right: 0;
    padding-left: 0;
}
