/* ------------------------------------ *\
    #ce07-product-teaser-list
\* ------------------------------------ */
.c-product-teaser-list {
    @include clearfix();
    @include make-row();

    display: flex;
    flex-wrap: wrap;
    align-items: stretch;

    &.is-collection {
        .c-product-teaser-list__item {
            @include mq(md) {
                @include make-col(12);
            }

            @include mq(lg) {
                @include make-col(12);
            }
        }
    }
}

.c-product-teaser-list__header {
    @include make-col-ready();

    width: 100%;

    @include mq(lg) {
        width: 75%;
    }

    .c-product-filter & {
        padding-right: 0;
        padding-left: 0;
    }
}

.c-product-teaser-list__item {
    @include make-col-ready();
    @include make-col(12);

    height: auto;
    margin-bottom: 40px;

    @include mq(md) {
        @include make-col(6);

        margin-bottom: 50px;
        overflow: hidden;
    }
    @include mq(lg) {
        @include make-col(4);

        margin-bottom: 60px;
        overflow: hidden;
    }
}
