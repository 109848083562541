/* ------------------------------------ *\
    #Vcard
\* ------------------------------------ */
.c-vcard {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-right: -$grid-gutter-width / 2;
    margin-left: -$grid-gutter-width / 2;
    padding-top: $spacing-m;
    padding-bottom: $spacing-m;
    color: $c-white;

    .c-logo {
        margin-top: 0;
        margin-right: 0;
        margin-bottom: 1rem;
    }
}

.c-vcard__block {
    @include make-col-ready();
    @include make-col(12);

    margin-top: $spacing-xl;
    margin-bottom: $spacing-xl;

    @include mq(sm) {
        @include make-col(6);
    }

    @include mq(lg) {
        @include make-col(12);

        padding-right: $grid-gutter-width;
        padding-left: $grid-gutter-width;
    }

    &:nth-child(even) {
        margin-top: $spacing-m;

        @include mq(sm) {
            margin-top: $spacing-3xl;
        }
        @include mq(lg) {
            margin-top: $spacing-xl;
        }
    }
}

.c-vcard__topline {
    @include typo-h5(false);

    display: block;
    width: 100%;
    margin-bottom: 7px;
    color: $c-brand;
}

.c-vcard__adr {
    @include small-font();

    font-family: $global-font-family-regular;
}

.c-vcard__org {
    width: 100%;

    span {
        display: flex;
        width: 100%;
    }
}

.c-vcard__title {
    @include typo-h6();

    display: block;
    padding-right: 25px;
    color: $c-white;
    font-family: $global-font-family-bold;
    word-break: break-word;
}

.c-vcard__text {
    @include small-font();

    padding-right: 25px;
    font-family: $global-font-family-regular;
}

.c-vcard--location {
    color: $c-text;

    @include mq(xl) {
        padding-right: 30px;
        padding-bottom: 0;
        padding-left: 30px;
    }

    .c-vcard__block {
        @include make-col-ready();
        @include make-col(12);

        margin: 0 0 20px 0;

        @include mq(md) {
            @include make-col(6);
        }

        @include mq(xl) {
            @include make-col(12);
        }

        &:nth-child(even) {
            margin-bottom: 0;

            @include mq(md) {
                @include make-col(6);
            }

            @include mq(lg) {
                @include make-col(5);
                @include make-col-offset(1);
            }

            @include mq(xl) {
                @include make-col(12);
                @include make-col-offset(0);
            }
        }
    }

    .c-vcard__adr,
    .c-vcard__position {
        @include normal-font();
    }

    .c-vcard__name {
        @include typo-h5(false);

        margin-bottom: 5px;
    }

    .c-vcard__org,
    .c-vcard__title {
        @include typo-h3(false, true);

        width: 100%;
        margin-bottom: 20px;
        color: $c-text;

        span {
            display: flex;
            width: 100%;
        }

        + .c-vcard__org {
            @include normal-font();

            margin-bottom: 0;
        }
    }
}

.c-vcard__contact {
    .c-vcard__headline {
        display: block;
        margin-bottom: 1.25rem;
        font-weight: bold;
        font-size: 20px;
        font-family: $global-font-family-bold;
        line-height: 1.5;
        letter-spacing: .05em;
    }
}
