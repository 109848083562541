/* ------------------------------------ *\
    Overlay
\* ------------------------------------ */
.c-overlay {
    @include zindex(overlay);

    position: fixed;
    top: 0;
    left: 0;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;

    &.is-open {
        width: 100%;
        /* stylelint-disable  */
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        /* stylelint-enable */
        visibility: visible;
        opacity: 1;
        transition: opacity $global-transition-speed $global-transition-easing;

        @include mq(md) {
            overflow-y: auto;
        }
    }
}

.c-overlay__wrapper {
    @include zindex(overlay);

    position: relative;
    width: 100%;
    /* stylelint-disable  */
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    /* stylelint-enable */
    overflow: auto;

    @include mq(md) {
        @include makeSection();

        height: auto;
        margin-top: $spacing-2xl;
        margin-bottom: $spacing-2xl;
        overflow-y: scroll;
    }

    @include mq(lg) {
        margin-top: $spacing-4xl;
        margin-bottom: $spacing-3xl;
    }

    @include mq(xl) {
        margin-top: $spacing-6xl;
        margin-bottom: $spacing-6xl;
    }
}

.c-overlay__close {
    @include zindex(overlay);

    position: absolute;
    top: 15px;
    right: 15px;
    width: 40px;
    height: 40px;

    @include mq(md) {
        position: absolute;
        top: 15px;
        right: 55px;
    }

    @include mq(lg) {
        right: calc(100% / 12 + 50px);
    }

    @include mq(xl) {
        right: calc(100% / 12 + 50px);
    }

    @include mq(xxl) {
        right: calc((#{$max-content-width} / 12) + 60px);
    }

    &::before,
    &::after {
        @include zindex(base);

        position: absolute;
        top: calc(50% - 1px);
        right: 7px;
        display: block;
        width: 25px;
        height: 3px;
        background-color: $c-text;
        transition: background $global-transition-speed $global-transition-easing;
        content: '';
    }

    &::before {
        transform: rotate(45deg);
    }

    &::after {
        transform: rotate(-45deg);
    }

    &:hover,
    &:active,
    &:focus {
        &::before,
        &::after {
            background-color: $c-brand;
        }
    }
}

.c-overlay__content {
    position: relative;
    height: 100%;
    margin: auto;

    @include mq(md) {
        @include make-row();

        height: auto;
    }
}

.c-overlay__inner {
    width: 100%;
    height: 100%;
    vertical-align: top;

    @include mq(md) {
        @include make-col-ready();

        height: auto;
    }

    @include mq(lg) {
        @include make-col-offset(1);
        @include make-col(10);
    }
}

.c-overlay__bookmarks {
    padding: $spacing-m ($grid-gutter-width / 2) $spacing-xl;
    background-color: $c-white;

    @include mq(sm) {
        padding: $spacing-m ($grid-gutter-width + ($grid-gutter-width / 2)) $spacing-xl;
    }

    @include mq(md) {
        padding: $spacing-3xl 0;
    }

    @include mq(lg) {
        padding-top: $spacing-4xl;
        padding-bottom: $spacing-4xl;
    }

    @include mq(xl) {
        padding-top: $spacing-6xl;
        padding-bottom: $spacing-6xl;
    }
}

.c-overlay__mask {
    @include zindex(overlay-background);

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    /* stylelint-disable  */
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    /* stylelint-enable */
    background: $c-white;

    @include mq(md) {
        background: rgba($c-grey-90, .8);
    }
}
