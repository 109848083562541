/* -------------------------------------------------------------------------- *\
    #STICKY_HEADER
\* -------------------------------------------------------------------------- */

/**
 * Begin of module
 */
.c-sticky-header {
    @include make-container();

    height: $shared-header-top-height;
    transition: $shared-sticky-header-transition-duration height ease-in-out;
    transition-duration: $shared-sticky-header-transition-duration;

    .header-is-shrunk & {
        height: $shared-sticky-header-height;
    }

    @include mq($from: false, $until: xl) {
        height: $shared-sticky-header-height;
    }
}

.c-sticky-header__default {
    @include make-row();

    display: flex;
    flex-wrap: nowrap;
    transition: $shared-sticky-header-transition-duration ease-in-out;
    transition-property: opacity, transform;

    .header-is-shrunk & {
        transform: translateY(-100%);
        opacity: 0;
    }

    @include mq($from: false, $until: xl) {
        transform: translateY(-100%);
        opacity: 0;
    }
}

.c-sticky-header__alternate {
    position: absolute;
    top: 0;
    right: $grid-gutter-width;
    left: $grid-gutter-width;
    transform: translateY(-100%);
    opacity: 0;
    transition: $shared-sticky-header-transition-duration ease-in-out;
    transition-property: opacity, transform;
    pointer-events: none;

    .header-is-shrunk & {
        transform: translateY(0);
        opacity: 1;
        pointer-events: auto;
    }

    .is-cv & {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    > .c-meta-nav {
        height: unset;
    }

    @include mq($from: false, $until: xl) {
        transform: translateY(0);
        opacity: 1;
        pointer-events: auto;
    }
}

.c-sticky-header__brand {
    margin-right: 30px;
    padding-top: 9px;
    padding-bottom: 9px;

    @include mq(lg) {
        width: auto;
    }
}

.c-sticky-header__identifier {
    display: flex;
    // justify-content: space-between;

    @include mq(sm) {
        @include make-col-ready();
    }
    @include mq(xl) {
        @include make-col(8);
    }
}

.c-sticky-header__text {
    @include textEmphasizedSmall();

    color: $c-grey-50;
    font-weight: bold;
    line-height: $shared-header-top-height;

    .c-sticky-header__alternate & {
        line-height: $shared-sticky-header-height;

        @include mq($until: lg) {
            display: none;
        }
    }
}

.c-sticky-header__text--level-2 {
    padding-left: $spacing;
}

.c-sticky-header__toggle {
    position: absolute;
    top: 0;
    right: -$grid-gutter-width;
    height: 60px;
}

.c-sticky-header__button {
    position: relative;
    display: block;
    width: 90px;
    height: 100%;
    color: $c-white;
    font-size: inherit;
    background: $c-brand;

    &:hover,
    &:focus {
        outline: none;
    }
}

.c-sticky-header__icon {
    position: absolute;
    top: 50%;
    left: 0;
    display: inline-block;
    width: 100%;
    transform: translateY(-50%);
    transition: $global-transition-config;
    transition-property: opacity;
}

.c-sticky-header__icon--open {
    .c-sticky-header__button.is-active & {
        opacity: 0;
    }
}

.c-sticky-header__icon--close {
    opacity: 0;

    .c-sticky-header__button.is-active & {
        opacity: 1;
    }
}
