/* ------------------------------------ *\
    Object: Section
\* ------------------------------------ */

/*
    The section object serves as a wrapper to shrink the containing elements
    to a maximum width. It then gets centered.
*/

.o-section {
    @include clearfix;
}

.o-section__content {
    @include makeSection();
}
